import React from 'react';
import { Col, Label, NewDateInput, Row, themes } from '@digitools/honest-react';
import useExperience from 'src/hooks/useExperience';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from '../../../hooks/useCliDataContext';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from 'src/types/Experience';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import { validateDateIsWithinAYear } from 'src/packages/cli/utils/validationUtils';
import { addYears, isBefore, parse } from 'date-fns';
import { useFormState } from 'informed';
const SurgeryDate = () => {
    const { absenceData } = useCliDataContext();
    const { t } = useTranslate();
    const { experience, language } = useExperience();
    const { formLabels } = AboutYourAbsenceText;
    const { values } = useFormState();
    const labelText = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(formLabels.surgeryDateEE) : t(formLabels.surgeryDateER));
    const validateSurgeryDate = (input) => {
        if (input && values?.absenceData?.claimBeginDate) {
            if (isBefore(parse(input, 'P', new Date()), parse(values.absenceData.claimBeginDate, 'P', new Date()))) {
                return t(formLabels.surgeryDateErrorClaimBegin);
            }
        }
        if (input && values?.absenceData?.incidentDate) {
            if (isBefore(parse(input, 'P', new Date()), parse(values.absenceData.incidentDate, 'P', new Date()))) {
                return t(formLabels.surgeryDateErrorIncident);
            }
        }
        return validateDateIsWithinAYear(input, t, false);
    };
    return (<>
      <Row className='mb-3'>
        <Col size={12}>
          <Label className='mb-2'>{labelText}</Label>
        </Col>
        <Col size={12} sm={6} xl={4}>
          <NewDateInput data-testid='surgeryDate' theme={themes.lincoln} field={'surgeryDate'} lang={language} label={t(formLabels.surgeryDateShortLabel)} placeholder={'mm/dd/yyyy'} ariaLabelText={labelText} minDate={values?.absenceData?.claimBeginDate ? new Date(values?.absenceData?.claimBeginDate) : values?.absenceData?.incidentDate ? new Date(values?.absenceData?.incidentDate) : undefined} maxDate={addYears(new Date(), 1)} validate={(value) => validateSurgeryDate(value)} initialValue={absenceData && absenceData.surgeryDate}/>
        </Col>
      </Row>
    </>);
};
export default SurgeryDate;
