import { themes } from '@digitools/honest-react';
import React from 'react';
import CategorizationDocumentUpload from 'src/components/documentUpload/CategorizationDocumentUpload';
import useAuthentication from '../../../../hooks/useAuthentication';
import useExperience from '../../../../hooks/useExperience';
import useTranslate from '../../../../hooks/useTranslate';
import { Experience } from '../../../../types/Experience';
import { getNotificationsEnabled } from '../../api/CliApiCalls';
import { CliConfirmationText } from '../../i18n/CliConfirmationText';
import Step from './Step';
// TODO: implement final copy & variablility based on submissionType OGP-2966
const NextSteps = ({ submissionType, eventNum }) => {
    const { t } = useTranslate();
    const { user } = useAuthentication();
    const { experience } = useExperience();
    let textEnabled = false;
    if (user && user.customerDiv && user.customerSerial) {
        const { loading: configLoading, data: configData, error: configError, } = getNotificationsEnabled(user.customerDiv, user.customerSerial);
        if (configData) {
            textEnabled = configData.notificationsActiveIndicator === 'true' ? true : false;
        }
    }
    const determineText = (index, submissionType) => {
        if (index === '2') {
            if (submissionType !== 'leave') {
                return t(CliConfirmationText[submissionType].nextSteps2Body);
            }
            else if (experience === Experience.EMPLOYEE) {
                return t(CliConfirmationText[submissionType].nextSteps2BodyEE);
            }
            else {
                return t(CliConfirmationText[submissionType].nextSteps2BodyER);
            }
        }
        else if (index === '3') {
            if (submissionType !== 'leave') {
                return t(CliConfirmationText[submissionType].nextSteps3Body);
            }
        }
        else if (index === '4') {
            if (submissionType === 'leave') {
                if (experience === Experience.EMPLOYEE) {
                    return t(CliConfirmationText[submissionType].nextSteps4BodyEE);
                }
                return t(CliConfirmationText[submissionType].nextSteps4BodyER);
            }
            return t(CliConfirmationText[submissionType].nextSteps4Body);
        }
        else if (index === '5') {
            if (submissionType === 'claimdocs') {
                return t(CliConfirmationText[submissionType].nextSteps5Body);
            }
            else if (submissionType !== 'leave') {
                if (experience === Experience.EMPLOYEE) {
                    return t(CliConfirmationText[submissionType].nextSteps5BodyEE);
                }
                return t(CliConfirmationText[submissionType].nextSteps5BodyER);
            }
        }
    };
    return (<div data-testid='next-steps'>
      <Step index='1' heading={t(CliConfirmationText[submissionType].nextSteps1Heading)} text={t(CliConfirmationText[submissionType].nextSteps1Body)} color={themes.lincoln.palette.primary} submissionType={submissionType}/>
      <Step index='2' heading={t(CliConfirmationText[submissionType].nextSteps2Heading)} text={determineText('2', submissionType)} link={submissionType !== 'leave'
            ? (submissionType === 'claimdocs' ? t(CliConfirmationText[submissionType].nextSteps2Link) : undefined)
            : <CategorizationDocumentUpload eventNum={`${eventNum}`} productName={'LEAVE'} displayLabel={true} renderAsLink={false} data-testid='CategorizationDocumentUpload' page='confirmation'/>} color={themes.lincoln.palette.primaryDarker} submissionType={submissionType}/>
      {user?.customerInContext?.smallMid ? (<Step index='3' heading={t(CliConfirmationText[submissionType].nextSteps3Heading)} text={determineText('3', submissionType)} link={t(CliConfirmationText.claimdocs.nextSteps3Link)} communicationPref={submissionType === 'leave' && textEnabled === true ? true : false} color={themes.lincoln.palette.warning} submissionType={submissionType} data-testid='step3'/>) : (<Step index='3' heading={t(CliConfirmationText[submissionType].nextSteps3Heading)} text={determineText('3', submissionType)} link={submissionType !== 'claimdocs' ? undefined :
                textEnabled && textEnabled === true ? (t(CliConfirmationText[submissionType].nextSteps3Link)) : undefined} color={themes.lincoln.palette.warning} submissionType={submissionType} data-testid='step3'/>)}
     <Step index='4' heading={t(CliConfirmationText[submissionType].nextSteps4Heading)} text={determineText('4', submissionType)} communicationPref={textEnabled && submissionType === 'leave'} submissionType={submissionType} link={submissionType === 'claim' || submissionType === 'claimleave'
            ? <CategorizationDocumentUpload eventNum={`${eventNum}`} productName={'STD'} displayLabel={true} renderAsLink={false} data-testid='CategorizationDocumentUpload' page='confirmation'/>
            : textEnabled === true && submissionType === 'leave'
                ? t(CliConfirmationText[submissionType].nextSteps4Link)
                : undefined} color={themes.lincoln.palette.primary}/>
      {submissionType !== 'leave' && (<><Step index='5' heading={t(CliConfirmationText[submissionType].nextSteps5Heading)} text={determineText('5', submissionType)} submissionType={submissionType} color={themes.lincoln.palette.primaryDarker} communicationPref={textEnabled && submissionType !== 'claimdocs'} link={textEnabled === true && submissionType !== 'claimdocs'
                ? t(CliConfirmationText[submissionType].nextSteps5Link)
                : undefined}/>

            </>)}
    </div>);
};
export default NextSteps;
