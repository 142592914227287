import React, { useState } from 'react';
import { Row, Col, TextInput, SelectInput, themes, RadioGroup, Radio, Box, Icon, NewDateInput } from '@digitools/honest-react';
import { Scope } from 'informed';
import useTranslate from 'src/hooks/useTranslate';
import { DatePickerBlock, DivBlock, LabelText, RadioContainer, SectionHeading, StyledRow } from 'src/packages/as/components/LayoutComponents';
import { validateTextArea, isDateInGivenRange, normalizeNewLines } from 'src/packages/as/utils/validationUtils';
import styled from 'styled-components';
import { ConditionCircumstanceText } from '../../constants/ConditionCircumstanceText';
import useAsDataContext from '../../hooks/useAsDataContext';
import useAsContext from '../../hooks/useAsConfigContext';
import { getDropDownList } from '../../utils/AsDataUtils';
import DocumentUpload from '../../components/DocumentUpload';
import { AddRequestText } from '../../constants/AddAccommodationRequestText';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
const labelStyle = {
    color: '#333333',
};
const TextInputStyles = {
    height: '5rem',
    minHeight: '5rem',
    width: '100%',
    marginBottom: '2rem'
};
const StyledSelectInput = styled(SelectInput) `{
  height: 8rem;
  min-height: 8rem;
  width: 100%;
  margin-bottom: 1.5rem;
}`;
const legendStyles = {
    marginBottom: '0.5rem',
};
const radioContainerStyles = {
    marginBottom: '1.5rem',
};
const Disclaimer = styled.div `
    color: ${themes.lincoln.palette.black};
    font-style: italic;
    font-weight: 100;
    marginTop: 0rem;
    marginLeft: 0.5rem;
    margin-bottom: 1.5rem;
    display: flex;
  `;
const StyledIcon = styled(Icon) `	
  font-size: 2rem; 	
  margin-left: 0.5rem;	
  margin-right: 0.5rem;	
  margin-top: 0rem;	
  margin-botton: -0.5rem;	
  @media only screen and (max-width: 500px) {	
    margin-top: -1.5rem;	
}	
`;
const validateRange = (days, value) => {
    return isDateInGivenRange(days, value);
};
const ConditionCircumstance = () => {
    const { t } = useTranslate();
    const { conditionData, setReceivedDate } = useAsDataContext();
    const { asConfig } = useAsContext();
    const [wrState, setWrState] = useState();
    const { experience } = useExperience();
    let days = asConfig?.config?.requestedStartPastBusinessDays ?
        asConfig?.config?.requestedStartPastBusinessDays :
        asConfig?.config?.requestedStartPastCalendarDays;
    const [workRelatedDeselect, setWorkRelatedDeselect] = useState(false);
    const rlLabel = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(ConditionCircumstanceText.formLabels.reportedRestrictonOrLimitationEE) : t(ConditionCircumstanceText.formLabels.reportedRestrictonOrLimitationER));
    return (<div data-public={true} data-testid='accommodation-request-condition'>
      <Box className={'mt-4'}>
        <StyledRow>
          <DivBlock className={'d-flex align-items-center'}>
            <StyledIcon name={'condition'} size='large' color={'primary'}/>
            <SectionHeading theme={themes.lincoln} color={'black'} elemType={'h3'}>{t(ConditionCircumstanceText.accommTitle)}</SectionHeading>
          </DivBlock>
        </StyledRow>
        {/* @ts-ignore */}
        <Scope scope='conditionData'>
          <Row>
            {experience === Experience.EMPLOYER && asConfig?.config?.asRep && <> <Col md={12} xs={12}>
              <LabelText data-testid='receivedDateLabel'>{CustomEmployeeText(t(ConditionCircumstanceText.formLabels.receivedDate))}</LabelText>
            </Col>
              <Col sm={12}>
                <DatePickerBlock md={6} lg={4} className={'pl-0'}>
                  <NewDateInput data-testid='receivedDate' theme={themes.lincoln} label={t(AddRequestText.datePickerLabelEE)} field={'receivedDate'} placeholder={t(AddRequestText.dateFormatEE)} hideLabel={true} onChange={(date) => setReceivedDate(date)} validate={(value) => validateRange(days, value)} initialValue={conditionData?.receivedDate}/>
                </DatePickerBlock>
              </Col></>}
            <Col md={12} xs={12}>
              <LabelText optional={true}>{t(ConditionCircumstanceText.formLabels.conditions)} </LabelText>
            </Col>
            <Col md={6} xs={12}>
              <StyledSelectInput field={'conditions'} name={'conditions'} placeholderText={t(ConditionCircumstanceText.formLabels.conditions)} validateOnBlur={true} options={getDropDownList(asConfig?.config?.conditionDropDown, t)} multiple={true} label={'conditions'} hideLabel={true} initialValue={conditionData?.conditions}/>
            </Col>
            <Col md={12} xs={12}>
              <LabelText optional={true}>{t(ConditionCircumstanceText.formLabels.workRelated)} </LabelText>
            </Col>
            <Col md={12} xs={12}>
              <RadioGroup label='' field={'workRelated'} name={'workRelated'} theme={themes.lincoln} legendStyles={legendStyles} validateOnBlur={true} validateOnChange={true} onChange={(e) => { setWrState(e.target.value); }} initialValue={conditionData?.workRelated}>
                <RadioContainer style={radioContainerStyles}>
                  <Radio containerStyle={{ zIndex: 0 }} data-testid={'pref-yes'} name='workRelated' label={t({ english: 'Yes', spanish: 'Sí' })} value={'Y'} labelStyle={labelStyle} onClick={() => setWorkRelatedDeselect(true)}/>
                  <Radio containerStyle={{ zIndex: 0 }} data-testid={'pref-no'} name='workRelated' label={'No'} value={'N'} labelStyle={labelStyle} onClick={() => setWorkRelatedDeselect(true)}/>
                  {workRelatedDeselect &&
            <Radio containerStyle={{ zIndex: 0 }} name='workRelated' label={'Deselect'} value={''} labelStyle={labelStyle} onClick={() => setWorkRelatedDeselect(false)}/>}
                </RadioContainer>
              </RadioGroup>
            </Col>
            {(wrState === 'Y') &&
            <Col md={12} xs={12}>
                <Disclaimer>
                  <Icon name='info' className={'mr-1'} size='small' color='secondary'/>
                  <div>
                    <strong>{t(ConditionCircumstanceText.formLabels.pleaseNote)}</strong>
                    {t(ConditionCircumstanceText.formLabels.workRelatedMessage)}
                  </div>
                </Disclaimer>
              </Col>}
            <Col md={12} xs={12}>
              <LabelText htmlFor='conditionText'>
                {rlLabel}
              </LabelText>
              <TextInput className={'mb-2'} id='conditionText' data-private={true} data-testid='conditionText' label='conditionText' hideLabel={true} field={'conditionText'} name={'conditionText'} validate={(props) => validateTextArea(props)} validateOnBlur={true} validateOnChange={true} initialValue={conditionData?.conditionText} textarea={true} mask={normalizeNewLines} style={TextInputStyles} resize={'vertical'}/>
            </Col>
            <Col md={12} xs={12}>
              <LabelText optional={true} htmlFor='availableDocumentation'>
                {t(ConditionCircumstanceText.formLabels.availableDocumentation)}
              </LabelText>
              <DocumentUpload productName='AS' displayLabel={true} pageTitle={'Accommodation Condition'}/>
            </Col>
          </Row>
        </Scope>
      </Box>
    </div>);
};
export default React.memo(ConditionCircumstance);
