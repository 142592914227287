import { Box, Heading, TabContainer, Table, TableBody, TableData, TableRow, TabSection, themes, } from '@digitools/honest-react';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import DentalText from 'src/packages/dental/constants/DentalText';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { DEDUCTIBLES_AND_COINSURANCE, getTabs, tealiumFormat, } from 'src/packages/dental/providers/DentalDataProvider';
import { dentalBoxClass, DeductiblesAndCoinsuranceHeader, dentalTableClass, } from 'src/packages/dental/pages/DeductibleAndCoinsurance';
import useMediaQuery from 'src/hooks/useMediaQuery';
const PlanMaximumsTableData = ({ planMaximums, networkType, isTitleView }) => {
    return (<Table className={dentalTableClass(isTitleView)}>
      <TableBody>
        {planMaximums &&
            planMaximums.map(plan => plan[networkType] && (<TableRow key={plan.deductibleType} className={'border-bottom'} isHoverable={false}>
                  <TableData className={'border-0'}>
                    <Heading elemType={'h4'}>{plan.deductibleType}</Heading>
                    <p className={'font-weight-light font-italic'}>{plan.planDesc}</p>
                  </TableData>
                  <TableData className={'border-0 text-right font-weight-light'}>
                    ${plan[networkType].networkMaximum}
                  </TableData>
                </TableRow>))}
      </TableBody>
    </Table>);
};
const PlanMaximums = () => {
    const { currentMember, tealiumFunction } = useDentalDataContext();
    const [inNetworkLabel, outNetworkLabel, deductibles, planMaximums] = getTabs('planMaximums', currentMember);
    const hasData = !!(planMaximums && planMaximums?.length);
    const hasMultiTabs = !!(inNetworkLabel.length && outNetworkLabel.length);
    const { t } = useTranslate();
    const trackDropdownEvent = (eventName) => tealiumFunction({
        hasEventView: false,
        eventAction: TEALIUM_EVENT.EVENT_ACTION.TAB,
        eventName: tealiumFormat(`${eventName}-plan maximums`),
        eventType: tealiumFormat(DEDUCTIBLES_AND_COINSURANCE),
    });
    const dataClass = 'border-0 text-right font-weight-light align-bottom';
    const isTitleView = useMediaQuery('(max-width: 768px)');
    return (<Box theme={themes.lincoln} className={dentalBoxClass(isTitleView)}>
      <DeductiblesAndCoinsuranceHeader title={t(DentalText.planMaximumsText)} text={currentMember?.deductiblesAndCoinsurance?.planMaximumHelpText}/>
      <Table className={'mt-0 pt-0 mb-0 pb-0'}>
        <TableBody>
          {!!deductibles && (<>
              {deductibles.calendarMaxUsed != null && (<TableRow isHoverable={false} className={'border-0'}>
                  <TableData className={'border-0'}>
                    <Heading elemType={'h4'}>{t(DentalText.individualAnnualText)}</Heading>
                  </TableData>
                  <TableData className={dataClass}>${deductibles?.calendarMaxUsed}</TableData>
                </TableRow>)}
              {deductibles.lifetimeOrthoMaxUsed != null && (<TableRow className={'border-top border-light'}>
                  <TableData className={'border-0'}>
                    <Heading elemType={'h4'}>{t(DentalText.indvidualLifetimeText)}</Heading>
                  </TableData>
                  <TableData className={dataClass}>${deductibles?.lifetimeOrthoMaxUsed}</TableData>
                </TableRow>)}
              {deductibles.maxRewardsAvailable != null && (<TableRow className={'border-top border-light'}>
                  <TableData className={'border-0'}>
                    <Heading elemType={'h4'}>{t(DentalText.maxRewardsText)}</Heading>
                  </TableData>
                  <TableData className={dataClass}>${deductibles?.maxRewardsAvailable}</TableData>
                </TableRow>)}
            </>)}
        </TableBody>
      </Table>
      {hasData && hasMultiTabs ? (<TabContainer noVertical={true}>
          <TabSection onClick={() => {
                trackDropdownEvent(inNetworkLabel);
            }} label={inNetworkLabel} name={'planMaximumsInNetworkTab'}>
            <PlanMaximumsTableData isTitleView={isTitleView} planMaximums={planMaximums} networkType={'inNetwork'}/>
          </TabSection>
          <TabSection onClick={() => {
                trackDropdownEvent(outNetworkLabel);
            }} label={outNetworkLabel} name={'planMaximumsOutNetworkTab'}>
            <PlanMaximumsTableData isTitleView={isTitleView} planMaximums={planMaximums} networkType={'outNetwork'}/>
          </TabSection>
        </TabContainer>) : (hasData && (<PlanMaximumsTableData isTitleView={isTitleView} planMaximums={planMaximums} networkType={inNetworkLabel ? 'inNetwork' : 'outNetwork'}/>))}
    </Box>);
};
export default PlanMaximums;
