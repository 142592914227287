import React, { useEffect } from 'react';
import { Alert, Box, Spinner, themes } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import { useCliOfficeInfo } from 'status/hooks';
import titleCase from 'status/utilities/titleCase';
import formatPhone from 'status/utilities/formatPhone';
import useAuthentication from '../../../../../hooks/useAuthentication';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
import useTealium from '../../../../../hooks/useTealium';
import { SMALL_MID_OFFICE } from '../../../constants/SmallMidOffice';
import { getOffice } from 'status/utilities/getOffice';
import useTranslate from 'src/hooks/useTranslate';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
export const CASE_MANAGER_ERROR_TEXT = {
    english: 'Unable to load case manager',
    spanish: 'No se puede cargar el administrador de casos',
};
export const OFFICE_NOT_FOUND_TEXT = {
    english: 'Office information unavailable',
    spanish: 'Información de la oficina no disponible',
};
const CaseManager = ({ caseManager, claimNum, employeeName, prodType }) => {
    const { t: oldTranslate } = useTranslation();
    const { t } = useTranslate();
    const { user } = useAuthentication();
    const { trackEvent } = useTealium();
    const { experience } = useExperience();
    const customerId = user?.customerId;
    const { error: cliOfficeError, loading: cliOfficeLoading, cliOfficeInfo, getCliOfficeInfo, } = useCliOfficeInfo(customerId);
    const isEmployer = experience === Experience.EMPLOYER;
    useEffect(() => {
        if (!user?.customerInContext?.smallMid) {
            getCliOfficeInfo();
        }
    }, []);
    // fall back on different office data based on user type and available params
    let office;
    if (user?.customerInContext?.smallMid) {
        office = SMALL_MID_OFFICE;
    }
    else {
        office = getOffice(cliOfficeInfo, prodType, caseManager);
    }
    const OfficeName = ((user?.customerInContext?.smallMid) ?
        office?.officeNameLine1
        :
            'Lincoln Financial');
    const OfficeAddressLine = ((user?.customerInContext?.smallMid) ?
        office?.officeAddressLine
        :
            'P.O. Box 2578');
    const OfficeCity = ((user?.customerInContext?.smallMid) ?
        office?.officeCity
        :
            'Omaha');
    const OfficeState = ((user?.customerInContext?.smallMid) ?
        office?.officeState
        :
            'NE');
    const OfficeZipCode = ((user?.customerInContext?.smallMid) ?
        office?.officeZipCode
        :
            '68172-9688');
    useEffect(() => {
        if (!office) {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                // event_name required by analytics (set from before translation was implemented)
                // TODO: we should tell Analytics/PO partners to stop using label text as event names so event_names don't make updating text on screen harder
                event_name: 'Unable to load office for this case manager.',
                event_version: 'claim details',
            });
        }
    }, [office]);
    if (cliOfficeLoading) {
        return (<Box className='mb-3' data-testid={'case-manager-loading'}>
        <Spinner id='case-manager-spinner'/>
      </Box>);
    }
    if (cliOfficeError) {
        return (<Alert type={'error'} className={'mb-3'}>
        <span>
          <strong>Error!</strong>&nbsp;{t(CASE_MANAGER_ERROR_TEXT)}
        </span>
      </Alert>);
    }
    /** Email formatting and logic  */
    const subject = `${employeeName + (employeeName && ' ')}%23${claimNum}`;
    let emailAddress;
    /** Small mid - always use case manager email **/
    if (user?.customerInContext?.smallMid) {
        emailAddress = office?.emailAddr;
    }
    else if (experience == Experience.EMPLOYER) {
        /** ER to use the logic down in getOffice, trust that to handle the crazy scenarios **/
        emailAddress = office.emailAddress;
    }
    else {
        /** All other cases show case manager email **/
        emailAddress = caseManager?.emailAddr;
    }
    /** prevent any non lfg emails */
    const isLfgEmail = emailAddress?.toLowerCase().includes('@lfg.com');
    return (<>
      <Box type='header' style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
        {oldTranslate('caseManagerText')}
      </Box>
      <Box style={{
            wordWrap: 'break-word',
            marginBottom: '1rem',
            border: `1px solid ${themes.lincoln.palette.grayLighter}`,
        }}>
        {caseManager && (<small data-private={true} className='d-block' data-testid='name'>
            {titleCase(caseManager.cntcFirstName)} {titleCase(caseManager.cntcLastName)}
          </small>)}
        {office ? (<>
            <small className='d-block'>{OfficeName}</small>
            <small className='d-block'>{OfficeAddressLine}</small>
              <small className='d-block'>
                {`${OfficeCity},`} {OfficeState} {OfficeZipCode}
              </small>
            <small className='d-block'>
              <strong>{oldTranslate('phoneText')}: </strong> {formatPhone(office?.officePhoneNumber)}
              {(office?.officePhoneNumber) && caseManager?.phoneExt && ` ext. ${caseManager.phoneExt}`}
            </small>

            {(user?.customerInContext?.smallMid || isEmployer) && (<small data-private={true} className='d-block'>
                <strong>Email: </strong>
                {isLfgEmail &&
                    <a id='email-address' href={`mailto:${emailAddress}?subject=${subject}`}>
                    {' '}
                    {emailAddress}
                  </a>}
              </small>)}
            {(office?.officeFaxNumber !== undefined || !user?.customerInContext?.smallMid) &&
                <small className='d-block'>
                <strong>{oldTranslate('faxText')}: </strong> {formatPhone(office?.officeFaxNumber)}
              </small>}
          </>) : (<Alert type='warning' className='mt-2'>
            {t(OFFICE_NOT_FOUND_TEXT)}
          </Alert>)}
      </Box>
    </>);
};
export default CaseManager;
