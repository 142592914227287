import React from 'react';
import { addDays, endOfDay, isAfter, isBefore, max, min, parse, startOfDay, subDays } from 'date-fns';
import LanguageToggle from 'src/utils/LanguageToggle';
import { RIA_VALIDATION_CONSTANTS } from '../constants/RiaValidationConstants';
/**
 *
 * @param input Raw date coming from date picker
 * @param past This value will either be a date (leaveBegin) or a number (reporting policy past)
 * @param future This value will either be a date (leaveEnd) or a number (reporting policy future)
 * @returns When reporting policy for future is 0, inputeDate and today's date does NOT equal, inputDate is after today then show error messaging
 * @returns Error messaging if input date isn't between past and future
 */
export const validateDateRange = (input, past, future, leaveBeginDate, leaveEndDate) => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const inputDate = parse(input, 'P', new Date());
    const minDate = max([
        subDays(new Date(), past),
        new Date(leaveBeginDate)
    ]);
    const maxDate = min([
        addDays(new Date(), future),
        new Date(leaveEndDate)
    ]);
    if (input) {
        //startOfDay because we want hh:mm:ss to be 00:00:00 and endOfDay because we want hh:mm:ss to be 23:59:59
        if (isBefore(startOfDay(inputDate), startOfDay(minDate)) || isAfter(inputDate, endOfDay(maxDate))) {
            return (<LanguageToggle en={RIA_VALIDATION_CONSTANTS.validateDateRangeMessage.english} es={RIA_VALIDATION_CONSTANTS.validateDateRangeMessage.spanish}/>);
        }
    }
};
const validateMinuteIncrements = (value, durationInterval, t) => {
    if (parseInt(value.split(':')[1]) % durationInterval !== 0) {
        return t(RIA_VALIDATION_CONSTANTS.durationMessage)(durationInterval);
    }
};
export const absenceConfigDateValidation = (value, policyItem, isEditing, leave) => {
    return isEditing
        ? validateDateRange(value, policyItem?.numberOfEditingDaysPast, policyItem?.numberOfEditingDaysFuture, leave?.lveBeginDte, leave?.lveEndDte)
        : validateDateRange(value, policyItem?.numberOfReportingDaysPast, policyItem?.numberOfReportingDaysFuture, leave?.lveBeginDte, leave?.lveEndDte);
};
export const validateDuration = (value, durationInterval, t) => {
    if (value) {
        const hours = parseInt(value.split(':')[0]);
        const minutes = parseInt(value.split(':')[1]);
        if (hours > 24 || (hours > 23 && minutes > 0)) {
            return t(RIA_VALIDATION_CONSTANTS.validateDurationOverMessage);
        }
        else if (hours <= 0 && minutes <= 0) {
            return t(RIA_VALIDATION_CONSTANTS.validateDurationUnderMessage);
        }
        // Default case for duration 0 = 1 hour increments
        else if (durationInterval === 60 && (minutes !== 0 || hours === 0)) {
            return t(RIA_VALIDATION_CONSTANTS.hourDurationMessage);
        }
        else if (minutes % durationInterval !== 0) {
            return t(RIA_VALIDATION_CONSTANTS.durationMessage)(durationInterval);
        }
        // Default case for duration 0 = 1 hour increments
        else if (durationInterval === 0 && (minutes !== 0 || hours === 0)) {
            return t(RIA_VALIDATION_CONSTANTS.durationMessage)(60);
        }
        else if (minutes % durationInterval !== 0) {
            return t(RIA_VALIDATION_CONSTANTS.durationMessage)(durationInterval);
        }
    }
};
