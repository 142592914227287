import React, { useEffect } from 'react';
import { Alert, Box, Spinner, themes } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import { useCliOfficeInfo } from 'status/hooks';
import useAuthentication from '../../../../../hooks/useAuthentication';
import { getOffice } from 'status/utilities/getOffice';
import titleCase from 'status/utilities/titleCase';
import formatPhone from 'status/utilities/formatPhone';
const Error = ({ error, notFound }) => {
    const noData = notFound || (error.response && error.response.status === 404);
    if (noData) {
        return <></>;
    }
    else {
        return (<Alert type={'error'}>
        <span>
          <strong>Error!</strong>&nbsp;Unable to load leave specialist.
        </span>
      </Alert>);
    }
};
const LeaveSpecialist = ({ specialistName, leaveNum, employeeName }) => {
    const { t } = useTranslation();
    const { user } = useAuthentication();
    const { error: cliOfficeError, loading: cliOfficeLoading, cliOfficeInfo, getCliOfficeInfo, } = useCliOfficeInfo(user?.customerId);
    const emailRegex = RegExp(/^$|^[A-Za-z0-9\._%-]+@[A-Za-z0-9\.-]+\.[A-Za-z]{2,4}$/);
    useEffect(() => {
        getCliOfficeInfo();
    }, []);
    const office = getOffice(cliOfficeInfo, 'LEAVE');
    if (cliOfficeLoading) {
        return (<Box className='mb-3'>
        <Spinner id='leave-specialist-spinner'/>
      </Box>);
    }
    if (cliOfficeError) {
        //@ts-ignore its mad about className
        return <Error className='mb-3' error={cliOfficeError}/>;
    }
    const specialistFirstName = specialistName ? specialistName.split(' ')[0] : '';
    const specialistLastName = specialistName ? specialistName.split(' ')[1] : '';
    return (<div className='mb-3'>
      <Box type='header' style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
        {t('leaveSpecialistText')}
      </Box>
      <Box style={{ wordWrap: 'break-word', border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
        <small data-private={true} className='d-block'>
          {titleCase(specialistFirstName)} {titleCase(specialistLastName)}
        </small>
        {office ? (<>
            <small className='d-block'>{'Lincoln Financial'}</small>
            <small className='d-block'>{'P.O. Box 2578'}</small>
              <small className='d-block'>
                {'Omaha, NE 68172-9688'}
              </small>
            <small className='d-block'>
              <strong>{t('phoneText')}: </strong> {formatPhone(office?.officePhoneNumber)}
            </small>
            {office?.emailAddress !== undefined && office?.emailAddress !== "" && emailRegex.test(office?.emailAddress) &&
                <small className='d-block'>
                <strong>Email: </strong>{' '}
                  <a href={`mailto:${office?.emailAddress}?subject=${employeeName} %23${leaveNum}`}>
                    {office?.emailAddress}
                  </a>
              </small>}
            {office?.officeFaxNumber !== undefined &&
                <small className='d-block'>
                <strong>{t('faxText')}: </strong> {formatPhone(office?.officeFaxNumber)}
              </small>}
          </>) : (<Alert type='info' className='mt-2'>
            <small>No office info.</small>
          </Alert>)}
      </Box>
    </div>);
};
export default LeaveSpecialist;
