import React from 'react';
const helpMessage = (<>
    If you have any questions about your accounts, please{' '}
    <a href='mailto:CCS_Product_Portal_Services@lfg.com'>email us.</a>
  </>);
const errorMessage = (<>
    We are unable to retrieve the list of customers right now. Please check back again in 20 minutes. If this problem
    persists, please <a href='mailto:CCS_Product_Portal_Services@lfg.com'>email us.</a>
  </>);
const unassociatedMessage = (<>
    <p>
      Thank you for registering within <strong>My Lincoln Portal</strong>. We are actively working to associate your
      book of business. Please check back soon!
    </p>
    <p>
      If you have any questions, please <a href='mailto:CCS_Product_Portal_Services@lfg.com'>email us.</a>
    </p>
  </>);
export const MultiCustomerDisplayTextEn = {
    pageHeading: 'Select a customer',
    subHeading: 'To view information for a specific account, search by account name (at least 3 characters)  ',
    customerHeading: 'Customer search results:',
    searchPlaceholder: 'Search by Customer Name or Div-Serial',
    helpMessage,
    noResults: 'No results found.',
    errorMessage,
    unassociatedMessage,
};
