import { Button, Col, NewDateInput, Heading, Row, SelectInput, StepperContext, TextInput, } from '@digitools/honest-react';
import { Form } from 'informed';
import React, { useContext, useMemo } from 'react';
import { isNotEmpty, lettersOnly, validateSSN, formatPhone, validateName, } from '../../../../utils/validationUtils';
import { LCI_EMPLOYEE_DEPENDENT_RELATION, LCI_EMPLOYEE_DEPENDENT_YES_NO, LCI_GENDER, MAX_LENGTH_FNAME, MAX_LENGTH_LNAME, } from '../../constants/LCIConstant';
import Address from '../address/Address';
import CancelLci from '../cancelLCI/CancelLci';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import { validateDateIsOnOrBeforeToday } from 'src/packages/cli/utils/validationUtils';
import { BorderLessGrayBox, StyledDiv } from 'src/components/Formatting/StyledComponents';
import useTranslate from 'src/hooks/useTranslate';
import { parse } from 'date-fns';
const DependentInfo = ({ onComplete }) => {
    const stepper = useContext(StepperContext);
    const { trackEvent } = useTealium();
    const { t } = useTranslate();
    const handleContinue = (values) => {
        const dependentInfo = {
            firstName: values.dependentInfo_firstName,
            middleInitial: values.dependentInfo_middleInitial,
            lastName: values.dependentInfo_lastName,
            dependentRelationship: values.dependentInfo_dependentRelationship,
            dependentEmployeeToCompany: values.dependentInfo_dependentEmployeeToCompany,
            dob: values.dependentInfo_dob,
            gender: values.dependentInfo_gender,
            ssn: values.dependentInfo_ssn,
            phoneNumber: values.dependentInfo_phoneNumber,
            streetAddress1: values.dependentInfo_street1,
            streetAddress2: values.dependentInfo_street2,
            city: values.dependentInfo_city,
            state: values.dependentInfo_state,
            province: values.dependentInfo_province,
            postalcode: values.dependentInfo_postalcode,
            country: values.dependentInfo_country,
            dateOfDeath: values.dependentInfo_dateOfDeath,
        };
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: 'continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
            event_version: 'dependent info',
            event_status: 'step 5'
        });
        onComplete(dependentInfo);
        stepper.next();
    };
    const handleBack = () => {
        stepper.back();
    };
    const firstNameValidator = useMemo(() => {
        return (value) => validateName(value, MAX_LENGTH_FNAME, 'First Name', true);
    }, []);
    const lastNameValidator = useMemo(() => {
        return (value) => validateName(value, MAX_LENGTH_LNAME, 'Last Name', true);
    }, []);
    return (<>
      <Form onSubmit={handleContinue}>
        <Heading elemType='h2' color='primary'>
          Dependent Info
        </Heading>
        <Row>
          <Col>
            <BorderLessGrayBox>
              <Heading elemType='h3' elemStyle='h4'>
                Dependent's Personal Information
              </Heading>
            </BorderLessGrayBox>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={5}>
            <TextInput field={'dependentInfo_firstName'} label={'First Name'} maxLength={MAX_LENGTH_FNAME} validate={firstNameValidator} validateOnBlur={true} type={'text'} data-private={true}/>
          </Col>
          <Col sm={12} md={2}>
            <TextInput ariaLabelText={'MI'} field={'dependentInfo_middleInitial'} label={'MI (optional)'} maxLength={1} mask={lettersOnly} type={'text'} validateOnBlur={true} data-private={true}/>
          </Col>
          <Col sm={12} md={5}>
            <TextInput field={'dependentInfo_lastName'} label={'Last Name'} maxLength={MAX_LENGTH_LNAME} validate={lastNameValidator} validateOnBlur={true} type={'text'} data-private={true}/>
          </Col>
        </Row>
        <Row style={{ marginTop: 0 }}>
          <Col md={6} sm={12}>
            <SelectInput field={'dependentInfo_dependentRelationship'} label={'Relationship to Employee'} options={LCI_EMPLOYEE_DEPENDENT_RELATION} validate={isNotEmpty} placeholderText={'Relationship to Employee'} validateOnBlur={true}/>
          </Col>
          <Col md={6} sm={12}>
            <SelectInput ariaLabelText={'Was Dependent an Employee of this Company?'} field={'dependentInfo_dependentEmployeeToCompany'} label={'Was Dependent an Employee of this Company? (optional)'} placeholderText={'Was Dependent an Employee of this Company? (optional)'} options={LCI_EMPLOYEE_DEPENDENT_YES_NO}/>
          </Col>
        </Row>
        <Row style={{ marginTop: 0 }}>
          <Col sm={12} md={6}>
            <NewDateInput data-testid='dateOfBirth' field={'dependentInfo_dob'} label={'Date of Birth'} ariaLabelText={'Date of Birth'} required={true} validate={(props) => validateDateIsOnOrBeforeToday(props, t, true)} minDate={parse('01/01/1900', 'P', new Date())} maxDate={new Date()}/>
          </Col>
          <Col sm={12} md={6}>
            <SelectInput ariaLabelText={'Sex Assigned at Birth'} field={'dependentInfo_gender'} label={'Sex Assigned at Birth'} placeholderText={'Sex Assigned at Birth'} options={LCI_GENDER} validate={isNotEmpty} validateOnBlur={true}/>
          </Col>
        </Row>
        <Row style={{ marginTop: 0 }}>
          <Col sm={12} md={6}>
            <TextInput ariaLabelText={'SSN'} field={'dependentInfo_ssn'} label={'SSN (optional)'} validate={validateSSN} maxLength={11} validateOnBlur={true} type={'textmask'} data-private={true}/>
          </Col>
          <Col sm={12} md={6}>
            <TextInput ariaLabelText={'Phone Number'} field={'dependentInfo_phoneNumber'} label={'Phone Number (optional)'} mask={formatPhone} validateOnBlur={true} type={'text'}/>
          </Col>
          <Col sm={12} md={6}>
            <NewDateInput data-testid='dependentInfo_dateOfDeath' field={'dependentInfo_dateOfDeath'} label={'Date of Death (optional)'} ariaLabelText={'Date of Death'} validate={(props) => validateDateIsOnOrBeforeToday(props, t, false)} minDate={parse('01/01/1900', 'P', new Date())} maxDate={new Date()}/>
          </Col>
        </Row>
        <Address title={'Dependent\'s Address'} field={'dependentInfo'} isStateRequired={true}/>
        <StyledDiv>
          <Button aria-label={'Back'} buttonType={'secondary'} role='button' id='back_dependent_info' type='button' onClick={handleBack}>
            Back
          </Button>
          <CancelLci step={'step 5'} pageTitle={'dependent info'} sectionInfo={'Dependent info'}/>
          <Button aria-label={'Continue'} buttonType='primary' role='button' id='continue_dependent_info' type='submit'>
            Continue
          </Button>
        </StyledDiv>
      </Form>
    </>);
};
export default DependentInfo;
