import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Box, Button, Col, Container, NewDateInput, Icon, Loader, Radio, RadioGroup, Row, TextInput, themes, } from '@digitools/honest-react';
import { CustomDivider, DateContainer, RadioContainer, StyledBanner, StyledMessage, StyledUploadDocBanner, TextContainer, } from './RtwContainer';
import { useTranslation } from 'react-i18next';
import useAuthentication from '../../../../../hooks/useAuthentication';
import RtwDocumentUpload from './RtwDocumentUpload';
import axios from 'axios';
import { CustomRtwText } from 'src/utils/CustomExperienceText';
import useCustomerConfig from 'src/hooks/useCustomerConfig';
import './RtwDetails.scss';
import { Form } from 'informed';
// @ts-ignore
import Msgicon from '../../../../cli/assets/important-message.svg';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
import useTealium from '../../../../../hooks/useTealium';
import RtwDocUpload from './RtwDocUpload';
import styles from './RtwDetails.scss';
import { getEnvVariable } from "src/utils/envUtils";
import checkRtwText from '../../../utilities/checkRtwText';
import useExperience from 'src/hooks/useExperience';
import { format } from 'date-fns';
import RtwContext from 'src/packages/status/context/RtwContext';
import { Experience } from 'src/types/Experience';
const RtwDetails = ({ RtwProps, product, employeeInfo }) => {
    const defaultRtwData = {
        sameEmployer: '',
        capacity: '',
        returnDate: '',
        circumstances: '',
    };
    const [updateDoc, setUpdateDoc] = React.useState(false);
    const [uploaded, setUploaded] = React.useState([]);
    const [requiredDoc, setRequiredDoc] = React.useState(false);
    const { t } = useTranslation();
    const { experience } = useExperience();
    const { mlpConfigs } = useCustomerConfig();
    const { authHeader, user } = useAuthentication();
    const [rtwData, setRtwData] = useState({
        ...defaultRtwData,
    });
    const [submittedRtwData, setSubmittedRtwData] = useState({
        ...defaultRtwData,
    });
    const [updating, setUpdating] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [displayAlert, setDisplayAlert] = useState(false);
    const [successMsgDisplay, setSuccessMsgDisplay] = useState(false);
    const [errorMsgDisplay, setErrorMsgDisplay] = useState(false);
    const { trackEvent } = useTealium();
    const eventNum = RtwProps?.clmNum || RtwProps?.lveNum;
    const productName = RtwProps?.prodType || (RtwProps?.lveNum && 'LEAVE');
    const rtwRef = useRef(null);
    const { rtwNavigation, removeRtwNavigation } = useContext(RtwContext);
    // --- start RTW Navigation handling -------
    useEffect(() => {
        if (rtwNavigation) {
            setTimeout(() => {
                rtwRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                removeRtwNavigation();
            }, 500); //slight delay for user wayfinding on the page
        }
    }, [rtwNavigation]);
    // --- end RTW Navigation handling -------
    const validateInput = (input) => {
        let replaceString = '';
        if (input) {
            replaceString = input.replace(/[\r'\n]/g, '');
            let regExVal = checkRtwText(replaceString);
            if (regExVal) {
                return t('specialCharValidation');
            }
            if (replaceString?.length >= 190) {
                return t('circumstancesValidation');
            }
            setRtwData({
                ...rtwData,
                circumstances: replaceString,
            });
        }
    };
    const validateRadioGroup = (value) => {
        if (!value) {
            return t('requiredMsg');
        }
    };
    const onFormSubmit = async (values) => {
        requiredDoc && setDisplayAlert(true);
        if (!requiredDoc) {
            setErrorMsgDisplay(false);
            setSuccessMsgDisplay(false);
            setSubmitting(true);
            const fileCount = uploaded.length;
            let noteText = 'RTW ONLINE BY ' +
                experience +
                (rtwData.capacity === 'F' ? ' FULL TIME ' : ' PART TIME ') +
                'ON ' +
                format(new Date(values.returnDate), 'MM/dd/yyyy') +
                '-' +
                (rtwData.sameEmployer === 'YES' ? 'SAME ER' : 'ALT ER') +
                '-' +
                fileCount +
                ' DOCUMENTS';
            if (rtwData.circumstances) {
                noteText += '-' + rtwData.circumstances;
            }
            const documentData = uploaded.map(doc => ({
                documentId: doc.documentId,
                documentType: doc?.documentType || '',
                documentTypeTitle: doc?.documentTypeTitle || '',
            }));
            //@ts-ignore
            setRtwData({
                sameEmployer: values.sameEmployer,
                capacity: values.workType,
                returnDate: values.returnDate,
                circumstances: values.circumstances,
            });
            const rtwDetails = {
                rtwData: {
                    employeeNumber: RtwProps?.empNum || '',
                    taskAnalyAsgnId: RtwProps?.clmAnalyAsgnId || '',
                    employeeFirstName: employeeInfo?.firstName || '',
                    employeeLastName: employeeInfo?.lastName || '',
                    ssdCode: RtwProps?.ssdCde || '',
                    locCode: RtwProps?.locCde || '',
                    prodType: RtwProps?.clmNum ? RtwProps?.prodType || '' : 'LEAVE',
                    fundMechnCode: RtwProps?.fundMechnCde || '',
                    clmSttusCode: RtwProps?.clmNum ? RtwProps?.clmSttusCde : '',
                    lveSttusCode: RtwProps?.lveNum ? RtwProps?.lveSttusCde : '',
                    clmDisabDate: RtwProps?.clmNum ? RtwProps?.clmDisabDte : '',
                    lveBeginDate: RtwProps?.lveNum ? RtwProps?.lveBeginDte : '',
                    clmLveInd: RtwProps?.clmNum ? 'CLM' : 'LVE',
                    rtwId: eventNum || '',
                    rtwDate: values.returnDate || '',
                    noteText: noteText,
                    rtwSameCustomer: rtwData.sameEmployer,
                    rtwCapacity: rtwData.capacity,
                    documentData: documentData,
                },
            };
            let dataTypes = uploaded.map(data => data.label);
            let dataTypeString = '';
            const dataTypeCounts = {};
            if (Object.values(dataTypes).length) {
                dataTypes.forEach(element => {
                    dataTypeCounts[element] = (dataTypeCounts[element] || 0) + 1;
                });
                dataTypeString = 'gp_upload,';
                Object.keys(dataTypeCounts).forEach(data => {
                    dataTypeString += data + ',' + dataTypeCounts[data] + ',';
                });
                dataTypeString = dataTypeString.replace(/,$/, '');
            }
            const url = '/status-service/rtw/submit';
            try {
                const rtwApiData = await axios.post(url, rtwDetails, { headers: { Authorization: authHeader() } });
                if (rtwApiData.status === 200) {
                    setSuccessMsgDisplay(true);
                    setSubmitting(false);
                    if (updating) {
                        setUpdating(false);
                    }
                    (getEnvVariable('enableCategorizationForS1ERRtw') || experience === Experience.EMPLOYEE)
                        ? trackEvent({
                            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                            event_name: 'submit',
                            event_version: 'return to work - details',
                            event_results: `${fileCount}`,
                            form_result: dataTypeString,
                        })
                        : trackEvent({
                            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                            event_name: 'submit',
                            event_version: 'return to work - details',
                            event_results: `${fileCount}`,
                        });
                }
                else {
                    setErrorMsgDisplay(true);
                    setSubmitting(false);
                    trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                        event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                        event_name: `${t('claimErrorMessage')} ${t('contactUs')}`,
                        event_version: 'return to work - details',
                    });
                }
            }
            catch (error) {
                setErrorMsgDisplay(true);
                setSubmitting(false);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                    event_name: `${t('claimErrorMessage')} ${t('contactUs')}`,
                    event_version: 'return to work - details',
                });
            }
        }
    };
    const validateDoc = () => {
        let isDocReq;
        if (mlpConfigs) {
            isDocReq = mlpConfigs.docUpload;
            if (isDocReq && uploaded.length === 0) {
                setRequiredDoc(true);
            }
            if (!isDocReq || (isDocReq && uploaded.length !== 0)) {
                setRequiredDoc(false);
            }
        }
    };
    const updateRtwDetails = () => {
        setUploaded([]);
        setUpdateDoc(true);
        setSuccessMsgDisplay(false);
        setSubmittedRtwData({
            ...rtwData,
        });
        setUpdating(true);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
            event_name: 'update return-to-work',
            event_version: 'return to work - details',
        });
    };
    const cancelUpdate = () => {
        setUpdating(false);
        setRtwData({
            ...submittedRtwData,
        });
        setSuccessMsgDisplay(true);
    };
    useEffect(() => {
        validateDoc();
    }, [uploaded, mlpConfigs]);
    const claimSuccessMsg = `${t('leaveSuccessMessage')} ${t('claimSuccessMessage')}`;
    const ariaDateLabel = rtwData.capacity === 'F'
        ? t('fullTimeDate')
        : rtwData.capacity === 'P'
            ? t('partTimeDate')
            : t('tabDefault');
    return (<div style={{ scrollMarginTop: '2rem' }} ref={rtwRef}>
    <Container className={'parentWrapper'} data-testid='return-to-work-form'>
      {successMsgDisplay && (<div className='mb-2'>
          <Alert type={'success'} data-testid={'success-alert'}>
            <div>{product === 'claim' ? claimSuccessMsg : t('leaveSuccessMessage')}</div>
          </Alert>
        </div>)}
      {errorMsgDisplay && (<Alert type={'error'} data-testid={'error-alert'}>
          <div>
            {t('claimErrorMessage')}
            <a href='/customer/home/contacts'>{t('contactUs')}</a>
          </div>
        </Alert>)}
      <Loader id='document-upload-spinner' isShown={submitting}>
        <Form onSubmit={onFormSubmit}>
          {CustomRtwText() && (<StyledMessage>
              <Msgicon aria-hidden={true} height='3rem' className={styles.msgicon}/>
              <div className={styles.empmsg}>
                {/*//FIXME: this is html and returns an h3, it is breaking ADA and requires above */}
                <div dangerouslySetInnerHTML={{ __html: CustomRtwText() }}/>
              </div>
            </StyledMessage>)}

          <Row className={'rtwcontainer'}>
            <Col md={9} className={'ContainerMb'}>
              <StyledBanner data-testid='helpText'>
                <h3>{t('helperText')}</h3>
                <p style={{ marginTop: '0rem' }}>{t('helperTextContent')}</p>
              </StyledBanner>

              <Box className={styles.boxrtw}>
                {successMsgDisplay && (<Row role='group' aria-labelledby='updatebutton'>
                    <Col md={12} style={{ margin: 'auto', textAlign: 'right' }}>
                      <Button type='button' onClick={updateRtwDetails} buttonType={'text'} data-testid='update-button'>
                        <Icon color='secondary' style={{ paddingLeft: '.25rem' }} name='edit'/>
                        {t('updatelink')}
                      </Button>
                    </Col>
                  </Row>)}
                <Row role='group' aria-labelledby='sameemployerfield'>
                  <Col md={6} style={{ margin: 'auto' }}>
                    <label id='sameemployerfield'>
                      {' '}
                      <b>
                        {`${t('sameEmployer')}  ${user && (user.customerDisplayName ? user.customerDisplayName : user.customerInternalName)}?`}
                      </b>
                    </label>
                  </Col>
                  <Col md={6} style={{ margin: 'auto' }}>
                    {successMsgDisplay ? (rtwData.sameEmployer) : (<>
                        <RadioGroup label='' field={'sameEmployer'} validateOnBlur={true} validateOnChange={true} onChange={(e) => setRtwData({ ...rtwData, sameEmployer: e.target.value })} initialValue={rtwData.sameEmployer} validate={validateRadioGroup}>
                          <RadioContainer>
                            <Radio name={'sameEmployer'} id='yes' label={<>{t('yes')}</>} value='YES' data-testid='sameEmp-radio-yes'/>
                            <Radio name={'sameEmployer'} id='no' label={<>{t('no')}</>} value='NO' data-testid='sameEmp-radio-no'/>
                          </RadioContainer>
                        </RadioGroup>
                      </>)}
                  </Col>
                  <CustomDivider />
                </Row>
                <Row role='group' aria-labelledby='employeecapacity'>
                  <Col md={6} style={{ margin: 'auto' }}>
                    <label id='employeecapacity'>
                      <b>{t('capacity')}</b>
                    </label>
                  </Col>
                  <Col md={6} style={{ margin: 'auto' }}>
                    {successMsgDisplay ? (rtwData.capacity === 'F' ? (t('fullTimeText')) : (t('partTimeText'))) : (<>
                        <RadioGroup label='' field={'workType'} data-testid={'workType-select'} validateOnBlur={true} validateOnChange={true} onChange={(e) => setRtwData({ ...rtwData, capacity: e.target.value })} initialValue={rtwData.capacity} validate={validateRadioGroup}>
                          <RadioContainer>
                            <Radio name={'workType'} id='full' label={<>{t('fullTimeText')}</>} value='F' data-testid='workType-radio-full'/>
                            <Radio name={'workType'} id='part' label={<>{t('partTimeText')}</>} value='P' data-testid='workType-radio-part'/>
                          </RadioContainer>
                        </RadioGroup>
                      </>)}
                  </Col>
                  <CustomDivider />
                </Row>
                <Row>
                  <Col md={6} className={'datePickerLabel'} style={{ margin: 'auto' }}>
                    <label htmlFor='returnDate'>
                      <b>
                        {rtwData.capacity === 'F'
            ? t('fullTimeDate')
            : rtwData.capacity === 'P'
                ? t('partTimeDate')
                : t('tabDefault')}
                      </b>
                    </label>
                  </Col>
                  <Col md={6} className={styles.datePickerMob} style={{ margin: 'auto' }}>
                    {successMsgDisplay ? (rtwData.returnDate) : (<DateContainer>
                        <NewDateInput theme={themes.lincoln} disabled={false} label={''} data-testid='returnDate' aria-label={ariaDateLabel} field={'returnDate'} required={true} initialValue={rtwData.returnDate}/>
                      </DateContainer>)}
                  </Col>
                  <CustomDivider />
                </Row>
                <Row>
                  <Col md={6} style={{ margin: 'auto' }}>
                    <label htmlFor='circumstances'>
                      {' '}
                      <b>{t('circumstances')}</b>
                    </label>
                  </Col>
                  <Col md={6} xs={12} style={{ margin: 'auto' }}>
                    {successMsgDisplay ? (rtwData.circumstances ? (rtwData.circumstances) : (t('none'))) : (<TextContainer>
                        <TextInput theme={themes.lincoln} field='circumstances' ariaLabelText='circumstances' 
        // @ts-ignore string | null mismatch with placeholder string : undefined from honest
        placeholder={t('placeholder')} type='text' data-testid='circumstances' data-private={true} textarea={true} rows={4} validate={validateInput} validateOnBlur={true} validateOnChange={true} ariaLabeltext={t('circumstances')} hideLabel={true} initialValue={rtwData.circumstances}/>
                      </TextContainer>)}
                  </Col>
                  <CustomDivider />
                </Row>
              </Box>
            </Col>
            <Col md={3} className={'d-flex flex-column justify-content-between'}>
              <div>
                <StyledUploadDocBanner>
                  <b>{t('docUploadHeader')}</b>
                </StyledUploadDocBanner>
                <Row>
                  <Col data-testid={'required-alert'}>
                    {displayAlert && (<Alert style={{ display: uploaded.length !== 0 ? 'none' : 'flex' }} type={'warning'} aria-labelledby='required-alert'>
                        {t('docAlert')}
                      </Alert>)}
                  </Col>
                </Row>
                {(getEnvVariable('enableCategorizationForS1ERRtw') || experience === Experience.EMPLOYEE) ? (<RtwDocUpload eventNum={eventNum} data-testid='DocUploadCategorized' productName={productName} displayLabel={false} theme={themes.lincoln} updateDoc={updateDoc} setUpdateDoc={setUpdateDoc} setUploaded={setUploaded} isSuccess={successMsgDisplay}/>) : (<RtwDocumentUpload eventNum={eventNum} data-testid='DocUploadNonCategorized' productName={productName} displayLabel={false} theme={themes.lincoln} setUploaded={setUploaded} updateDoc={updateDoc} setUpdateDoc={setUpdateDoc} isSuccess={successMsgDisplay}/>)}
              </div>
              {!successMsgDisplay && !updating && (<Button className={styles.submitbtn} buttonType={'primary'} data-testid='submit' type='submit'>
                  {t('submit')}
                </Button>)}
              {updating && (<Row>
                  <Button className={styles.submitbtn} buttonType={'secondary'} type='button' onClick={cancelUpdate} style={{ maxWidth: 'calc(50% - 0.5rem)' }} data-testid='rtw-cancel'>
                    {t('cancel')}
                  </Button>
                  <Button className={styles.submitbtn} buttonType={'primary'} data-testid='update' type='submit' style={{ maxWidth: 'calc(50% - 0.5rem)' }}>
                    {t('submit')}
                  </Button>
                </Row>)}
            </Col>
          </Row>
        </Form>
      </Loader>
    </Container>
    </div>);
};
export default RtwDetails;
