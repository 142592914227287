import axios from 'axios';
import { getEnvVariable } from 'src/utils/envUtils';
/** Logrocket gdpr inclusion/exclusion api
 * Connects to our Logrocket configuration to include/exclude users from logrocket tracking
 * uses LFG org slug (constant) and app variables for API KEY & APP Name
 */
const ORGANIZATION_SLUG = 'o1n3rt';
const APP_SLUG = getEnvVariable('LOGROCKET_APP_SLUG');
const LOGROCKET_API_KEY = getEnvVariable('LOGROCKET_API_KEY');
export const optOutOfTracking = (username) => {
    const url = `https://api.logrocket.com/v1/orgs/${ORGANIZATION_SLUG}/apps/${APP_SLUG}/gdpr/exclusion/`;
    const requestBody = {
        user_id: username,
    };
    return axios.post(url, requestBody, {
        headers: {
            Authorization: `Token ${LOGROCKET_API_KEY}`,
            'Content-Type': 'application/json',
        },
    });
};
export const optIntoTracking = (username) => {
    const url = `https://api.logrocket.com/v1/orgs/${ORGANIZATION_SLUG}/apps/${APP_SLUG}/gdpr/inclusion/`;
    const requestBody = {
        user_id: username,
    };
    return axios.post(url, requestBody, {
        headers: {
            Authorization: `Token ${LOGROCKET_API_KEY}`,
            'Content-Type': 'application/json',
        },
    });
};
