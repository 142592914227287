import { Col, Row, SelectInput, TextInput, NewDateInput } from '@digitools/honest-react';
import React, { useEffect, useState, useContext } from 'react';
import { formatPhone, isNotEmpty, lettersOnly, validatePhoneNotRequired, validateGuardianInfo, } from '../../../utils/validationUtils';
import styled from 'styled-components';
import { BdmAddBeneficiaryModalDisplayTextEn as LanguageEn } from '../language/BdmAddBeneficiaryModalDisplayTextEn';
import { BdmAddBeneficiaryModalDisplayTextEs as LanguageEs } from '../language/BdmAddBeneficiaryModalDisplayTextEs';
import { LCI_GENDER } from '../../lci/constants/LCIConstant';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { Language } from '../../../types/Language';
import BdmBeneficiaryUtils from '../utils/BdmBeneficiaryUtils';
import { useIsLci } from '../../../providers/IsLciProvider';
import useBdmLciConfig from '../../../hooks/useBdmLciConfig';
import { validateDateIsOnOrBeforeToday } from 'src/packages/cli/utils/validationUtils';
import useTranslate from 'src/hooks/useTranslate';
import { parse } from 'date-fns';
const StyledP = styled('p') `
  font-size: 0.875rem;
  color: #91181d;
  font-style: italic;
`;
const BdmModalContentPerson = ({ beneficiaryCategory, beneficiaryIndex, beneficiary, }) => {
    const experienceContext = useContext(ExperienceContext);
    const language = experienceContext.language;
    const displayText = language === Language.SPANISH ? LanguageEs : LanguageEn;
    const [beneficiaryIsMinor, setBeneficiaryIsMinor] = useState(false);
    const isLci = useIsLci();
    const config = useBdmLciConfig();
    const { t } = useTranslate();
    useEffect(() => {
        if (beneficiary) {
            setBeneficiaryIsMinor(BdmBeneficiaryUtils.checkIfMinor(beneficiary.dateOfBirth));
        }
    }, []);
    const bdmDropDownValues = () => {
        return config.bdmLciRelationshipDropdownValues.map((v) => ({
            label: language === Language.SPANISH ? v.fieldValue_ES : v.fieldValue_EN,
            value: v.systemOneValue,
        }));
    };
    const showGuardianFields = (benDateOfBirth) => {
        setBeneficiaryIsMinor(BdmBeneficiaryUtils.checkIfMinor(benDateOfBirth));
        return benDateOfBirth;
    };
    const idPrefix = BdmBeneficiaryUtils.idBuilder(beneficiaryCategory, beneficiaryIndex);
    return (<>
      <Row data-private={true}>
        <Col>
          <TextInput field={`${idPrefix}firstName`} label={displayText.firstName} type={'text'} mask={BdmBeneficiaryUtils.formatName} maxLength={35} validateOnBlur={true} validate={isNotEmpty} initialValue={beneficiary ? beneficiary.firstName : undefined} data-private={true}/>
        </Col>
        <Col>
          <TextInput field={`${idPrefix}lastName`} label={displayText.lastName} type={'text'} mask={BdmBeneficiaryUtils.formatName} maxLength={35} validateOnBlur={true} validate={isNotEmpty} initialValue={beneficiary ? beneficiary.lastName : undefined} data-private={true}/>
        </Col>
        <Col>
          <TextInput style={{ width: '3.75rem', minWidth: '2.5rem' }} field={`${idPrefix}middleInitial`} label={displayText.middleInitial} type={'text'} maxLength={1} mask={lettersOnly} initialValue={beneficiary ? beneficiary.middleInitial : undefined} data-private={true}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <NewDateInput data-testid='dateOfBirth' field={`${idPrefix}dateOfBirth`} label={!isLci ? displayText.dateOfBirth : displayText.dateOfBirth + ' (optional)'} ariaLabelText={!isLci ? displayText.dateOfBirth : displayText.dateOfBirth + ' (optional)'} minDate={parse('01/01/1900', 'P', new Date())} maxDate={new Date()} validate={(props) => validateDateIsOnOrBeforeToday(props, t, !isLci)} initialValue={beneficiary ? beneficiary.dateOfBirth : undefined} required={!isLci} onValueChange={showGuardianFields}/>
        </Col>
        <Col>
          <SelectInput field={`${idPrefix}relationship`} label={displayText.relationship} placeholderText={displayText.relationshipPlaceholder} validateOnBlur={true} options={bdmDropDownValues()} validate={isNotEmpty} initialValue={beneficiary ? beneficiary.relationship : undefined}/>
        </Col>
        <Col>
          <TextInput field={`${idPrefix}phoneNumber`} label={displayText.phoneNumber} type={'text'} mask={formatPhone} validate={isLci ? undefined : validatePhoneNotRequired} validateOnBlur={true} initialValue={beneficiary ? beneficiary.phoneNumber : undefined}/>
        </Col>
      </Row>
      {isLci && (<Row>
          <Col>
            <TextInput field={`${idPrefix}emailAddress`} label={displayText.emailAddress} type={'text'}/>
          </Col>
          <Col>
            <SelectInput field={`${idPrefix}gender`} label={displayText.gender} placeholderText={`${displayText.genderPlaceholder} (optional)`} options={LCI_GENDER} type={'text'}/>
          </Col>
        </Row>)}
      {beneficiaryIsMinor && (<>
          <Row>
            <Col>
              <StyledP id='info-guardian'>{displayText.guardianAdvisory}</StyledP>
            </Col>
          </Row>
          <Row>
            <Col size={4}>
              <TextInput field={`${idPrefix}guardianName`} label={displayText.guardianName} type={'text'} mask={BdmBeneficiaryUtils.formatGuardianName} maxLength={35} initialValue={beneficiary ? beneficiary.guardianName : undefined} data-private={true}/>
            </Col>
            <Col size={8}>
              <TextInput field={`${idPrefix}guardianInfo`} label={displayText.guardianInformation} type={'text'} validateOnBlur={true} validate={validateGuardianInfo} initialValue={beneficiary ? beneficiary.guardianInfo : undefined}/>
            </Col>
          </Row>
        </>)}
    </>);
};
export default BdmModalContentPerson;
