import React from 'react';
import styled from 'styled-components';
const StyledDiv = styled('div') `
  margin-top: .5rem;
  & a {
    color: white;
  }
`;
const CookieConsent = () => {
    return (<StyledDiv id="teconsent" aria-label="Open Cookie Preferences Modal" aria-live='polite' role="complementary">
      <script src="https://consent.trustarc.com/asset/notice.js/v/v1.7-289" async={true} crossOrigin=""></script>
    </StyledDiv>);
};
export default CookieConsent;
