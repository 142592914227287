import React, { useRef, useState, useMemo, useContext, useEffect } from 'react';
import { NewModal as Modal, CompositeTable as Table, Button, Heading, Row, Col } from '@digitools/honest-react';
import SubsAndLocsColumns from '../../createMyReport/Constants/SubsLocsColumns';
import { UnexpectedErrorContext } from '../../../providers/UnexpectedErrorProvider';
import { AccessLevelEnum } from '../types/permissionTypes';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import { useDisclosure } from '@mantine/hooks';
const ButtonLabelText = {
    SelectAll: 'Select All Subsidiaries/Locations',
    DeselectAll: 'Deselect All Subsidiaries/Locations',
};
const EmployerSubLocModal = (props) => {
    const { trackEvent } = useTealium();
    const trackOpenModel = () => {
        return (trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.EMPLOYER_SUBS_LOCS_MODAL,
            event_name: 'Open Subsidiaries/Locations modal popup',
        }));
    };
    const trackSaveSubLocs = () => {
        return (trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.EMPLOYER_SUBS_LOCS_MODAL,
            event_name: 'Save Subsidiaries/Locations',
        }));
    };
    const trackCancelSubLocs = () => {
        return (trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.EMPLOYER_SUBS_LOCS_MODAL,
            event_name: 'Cancel Subsidiaries/Locations',
        }));
    };
    const trackSelectDeselectSubLoc = () => {
        return (trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.EMPLOYER_SUBS_LOCS_MODAL,
            event_name: 'Select/Deselect All Subsidiaries/Locations',
        }));
    };
    const tableApi = useRef({ setSelectedData: () => { } });
    const selectedSubLocObj = useRef([]);
    const reportFilterCategory = useState();
    const [buttonLabels, setDoSelectAll] = useState();
    const [tableReRender, setTableReRender] = useState(false);
    const [selectedLocationCount, setSelectedLocationCount] = useState(0);
    const { setIsMessagingDisabled } = useContext(UnexpectedErrorContext);
    useEffect(() => setIsMessagingDisabled(false), []);
    const getSelectAllButtonLabel = (selectedArrayLength) => {
        setSelectedLocationCount(selectedArrayLength);
        setDoSelectAll(selectedArrayLength === props.subLocs.length ? ButtonLabelText.DeselectAll : ButtonLabelText.SelectAll);
    };
    const setTableApi = (api) => {
        tableApi.current = api;
    };
    const getSelectedSubLocObject = (selectedSubLoc) => {
        const selectedIds = selectedSubLoc.current.map(ref => ref.locationId);
        return selectedIds;
    };
    const setSelectedSubLocObject = (selectedSubLoc) => {
        const selectedIds = selectedSubLoc.current.map(ref => ref.locationId);
        props.subLocs.map(obj => {
            obj.selected = false;
        });
        selectedIds.map(id => {
            props.subLocs
                .filter(obj => obj.locationId === id)
                .map(obj => {
                obj.selected = true;
            });
        });
    };
    const getSubAndLocAccessLevel = (selectedSubLoc) => {
        const selectedIds = selectedSubLoc.current.map(ref => ref.locationId);
        if (selectedIds === undefined || selectedIds.length === 0) {
            return AccessLevelEnum.NO_ACCESS;
        }
        else if (props.subLocs.length === selectedIds.length) {
            return AccessLevelEnum.FULL;
        }
        else {
            return AccessLevelEnum.LIMITED;
        }
    };
    const ModifySubLocButton = ({ showModal }) => {
        const handleOnClick = async () => {
            trackOpenModel();
            await props.getSubsLocData();
            getSelectAllButtonLabel(selectedSubLocObj.current.length);
            setTableReRender(true);
            showModal();
        };
        return (<Button buttonType={'text'} type={'button'} onClick={handleOnClick} data-testid='openModal' aria-label='Open Subsidiaries/Locations modal popup'>
        Modify Subsidiaries/Locations
      </Button>);
    };
    const SaveChangesButton = async () => {
        trackSaveSubLocs();
        setSelectedSubLocObject(selectedSubLocObj);
        const subAndLocAccessLevel = getSubAndLocAccessLevel(selectedSubLocObj);
        const selectedIds = getSelectedSubLocObject(selectedSubLocObj);
        await props.saveSubsAndLocs(selectedIds, subAndLocAccessLevel);
        setTableReRender(false);
        close();
    };
    const selectDeSelectSubsLoc = () => {
        trackSelectDeselectSubLoc();
        buttonLabels === ButtonLabelText.SelectAll
            ? tableApi.current.setSelectedData(props.subLocs)
            : tableApi.current.setSelectedData([]);
    };
    const table = useMemo(() => (<Table data={props.subLocs} defaultPageSize={10} columns={SubsAndLocsColumns} paginated={true} filterable={true} isSelectable={true} selectedRef={selectedSubLocObj} rowIdentifier={'locationId'} data-testid={'subLocTable'} tableApi={setTableApi} onSelectedChange={(enabled) => getSelectAllButtonLabel(enabled.length)}/>), [tableReRender]);
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <Modal title={'Modify Subsidiaries/Locations'} centered={true} opened={isOpen} onClose={() => {
            trackCancelSubLocs();
            setTableReRender(false);
            close();
        }} cancelButtonText={'Cancel'} submitButtonText={'Save Changes'} onSubmit={() => SaveChangesButton()} cancelButtonProps={{
            'data-testid': 'cancelSubLocs',
            'aria-label': 'Cancel Subsidiaries/Locations',
            buttonType: 'secondary'
        }} submitButtonProps={{
            'data-testid': 'saveSubLocs',
            'aria-label': 'Save Subsidiaries/Locations',
            buttonType: 'primary'
        }} closeOnClickOutside={true} size={'1100px'}>
        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Heading style={{ marginBottom: '10px' }} data-testid={'selectedSubLocHeader'} elemType={'h6'} aria-label='Subsidiaries/Locations Selected'>
              {selectedLocationCount} of {props.subLocs.length} subsidiaries/locations selected
            </Heading>
          </Col>
          <Col>
            <Button style={{ marginBottom: '15px', float: 'right' }} buttonType={'secondary'} data-testid={'selectSubs'} type={'button'} onClick={selectDeSelectSubsLoc} aria-label='Select/Deselect All Subsidiaries/Locations'>
              {buttonLabels}
            </Button>
          </Col>
        </Row>
        {table}
      </Modal>
      <ModifySubLocButton filterCategory={reportFilterCategory} showModal={() => open()}/>
    </>);
};
export default EmployerSubLocModal;
