import { Col, Container, NewDateInput, Form, Heading, Radio, RadioGroup, Row, TextInput } from '@digitools/honest-react';
import React, { useContext, useEffect, useState } from 'react';
import { findMyInformation } from '../../../api/FindMyInformationApi';
import { FormButton } from '../../../components/extraFormControls/FormButton';
import ServiceUnavailable from '../../../components/ServiceUnavailable/ServiceUnavailable';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../../constants/tealiumConstants';
import useAuthentication from '../../../hooks/useAuthentication';
import useBdmLciConfig from '../../../hooks/useBdmLciConfig';
import usePortalMessenger from '../../../hooks/usePortalMessenger';
import useTealium from '../../../hooks/useTealium';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { UnexpectedErrorContext } from '../../../providers/UnexpectedErrorProvider';
import { Language } from '../../../types/Language';
import { getTraceId } from '../../../utils/axiosUtils';
import { formatZipCode, numbersOnly, validateEmployeeId, validateZipCode } from '../../../utils/validationUtils';
import { BOTH, EMPLOYEE_ID_TEXT, EMP_ID, SSN } from '../constants/BdmConstants';
import { findMyInformationDisplayTextEn as LanguageEn } from '../language/FindMyInformationDisplayTextEn';
import { findMyInformationDisplayTextEs as LanguageEs } from '../language/FindMyInformationDisplayTextEs';
import BdmBeneficiaryUtils from '../utils/BdmBeneficiaryUtils';
import { parse } from 'date-fns';
import { validateDob } from 'src/utils/fieldValidators';
import useTranslate from 'src/hooks/useTranslate';
const FindMyInformation = ({ setVerification, setEmpId, setEmpSsn, setEmpDob, setEmpZip }) => {
    const { user, authHeader } = useAuthentication();
    const { language } = useContext(ExperienceContext);
    const { addMessage, clearMessages } = usePortalMessenger();
    const [formFilled, setFormFilled] = useState(false);
    const [isDuplicateUser, setIsDuplicateUser] = useState(false);
    const [traceId, setTraceId] = useState('');
    const { trackEvent, trackView } = useTealium();
    const { t } = useTranslate();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.BDM,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.BDM.FMI,
        });
    }, []);
    const { setIsMessagingDisabled } = useContext(UnexpectedErrorContext);
    const config = useBdmLciConfig();
    const showSsn = config.bdmApplication.uniqueIdIndicator === SSN;
    const showBoth = config.bdmApplication.uniqueIdIndicator === BOTH;
    const showEmpId = config.bdmApplication.uniqueIdIndicator === EMP_ID;
    const valueForEmpId = language === Language.ENGLISH ? 'value_EN' : 'value_ES';
    const empIdDisplayText = config.customContents.filter(c => c.title === EMPLOYEE_ID_TEXT)[0][valueForEmpId];
    const ssoManualSupport = config.bdmApplication.ssoManualSupport;
    const [selectedId, setSelectedId] = useState(EMP_ID);
    useEffect(() => {
        setIsMessagingDisabled(false);
    }, []);
    const checkForFormCompletion = (values) => {
        if (showEmpId || (showBoth && selectedId === EMP_ID)) {
            setFormFilled(!!values.employeeId && !!values.dateOfBirth && !!values.zipCode);
        }
        else if (showSsn || (showBoth && selectedId === SSN)) {
            setFormFilled(!!values.ssn && !!values.dateOfBirth && !!values.zipCode);
        }
    };
    const handleSubmit = async (values) => {
        try {
            const valuesForSubmission = {
                ...values,
                ssoManualSupport,
                customerDiv: user ? user.customerDiv : '',
                customerSerial: user ? user.customerSerial : '',
            };
            await findMyInformation(authHeader(), user.userName, valuesForSubmission);
            valuesForSubmission.employeeId ? setEmpId(valuesForSubmission.employeeId) : setEmpSsn(valuesForSubmission.ssn);
            setEmpDob(valuesForSubmission.dateOfBirth);
            setEmpZip(valuesForSubmission.zipCode);
            setVerification(true);
            clearMessages();
        }
        catch (error) {
            if (error.response.status == 409) {
                setIsDuplicateUser(true);
                setTraceId(getTraceId(error));
            }
            else if (error.response.status == 404) {
                addMessage({
                    type: 'error',
                    message: [LanguageEn.noIdFoundErrorMessage, LanguageEs.noIdFoundErrorMessage],
                    closeable: true,
                });
            }
            else {
                addMessage({ type: 'info', message: [LanguageEn.infoMessage, LanguageEs.infoMessage], closeable: true });
            }
        }
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.FIND_INFORMATION,
            event_name: 'Find',
        });
    };
    const getIdOptionSelectorValue = (e) => {
        if (e.target.value === EMP_ID) {
            setSelectedId(EMP_ID);
        }
        else {
            setSelectedId(SSN);
        }
    };
    const displayText = language === Language.SPANISH ? LanguageEs : LanguageEn;
    return !isDuplicateUser ? (<>
      <Container className={'mb-5'}>
        <Heading className={'mt-5'}>{displayText.ManageBensHeading}</Heading>
        <p>{displayText.FmiText}</p>
        <Heading elemType={'h2'} className={'mt-5'} color={'primary'}>
          {displayText.FmiHeading}
        </Heading>
        <Form onValueChange={checkForFormCompletion} onSubmit={handleSubmit}>
          {showBoth && (<RadioGroup field='idOptionSelector' initialValue={EMP_ID} onChange={getIdOptionSelectorValue} label={''}>
              <div className={'d-inline-block pr-3'}>
                <Radio value={EMP_ID} label={empIdDisplayText} name={''}/>
              </div>
              <div className={'d-inline-block pr-3'}>
                <Radio value={SSN} label={displayText.ssnLabel} name={''}/>
              </div>
            </RadioGroup>)}
          <Row className={'mt-2'}>
            {(showSsn || (showBoth && selectedId === SSN)) && (<Col md={3} xs={12} className={'mt-3'}>
                <TextInput field={'ssn'} label={displayText.ssnLabel} type={'textmask'} placeholder={displayText.ssnLabel} maxLength={9} mask={numbersOnly} validate={BdmBeneficiaryUtils.validateSSN} validateOnChange={true} validateOnBlur={true} data-private={true}/>
              </Col>)}
            {(showEmpId || (showBoth && selectedId === EMP_ID)) && (<Col md={3} xs={12} className={'mt-3'}>
                <TextInput field={'employeeId'} label={empIdDisplayText} type={'text'} placeholder={empIdDisplayText} validate={validateEmployeeId} validateOnChange={true} validateOnBlur={true}/>
              </Col>)}
            <Col md={3} xs={12} className={'mt-3'}>
              <TextInput field={'firstName'} label={displayText.firstNameLabel} type={'text'} initialValue={user ? user.firstName : ''} readOnly={true} data-private={true}/>
            </Col>
            <Col md={3} xs={12} className={'mt-3'}>
              <TextInput field={'lastName'} label={displayText.lastNameLabel} type={'text'} initialValue={user ? user.lastName : ''} readOnly={true} data-private={true}/>
            </Col>
            <Col md={3} xs={12} className={'mt-3'}>
              <NewDateInput data-testid={'dateOfBirth'} label={displayText.dateOfBirthLabel} field={'dateOfBirth'} minDate={parse('01/01/1900', 'P', new Date())} maxDate={new Date()} validate={(value) => validateDob(value, t)}/>
            </Col>
            <Col md={3} xs={12} className={'mt-3'}>
              <TextInput field={'zipCode'} label={displayText.postalCodeLabel} placeholder={displayText.postalCodeLabel} mask={formatZipCode} validateOnChange={true} validateOnBlur={true} validate={validateZipCode} type={'text'} maxLength={5}/>
            </Col>
            <Col md={3} xs={12} className={'mt-3'}>
              <TextInput field={'preferredEmail'} label={displayText.emailLabel} type={'text'} initialValue={user ? user.emailAddress : ''} readOnly={true}/>
            </Col>
            <Col md={3} xs={12} className={'mt-3'}>
              <div style={{ marginTop: 21 }}>
                <FormButton idName={'-find'} hasChanged={formFilled} displayText={displayText.findButton}/>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </>) : (<ServiceUnavailable traceId={traceId}/>);
};
export default FindMyInformation;
