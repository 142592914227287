import { Button, Col, NewDateInput, Form, Heading, Loader, Row, SelectInput, StepperContext, TextInput, } from '@digitools/honest-react';
import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import LCIApi from '../../../../api/LCIApi';
import useAuthentication from '../../../../hooks/useAuthentication';
import useBdmLciConfig from '../../../../hooks/useBdmLciConfig';
import useExperience from '../../../../hooks/useExperience';
import usePortalMessenger from '../../../../hooks/usePortalMessenger';
import { formatLowerCase, formatName, formatPhone, formatTitleCase, isNotEmpty, lettersOnly, validateSSNRequired, validateAlphaNumeric, validateName, validatePhoneNotRequired, } from '../../../../utils/validationUtils';
import Address from '../../components/address/Address';
import { LCI_EMPLOYEE_MARITAL_STATUS, LCI_GENDER, MAX_LENGTH_LNAME, MAX_LENGTH_FNAME, } from '../../constants/LCIConstant';
import { getLabels } from '../../utils/LCIUtils';
import CancelLci from '../cancelLCI/CancelLci';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import { BorderLessGrayBox, StyledDiv } from 'src/components/Formatting/StyledComponents';
import { validateDateIsOnOrBeforeToday } from 'src/packages/cli/utils/validationUtils';
import useTranslate from 'src/hooks/useTranslate';
import { parse } from 'date-fns';
const EMP_ID = 'EMP ID';
const SSN = 'SSN';
const BOTH = 'BOTH';
const StyledTextInput = styled(TextInput) `
  ‚ @media (max-width: 48rem) {
    width: 100%;
  }
  @media (min-width: 48rem) {
    width: 5rem;
    min-width: 5rem;
  }
`;
const SEARCH_CRITERIA_DEFAULT_VALUE = 'EMP ID';
const EmployeeInfo = ({ claimant, event, onComplete }) => {
    const { authHeader } = useAuthentication();
    const stepper = useContext(StepperContext);
    const { addMessage } = usePortalMessenger();
    const { customContents, lciApplication } = useBdmLciConfig();
    const { language } = useExperience();
    const { trackEvent } = useTealium();
    const { t } = useTranslate();
    const [showSpinner, setShowSpinner] = useState(false);
    const [isEligible, setIsEligible] = useState(false);
    const getCustomLabels = getLabels(customContents, language);
    const labelEmployeeID = getCustomLabels.employeeId || 'Employee ID';
    const labelEmployee = getCustomLabels.employee || 'Employee';
    const eeFormApiRef = useRef();
    const prefillFormApiRef = useRef();
    const setFormApi = (formApi) => {
        eeFormApiRef.current = formApi;
    };
    const setPrefillFormApi = (formApi) => {
        prefillFormApiRef.current = formApi;
    };
    const handleContinue = (values) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: 'continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
            event_version: `${labelEmployee} info`,
            event_status: 'step 3'
        });
        const info = {
            ssn: prefillFormApiRef && prefillFormApiRef.current ? prefillFormApiRef.current.getValue('ssnPrefill') : values.employeeInfo_ssn,
            employeeId: prefillFormApiRef && prefillFormApiRef.current ? prefillFormApiRef.current.getValue('employeeIdPrefill') : values.employeeInfo_id,
            firstName: values.employeeInfo_firstName,
            lastName: values.employeeInfo_lastName,
            middleInitial: values.employeeInfo_middleInitial,
            gender: values.employeeInfo_gender,
            dob: values.employeeInfo_dob,
            maritalStatus: values.employeeInfo_maritalStatus,
            phoneNumber: values.employeeInfo_phoneNumber,
            streetAddress1: values.employeeInfo_street1,
            streetAddress2: values.employeeInfo_street2,
            city: values.employeeInfo_city,
            state: values.employeeInfo_state,
            province: values.employeeInfo_province,
            postalcode: values.employeeInfo_postalcode,
            country: values.employeeInfo_country,
        };
        onComplete(info);
        stepper.next();
    };
    const handleBack = () => {
        stepper.back();
    };
    const handleSubmit = () => {
        if (eeFormApiRef && eeFormApiRef.current && prefillFormApiRef && prefillFormApiRef.current) {
            prefillFormApiRef.current.validate();
            eeFormApiRef.current.validate();
            const prefillFormInvalid = prefillFormApiRef.current.getState().invalid;
            const eeFormInvalid = eeFormApiRef.current.getState().invalid;
            if (!prefillFormInvalid && !eeFormInvalid) {
                eeFormApiRef.current.submitForm();
            }
        }
        else if (eeFormApiRef && eeFormApiRef.current) {
            eeFormApiRef.current.validate();
            if (!eeFormApiRef.current.getState().invalid) {
                eeFormApiRef.current.submitForm();
            }
        }
    };
    const handlePrefill = (values) => {
        setShowSpinner(true);
        const prefillRequestData = {
            employeeId: values.employeeIdPrefill,
            ssn: values.ssnPrefill,
            lastName: values.lastNamePrefill || '',
        };
        LCIApi()
            .prefill(prefillRequestData, authHeader())
            .then(({ data, status }) => {
            if (status === 200) {
                if (eeFormApiRef && eeFormApiRef.current) {
                    eeFormApiRef.current.setValue('employeeInfo_firstName', formatTitleCase(data.firstName));
                    eeFormApiRef.current.setValue('employeeInfo_lastName', formatTitleCase(data.lastName));
                    eeFormApiRef.current.setValue('employeeInfo_middleInitial', formatTitleCase(data.middleInitial));
                    if (data.gender && !!data.gender.trim()) {
                        eeFormApiRef.current.setValue('employeeInfo_gender', data.gender);
                    }
                    eeFormApiRef.current.setValue('employeeInfo_phoneNumber', data.phoneNumber);
                    if (data.country && !!data.country.trim()) {
                        eeFormApiRef.current.setValue('employeeInfo_country', data.country); //TODO: this keeps coming back as null and does not prefill
                    }
                    if (data.state && !!data.state.trim()) {
                        eeFormApiRef.current.setValue('employeeInfo_state', data.state);
                    }
                    eeFormApiRef.current.setValue('employeeInfo_city', formatTitleCase(data.city));
                    eeFormApiRef.current.setValue('employeeInfo_street1', formatTitleCase(data.streetAddress1));
                    eeFormApiRef.current.setValue('employeeInfo_street2', formatTitleCase(data.streetAddress2));
                    eeFormApiRef.current.setValue('employeeInfo_postalcode', data.postalcode);
                    eeFormApiRef.current.setValue('employeeInfo_dob', data.dob);
                }
                setShowSpinner(false);
            }
        })
            .catch((error) => {
            setShowSpinner(false);
            addMessage({
                type: 'error',
                message: ['Failed to find infomation for employee.', 'Fallar en encontrar la información de empleado.'],
                title: ['Employee Prefill', 'Auto LLenar empleado'],
                closeable: true,
            });
        });
    };
    const SEARCH_CRITERIA_OPTIONS = [
        { label: labelEmployeeID, value: EMP_ID },
        { label: 'SSN', value: SSN },
    ];
    const uniqueIdentifier = lciApplication.uniqueIdIndicator.toUpperCase();
    const [searchCriteria, setSearchCriteria] = useState(EMP_ID);
    const showSSN = uniqueIdentifier === SSN || (uniqueIdentifier === BOTH && searchCriteria === SSN);
    const showEmpId = uniqueIdentifier === EMP_ID || (uniqueIdentifier === BOTH && searchCriteria === EMP_ID);
    const ssnField = showSSN ? (<TextInput field={'ssnPrefill'} label={'SSN'} validate={validateSSNRequired} validateOnBlur={true} type={'textmask'} data-private={true}/>) : null;
    const employeeIdField = showEmpId ? (<TextInput field={'employeeIdPrefill'} label={labelEmployeeID} mask={validateAlphaNumeric} validate={isNotEmpty} validateOnBlur={true} type={'text'}/>) : null;
    LCIApi().getLciEligibility(authHeader()).then((response) => {
        setIsEligible(response.data);
    });
    return (<>
      <Loader viewportCentered={true} id='ee-info-spinner' isShown={showSpinner}>
      <Heading elemType='h2' color='primary'>
        {labelEmployee} Info
      </Heading>
      {isEligible &&
            <>
          <Form getApi={setPrefillFormApi} onSubmit={handlePrefill}>
            <Row>
              <Col>
                <BorderLessGrayBox>
                  <Heading elemType='h3' elemStyle='h4'>
                    {labelEmployee} Lookup
                  </Heading>
                </BorderLessGrayBox>
              </Col>
            </Row>
            <Row>
              <Col>
                Enter the {formatLowerCase(labelEmployee)} details to find the information to pre-fill and review this
                form.
              </Col>
            </Row>
            <Row className={'mt-4'}>
              {uniqueIdentifier === BOTH && (<Col>
                  <SelectInput field={'searchCriteria'} label={'Select Search Criteria'} options={SEARCH_CRITERIA_OPTIONS} initialValue={SEARCH_CRITERIA_DEFAULT_VALUE} onValueChange={setSearchCriteria} validateOnBlur={true} type={'text'}/>
                </Col>)}
              <Col>
                {ssnField}
                {employeeIdField}
              </Col>
              <Col>
                <TextInput field={'lastNamePrefill'} label={'Last Name'} mask={formatName} maxLength={MAX_LENGTH_LNAME} validateOnBlur={true} type={'text'} data-private={true}/>
              </Col>
            </Row>
            <Row className='mt-0'>
              <Col>
                <Button buttonType='primary'>Find Employee Information</Button>
              </Col>
            </Row>
            <Row>
              <Col md={4} xs={12}/>
              <Col md={4} xs={12}/>
            </Row>
          </Form>
        </>}
      <Form getApi={setFormApi} onSubmit={handleContinue}>
        <Row>
          <Col>
            <BorderLessGrayBox>
              <Heading elemType='h3' elemStyle='h4'>
                {labelEmployee} Personal Information
              </Heading>
            </BorderLessGrayBox>
          </Col>
        </Row>
        {!isEligible &&
            <Row>
            <Col>
              <TextInput field={'employeeInfo_ssn'} label={'SSN'} validate={validateSSNRequired} validateOnBlur={true} type={'textmask'} data-private={true}/>
            </Col>
              <Col>
                <TextInput field={'employeeInfo_id'} label={labelEmployeeID} mask={validateAlphaNumeric} validate={isNotEmpty} validateOnBlur={true} type={'text'}/>
              </Col>

          </Row>}
        <Row>
          <Col lg={5} md={5} sm={12}>
            <TextInput field={'employeeInfo_firstName'} label={'First Name'} maxLength={MAX_LENGTH_FNAME} validate={(value) => validateName(value, MAX_LENGTH_FNAME, 'First Name', true)} validateOnBlur={true} type={'text'} data-private={true}/>
          </Col>
          <Col lg={5} md={5} sm={12}>
            <TextInput field={'employeeInfo_lastName'} label={'Last Name'} maxLength={MAX_LENGTH_LNAME} validate={(value) => validateName(value, MAX_LENGTH_LNAME, 'Last Name', true)} validateOnBlur={true} type={'text'} data-private={true}/>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <StyledTextInput ariaLabelText={'employeeInfo_middleInitial'} field={'employeeInfo_middleInitial'} label={'MI (optional)'} maxLength={1} mask={lettersOnly} validateOnBlur={true} type={'text'}/>
          </Col>
        </Row>
        <Row style={{ marginTop: 0 }}>
          <Col xl={4} md={6} sm={12}>
            <TextInput ariaLabelText={'employeeInfo_phoneNumber'} field={'employeeInfo_phoneNumber'} label={'Phone Number (optional)'} mask={formatPhone} validate={(value) => validatePhoneNotRequired(value)} validateOnBlur={true} type={'text'}/>
          </Col>
          <Col xl={4} md={6} sm={12}>
            <NewDateInput data-testid='dateOfBirth' field={'employeeInfo_dob'} label={'Date of Birth'} ariaLabelText={'Date of Birth'} required={true} validate={(props) => validateDateIsOnOrBeforeToday(props, t, true)} minDate={parse('01/01/1900', 'P', new Date())} maxDate={new Date()}/>
          </Col>
          <Col xl={4} md={6} sm={12}>
            <SelectInput field={'employeeInfo_gender'} label={'Sex Assigned at Birth'} placeholderText={'Select a sex assigned at birth'} options={LCI_GENDER} validate={isNotEmpty} validateOnBlur={true} type={'text'}/>
          </Col>
          {claimant === 'employee' && event === 'accelerated death' && (<Col xl={4} md={6} sm={12}>
              <SelectInput ariaLabelText={'employeeInfo_maritalStatus'} field={'employeeInfo_maritalStatus'} label={'Marital Status (optional)'} placeholderText={'Select a martial status (optional)'} options={LCI_EMPLOYEE_MARITAL_STATUS} type={'text'}/>
            </Col>)}
        </Row>
        <Address title={`${labelEmployee} Address`} field={'employeeInfo'} required={true}/>
      </Form>
      <StyledDiv>
        <Button aria-label={'Back'} buttonType={'secondary'} role='button' id='employeeInfo_back' type='button' onClick={handleBack}>
          Back
        </Button>
        <CancelLci step={'step 3'} pageTitle={`${labelEmployee} info`} sectionInfo={'Employee info'}/>
        <Button aria-label={'Continue'} buttonType='primary' role='button' id='employeeInfo_continue' type='button' onClick={handleSubmit}>
          Continue
        </Button>
      </StyledDiv>
      </Loader>
    </>);
};
export default EmployeeInfo;
