import React from 'react';
import { Alert, CompositeTable, Heading, Link, NewDateInput, Row, themes } from '@digitools/honest-react';
import { format } from 'date-fns';
import { renderIcon } from '../../utils/FileUtils';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../constants/tealiumConstants';
import { sortDatesByDateAndTime } from 'src/utils/dateUtils';
class FileDetailsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: undefined,
            endDate: undefined,
        };
    }
    componentDidMount() {
        this.props.trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.STANDARD_REPORTS,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.STANDARD_REPORTS.FILE_DETAILS,
        });
    }
    renderDate = (initialDate) => {
        return format(new Date(initialDate), 'MM-dd-yyyy p');
    };
    mapFileItemToFileDetail = (fileItem) => {
        return {
            modifiedTimestamp: this.renderDate(fileItem.modifiedTimestamp),
            id: fileItem.id,
            label: fileItem.label,
            type: fileItem.type,
        };
    };
    noDocumentsSpan() {
        const noDocsStyle = { display: 'flex', justifyContent: 'center' };
        if (this.props.folderName === 'Select a Directory' || this.props.files.length > 0) {
            return <></>;
        }
        else if (this.props.files.length === 0) {
            if (this.state.endDate || this.state.startDate) {
                return (<Row className={'noDocumentsSpan'}>
            <span style={noDocsStyle}>There are no documents for the selected date range.</span>
          </Row>);
            }
            else {
                return (<Row className={'noDocumentsSpan'}>
            <span style={noDocsStyle}>There are no documents in this folder.</span>
          </Row>);
            }
        }
        else {
            return <></>;
        }
    }
    handleStartDate = (value) => {
        this.setState({
            startDate: value,
        });
        this.props.onDateSelect(value, this.state.endDate);
        return undefined;
    };
    handleEndDate = (value) => {
        this.setState({
            endDate: value,
        });
        this.props.onDateSelect(this.state.startDate, value);
        return undefined;
    };
    render() {
        const fileDetails = this.props.files.map(fileItem => this.mapFileItemToFileDetail(fileItem));
        const columns = [
            {
                Cell: ({ data }) => {
                    // TODO: fix this weird hack when we figure out why all the properties of IFileItem aren't getting passed.
                    const downloadFile = () => {
                        this.props.trackEvent({
                            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                            event_type: TEALIUM_EVENT.EVENT_TYPE.STANDARD_REPORTS,
                            event_name: 'standard Report Download',
                        });
                        // @ts-ignore
                        this.props.downloadFile(data._original.id);
                    };
                    return (<>
              {renderIcon(data?._original?.type)} <Link onClick={downloadFile}>{data.label}</Link>
            </>);
                },
                Header: 'File Name',
                accessor: 'label',
                id: 'label',
            },
            {
                Header: 'Modified Date',
                accessor: 'modifiedTimestamp',
                id: 'modifiedTimestamp',
                sortMethod: (a, b) => sortDatesByDateAndTime(a, b),
            },
        ];
        const table = this.props.files.length > 0 ? (<CompositeTable defaultSorted={[{ id: 'modifiedTimestamp', desc: true }]} columns={columns} data={fileDetails} filterable={false}/>) : (<></>);
        return (<>
        <Row className={'mb-3'}>
          <Heading elemType={'h4'}>{this.props.folderName}</Heading>
        </Row>
        <Row style={{ marginTop: '0rem', marginBottom: '1rem' }}>
          <Alert type={'info'} theme={themes.lincoln} closeable={true}>
            <strong>Info:</strong> By default 3 months of reports are shown. Please use the date inputs to set a custom
            date range.
          </Alert>
        </Row>
        <Row>
          <div style={{ display: 'flex' }} className={'ml-3 mb-3'}>
            <NewDateInput data-testid={'startDate'} label={'Start Date'} field={'startDate'} onChange={value => this.handleStartDate(value)} theme={themes.lincoln}/>
          </div>
          <div style={{ display: 'flex' }} className={'ml-3 mb-3'}>
            <NewDateInput data-testid={'endDate'} label={'End Date'} field={'endDate'} onChange={value => this.handleEndDate(value)} theme={themes.lincoln}/>
          </div>
        </Row>
        {this.noDocumentsSpan()}
        {table}
      </>);
    }
}
export default FileDetailsTable;
