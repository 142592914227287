import { Box, Col, Icon, Link, Row } from '@digitools/honest-react';
import useTranslate from 'src/hooks/useTranslate';
import DentalText from 'src/packages/dental/constants/DentalText';
import React from 'react';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import { DentalSubHeading } from 'src/packages/dental/Dental';
import { useMediaQuery } from '@mantine/hooks';
const AdditionalResources = () => {
    const { tealiumFunction } = useDentalDataContext();
    const { t } = useTranslate();
    const isMobile = useMediaQuery('(max-width: 1439px)');
    const AdditionalResourcesBox = ({ link, eventName, children }) => {
        return (<Col md={6} className={'mb-2'}>
        <Box className={'h-100'} borderType={'rounded'}>
          <Icon className={'pr-2'} name={'documents'} color={'secondaryLight'}/>
          <Link href={link} target={'_blank'} onClick={() => {
                tealiumFunction({
                    eventAction: TEALIUM_EVENT.EVENT_ACTION.LINK,
                    eventName: eventName,
                    memberEvent: 'Additional resources',
                });
            }}>
            {children}
          </Link>
        </Box>
      </Col>);
    };
    return (<Row>
      <Col md={12} className={'mb-2'}>
        <Row>
          <Col md={12}>
            <DentalSubHeading isMobile={isMobile} text={t(DentalText.additionalResources)}/>
          </Col>
        </Row>
        <Row>
          <AdditionalResourcesBox eventName={'Forms'} link={'https://www.lincoln4benefits.com/jpfebill/forms/gfrmsel2.asp'}>
            {t(DentalText.additionalResourcesForms)}
          </AdditionalResourcesBox>
          <AdditionalResourcesBox eventName={'Lincoln DentalConnect'} link={'https://ohl.go2dental.com/?cli=lincoln&sec=y'}>
            <em>Lincoln DentalConnect</em>
            <sup>SM</sup>
          </AdditionalResourcesBox>
        </Row>
      </Col>
    </Row>);
};
export default AdditionalResources;
