import React from 'react';
import Cookies from 'js-cookie';
import { useEffect, useRef, useState } from 'react';
import useAuthentication from '../hooks/useAuthentication';
import * as LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { getEnvVariable } from '../utils/envUtils';
import { optIntoTracking, optOutOfTracking } from 'src/api/LogRocketApi';
/**
 *  GDPR Preferences check
 *  accepts a given gdprPrefs cookie or looks it up itself
 *  0 is reqired cookies only
 *  1 is functional cookies (this is what's necessary to track)
 *  2 is advertising cookies
 *
 * @returns boolean
 */
export const isUserTrackable = (gdprPrefs) => {
    switch (gdprPrefs) {
        case '0:':
        case '0,2:':
            return false;
        case '0,1:':
        case '0,1,2:':
            return true;
        default: //No cookie
            return false;
    }
};
const LogRocketContext = React.createContext({
    initLogRocketSession: () => { },
});
/**
 *  LogRocket Provider for MLP
 *  Handles initialization of Logrocket and management of GDPR/CCPA privacy settings
 *  checks for GDPR/CCPA cookie and calls log rocket api to opt in or out of tracking based on the value of the cookie
 *
 *  opting out will delete all records we have under that user, opting in will remove the user
 *  from the 'do-not-track' array they have stored.
 *
 *  Connects to (and dependent on) the CookieConsent script, which inserts/updates the teconsent div on the app's page
 *
 *  exposes the init function, to allow for other parts of the app to re-trigger the init call if necessary
 *
 * @returns nothing - render this somewhere under the auth0Provider and it'll do it's thing
 */
const LogRocketProvider = ({ children }) => {
    const { user } = useAuthentication();
    // NOTE: component needs refs to let observer see updated value (stale closure issue otherwise)
    const logRocketInitialized = useRef(false);
    const [optedOut, setOptedOut] = useState(false);
    const cookieRef = useRef(Cookies.get('notice_gdpr_prefs') || '');
    const userRef = useRef(user);
    /** keeps the user ref up to date,
     * makes sure as soon as the user loads we update their prefs
     */
    useEffect(() => {
        userRef.current = user;
        checkAndUpdateUserPrefs(cookieRef.current);
    }, [user]);
    /**
     * Run initial logrocket init,
     * spin up observer for changes to teconsent div (indicates consent cookie has changed)
     */
    useEffect(() => {
        initLogRocketSession();
        const targetNode = document.getElementById('teconsent');
        if (targetNode) {
            const observer = new MutationObserver(() => {
                handleCookieChange();
            });
            observer.observe(targetNode, { attributes: true });
            return () => {
                observer.disconnect(); // Cleanup on unmount
            };
        }
    }, []);
    /**
     *  Handles acting on a change to the notice_gdpr_prefs cookie
     *  called when the observer notices a change to the teconsent div (useEffect above)
     *  triggers an update to preferences if the users cookie changed
     */
    const handleCookieChange = () => {
        const newCookieValue = Cookies.get('notice_gdpr_prefs') || '';
        if (newCookieValue !== cookieRef.current) {
            cookieRef.current = newCookieValue;
            checkAndUpdateUserPrefs(newCookieValue);
        }
    };
    /** GDPR/CCPA privacy check
     * Checks the value of the passed in cookie and calls the correct logrocket api to opt the user in or out of tracking
     * if it detects trackign is being turned on, calls the LogRocketProvider to start a new session
     * @param gdprPrefs string value of the notice_gdpr_prefs cookie
     */
    const checkAndUpdateUserPrefs = async (gdprPrefs) => {
        if (userRef.current?.userName) {
            if (isUserTrackable(gdprPrefs)) {
                setOptedOut(false); // reset opt out value
                await optIntoTracking(userRef.current?.userName);
                initLogRocketSession();
            }
            else {
                if (!optedOut) {
                    setOptedOut(true);
                    await optOutOfTracking(userRef.current?.userName);
                }
                else {
                }
            }
        }
    };
    /**
     * Logrocket Initialization
     * GDPR Requirement ***
     * Logrocket must abide by the notice_gdpr_prefs cookie, and not initialize if it's set to disallow tracking
     */
    const initLogRocketSession = () => {
        const gdprPrefs = Cookies.get('notice_gdpr_prefs') || '';
        const LOGROCKET_APP_ID = getEnvVariable('LOGROCKET_APP_ID');
        const canTrack = isUserTrackable(gdprPrefs);
        if (LOGROCKET_APP_ID && !logRocketInitialized.current && canTrack) {
            LogRocket.init(LOGROCKET_APP_ID, {
                browser: {
                    urlSanitizer: url => {
                        let sanitizedUrl = url;
                        sanitizedUrl = sanitizedUrl.replace(/access_token=([^&]*)/, 'access_token=**redacted**');
                        return sanitizedUrl;
                    },
                },
                dom: {
                    textSanitizer: true,
                    inputSanitizer: true,
                },
                network: {
                    requestSanitizer: request => {
                        request.body = undefined;
                        if (request.headers['Authorization']) {
                            request.headers['Authorization'] = '******';
                        }
                        if (request.headers['authorization']) {
                            request.headers['authorization'] = '******';
                        }
                        return request;
                    },
                    responseSanitizer: response => {
                        response.body = undefined;
                        return response;
                    },
                },
            });
            setupLogRocketReact(LogRocket);
            LogRocket.getSessionURL(sessionURL => {
                // @ts-ignore
                newrelic.setCustomAttribute('logrocket_url', sessionURL);
                // @ts-ignore
                window.logRocket = {
                    sessionURL: sessionURL,
                };
            });
        }
    };
    return <LogRocketContext.Provider value={{ initLogRocketSession }}>{children}</LogRocketContext.Provider>;
};
export default LogRocketProvider;
