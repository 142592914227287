import React from 'react';
import { Col, Label, NewDateInput, Row, themes } from '@digitools/honest-react';
import useExperience from 'src/hooks/useExperience';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from '../../../hooks/useCliDataContext';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from 'src/types/Experience';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import { validateDateIsWithinAYear } from 'src/packages/cli/utils/validationUtils';
import { useFormState } from 'informed';
import { addYears, isBefore, parse } from 'date-fns';
const ClaimBeginDate = () => {
    const { absenceData } = useCliDataContext();
    const { t } = useTranslate();
    const { experience, language } = useExperience();
    const { formLabels } = AboutYourAbsenceText;
    const { values } = useFormState();
    const labelText = experience === Experience.EMPLOYEE
        ? t(formLabels.claimBeginDateEE)
        : CustomEmployeeText(t(formLabels.claimBeginDateER));
    const validateClaimBeginDate = (input) => {
        if (input && values?.absenceData?.incidentDate) {
            if (isBefore(parse(input, 'P', new Date()), parse(values.absenceData.incidentDate, 'P', new Date()))) {
                return t({
                    english: 'Date of disability cannot be before the incident date',
                    spanish: 'La fecha de incapacidad no puede ser anterior a la fecha del incidente',
                });
            }
            else
                return validateDateIsWithinAYear(input, t, true);
        }
        else
            return validateDateIsWithinAYear(input, t, true);
    };
    return (<>
      <Row className='mb-3'>
        <Col size={12}>
          <Label className='mb-2'>{labelText}</Label>
        </Col>
        <Col size={12} sm={6} xl={4}>
          <NewDateInput data-testid='claimBeginDate' theme={themes.lincoln} field={'claimBeginDate'} lang={language} label={t(formLabels.claimBeginDateShortLabel)} placeholder={'mm/dd/yyyy'} ariaLabelText={labelText} validate={validateClaimBeginDate} initialValue={absenceData && absenceData.claimBeginDate} minDate={values?.absenceData?.incidentDate ? new Date(values?.absenceData?.incidentDate) : undefined} maxDate={addYears(new Date(), 1)}/>
        </Col>
      </Row>
    </>);
};
export default ClaimBeginDate;
