import React, { useState } from 'react';
import { Alert, Col, Container, Icon, Link, Loader, Row, SelectInput } from '@digitools/honest-react';
import { Language } from '../../../types/Language';
import { DocumentUploadDisplayTextEs } from '../../../components/documentUpload/language/DocumentUploadDisplayTextEs';
import { DocumentUploadDisplayTextEn } from '../../../components/documentUpload/language/DocumentUploadDisplayTextEn';
import axios from 'axios';
import styled from 'styled-components';
import useAuthentication from '../../../hooks/useAuthentication';
import useExperience from '../../../hooks/useExperience';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import { supportedFiles } from '../constants/CliConstants';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from 'src/types/Experience';
import useCliDataContext from '../hooks/useCliDataContext';
import UploadButton from 'src/components/documentUpload/UploadButton';
import { CategoryCol, StyledSerial } from 'src/components/documentUpload/CategorizationDocumentUpload';
import { docMeta } from 'src/packages/status/components/pages/ClaimDetails/docCategoryMapper';
import SelectCategory from 'src/components/documentUpload/SelectCategory';
import useTranslate from 'src/hooks/useTranslate';
import { getEnvVariable } from 'src/utils/envUtils';
import useCliCustomerConfigContext from 'src/packages/cli/hooks/useCliContext';
const StyledCol = styled(Col) `
  padding: 12px;
`;
const StyledLink = styled(Link) `
  font-weight: bold;
`;
const CliDocumentUpload = ({ productName }) => {
    const { language, experience } = useExperience();
    const { trackEvent } = useTealium();
    const { authHeader, user } = useAuthentication();
    const languageFile = language === Language.SPANISH ? DocumentUploadDisplayTextEs : DocumentUploadDisplayTextEn;
    const [errorMessage, setErrorMessage] = useState('');
    const [uploading, setUploading] = useState(false);
    const { documents, setDocuments, uploadedFiles, setUploadedFiles } = useCliDataContext();
    const [documentCategory, setDocumentCategory] = useState('');
    const { t } = useTranslate();
    const { isClaimLeaveCustomer } = useCliCustomerConfigContext();
    const [apiFailureMessage, setApiFailureMessage] = useState(false);
    let isCoordinatedClaim = false;
    //determine if it should see Claim categories or Coordinated categories
    if (productName === 'STD' && isClaimLeaveCustomer) {
        isCoordinatedClaim = true;
    }
    let productType = docMeta.s1.NoCategory;
    if (user?.customerInContext?.smallMid && experience === Experience.EMPLOYEE) {
        if (productName === 'STD') {
            productType = docMeta.docsNet.claimsEE;
        }
    }
    else if (experience === Experience.EMPLOYEE) {
        if (productName === 'LEAVE') {
            productType = docMeta.s1.leaves;
        }
        else if (isCoordinatedClaim) {
            productType = docMeta.s1.coordinatedEE;
        }
        else {
            productType = docMeta.s1.claimsEE;
        }
    }
    else if (experience === Experience.EMPLOYER) {
        if (productName === 'LEAVE') {
            productType = docMeta.s1.leaves;
        }
        else if (isCoordinatedClaim) {
            productType = docMeta.s1.coordinatedER;
        }
        else {
            productType = docMeta.s1.claimsER;
        }
    }
    const onUpload = async (event) => {
        if (event.target.files.length >= 1) {
            const selectedFile = event.target.files[0];
            const fileName = selectedFile.name;
            if (fileName.match(/^[a-zA-Z0-9_.\s-]*$/)) {
                const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
                if (supportedFiles.indexOf(fileType.toLocaleLowerCase()) >= 0) {
                    if (selectedFile.size <= 6291456 && selectedFile.size !== 0) {
                        await uploadDocument(selectedFile);
                        event.target.value = null;
                    }
                    else if (selectedFile.size === 0) {
                        setErrorMessage(languageFile.uploadFileEmpty);
                    }
                    else {
                        setErrorMessage(languageFile.uploadSizeExceeded);
                        trackEvent({
                            event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                            event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                            event_name: languageFile.uploadSizeExceeded,
                            event_version: 'upload document',
                        });
                    }
                }
                else {
                    setErrorMessage(languageFile.uploadFileTypeInvalid);
                    trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                        event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                        event_name: languageFile.uploadFileTypeInvalid,
                        event_version: 'upload document',
                    });
                }
            }
            else {
                setErrorMessage(languageFile.uploadFilenameInvalid);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                    event_name: languageFile.uploadFilenameInvalid,
                    event_version: 'upload document',
                });
            }
        }
    };
    const uploadDocument = async (file) => {
        try {
            setUploading(true);
            const response = await axios.post(`/mlp-ui-service/api/documents/upload`, getFormData(file, productName.toUpperCase()), {
                headers: {
                    Authorization: authHeader(),
                },
            });
            if (user?.customerInContext?.smallMid || (getEnvVariable('enableCategorizationForS1Cli'))) {
                const docMeta = documentCategory && getDocMeta(documentCategory);
                setUploadedFiles([
                    ...uploadedFiles,
                    {
                        documentName: file.name,
                        docId: response.data,
                        label: docMeta.label,
                        documentTypeTitle: docMeta.title,
                        documentType: docMeta.type,
                    },
                ]);
                setDocumentCategory('');
            }
            else {
                setDocuments([...documents, { name: file.name, uuid: response.data }]);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                    event_name: 'open',
                    event_version: 'review',
                    event_results: '1', //This will always be 1, you only can 'upload' one at a time, even if submitting ends up attaching all docs to one claim
                });
            }
            setErrorMessage('');
            setUploading(false);
        }
        catch (error) {
            setUploading(false);
            setDocumentCategory('');
            setApiFailureMessage(true);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                event_name: languageFile.failureMessage,
                event_version: 'upload document',
            });
        }
    };
    const getDocMeta = (val) => {
        const metaObj = t(productType).filter((e) => e.label == val);
        return metaObj[0];
    };
    const getFormData = (file, interviewType) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('interviewType', interviewType);
        formData.append('experience', experience);
        return formData;
    };
    const renderColumns = () => {
        return (<>
        <Col lg={6} md={6} sm={6} xs={6}>
          {' '}
          {documents.map((document, index) => {
                if (index <= 4)
                    return getSelectedDocuments(document);
            })}{' '}
        </Col>

        <Col lg={6} md={6} sm={6} xs={6}>
          {' '}
          {documents.map((document, index) => {
                if (index > 4)
                    return getSelectedDocuments(document);
            })}{' '}
        </Col>
      </>);
    };
    const renderCategoryColumns = () => {
        return (<>
        {uploadedFiles?.map((documentDetail, index) => {
                return (<>
              <CategoryCol md={5}>
                <StyledSerial>{index + 1}</StyledSerial>
                <SelectInput key={documentDetail.docId} field={'doc-category'} options={t(productType)} data-testid={'doc-category'} initialValue={documentDetail?.label} onChange={(e) => updateCategory(documentDetail, e.target.value)}/>
              </CategoryCol>

              <Col md={7}> {getCategoryDocuments(documentDetail)}</Col>
            </>);
            })}
      </>);
    };
    const getCategoryDocuments = (documentDetail) => {
        const handleDelete = () => {
            const newDocumentList = uploadedFiles.filter(file => file.docId !== documentDetail.docId);
            setUploadedFiles(newDocumentList);
        };
        return (<Row key={documentDetail.docId}>
        <StyledCol md={8}>
          <Row data-testid={'submitted-doc'} aria-labelledby={documentDetail.documentName}>
            <Icon style={{ margin: '2px .5rem 0 1rem' }} name='documents'/> {documentDetail.documentName}
          </Row>
        </StyledCol>
        <StyledCol md={3}>
          <StyledLink data-testid={'delete-doc'} aria-label={languageFile.delete} onClick={handleDelete}>
            <Icon color='secondary' name='trash'/> {languageFile.delete}
          </StyledLink>
        </StyledCol>
      </Row>);
    };
    const updateCategory = (docDetails, val) => {
        let updatedCategory = uploadedFiles.map(e => {
            let metaDoc = getDocMeta(val);
            if (e.docId == docDetails.docId) {
                return { ...e, label: metaDoc.label, title: metaDoc.title, type: metaDoc.type };
            }
            return e;
        });
        setUploadedFiles(updatedCategory);
    };
    const getSelectedDocuments = (document) => {
        const handleDelete = () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.DOCUMENT_UPLOAD,
                event_name: 'Delete Uploaded document',
            });
            // const newDocumentList = uploadedFiles.filter(file => file.documentId !== documentDetail.documentId);
            setDocuments(documents.filter(file => file.uuid !== document.uuid));
            //setUploadedFiles(newDocumentList);
        };
        return (<>
        {' '}
        <Row key={document.uuid}>
          <StyledCol xs={12} md={6} lg={6}>
            <Row data-testid={'submitted-doc'} aria-labelledby={document.name}>
              <Icon style={{ margin: '2px .5rem 0 1rem' }} name='documents'/> {document.name}
            </Row>
          </StyledCol>
          <StyledCol md={3}>
            <StyledLink data-testid={'delete-doc'} aria-label={languageFile.delete} onClick={handleDelete}>
              <Icon color='secondary' name='trash'/> {languageFile.delete}
            </StyledLink>
          </StyledCol>
        </Row>
      </>);
    };
    const documentUploadLabel = CustomEmployeeText(experience === Experience.EMPLOYEE
        ? `${languageFile.informationalMessage1} ${languageFile.yourAbsenceEE}`
        : `${languageFile.informationalMessage1} ${languageFile.yourAbsenceER}`);
    return (<div data-testid='cli-document-upload'>
      <Loader id='document-upload-spinner' isShown={uploading}>
        <Container>
          {errorMessage !== '' && (<Alert type={'error'} data-testid={'error-alert'}>
              <div>
                {/* REVERTING CHANGE FROM OGS-3128 */}
                {/* {t('claimErrorMessage')}
            <a href='/customer/home/contacts'>{t('contactUs')}</a> */}
                {errorMessage}
              </div>
            </Alert>)}
          {apiFailureMessage && (<Alert data-testid={'api-error-alert'} type={'error'} aria-labelledby='api-error-alert'>
              {languageFile.apiFailureMessage}
              <a href='/customer/home/contacts'>{languageFile.contactUs}</a>
            </Alert>)}
          <Row data-testid={'informationText'} style={{ marginTop: '1rem' }}>
            {documentUploadLabel}
          </Row>
          <Row data-testid={'acceptableDocumentsText'}>
            {languageFile.informationalMessage2}
          </Row>
          {(documents?.length < 10 || uploadedFiles?.length < 10) &&
            (user?.customerInContext?.smallMid || (getEnvVariable('enableCategorizationForS1Cli'))) ? (<>
                <Row>
                  <p style={{ fontWeight: 'bold', fontSize: 'larger' }}>{languageFile.uploadCategory}</p>
                </Row>
                <Row style={{ marginBottom: '0.5rem' }}>{renderCategoryColumns()}</Row>
                <Row data-testid={'Category'} style={{ marginTop: '1rem' }}>
                  <SelectCategory onUpload={onUpload} eventNum={''} uploadedFiles={uploadedFiles} documentCategory={documentCategory} uploading={uploading} productType={productType} setDocumentCategory={setDocumentCategory}/>
                </Row>
              </>) : (<>
                <Row style={{ marginTop: '2rem' }}>
                  <UploadButton onUpload={onUpload} eventNum={''}/>
                </Row>
                <Row style={{ marginBottom: '0.5rem' }}>{renderColumns()}</Row>
              </>)}
        </Container>
      </Loader>
    </div>);
};
export default CliDocumentUpload;
