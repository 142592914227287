import { Box, Heading, SelectInput } from '@digitools/honest-react';
import DentalText from 'src/packages/dental/constants/DentalText';
import { encode } from 'status/utilities/hash';
import React from 'react';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import useTranslate from 'src/hooks/useTranslate';
import { useNavigate } from 'react-router-dom';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
const MemberDropdown = () => {
    const navigate = useNavigate();
    const { currentMember, subscriber, tealiumFunction, infoLoading } = useDentalDataContext();
    const { t } = useTranslate();
    return (<>
      {!!currentMember && !!subscriber && (<Box style={{ paddingBottom: 0.2 }} color={'primary'} className={'pb-0'} data-testid='dental-dropdown-box' type={'header'}>
          <Heading color={'white'} elemType={'h4'} elemStyle={'h2'} style={{ paddingBottom: '10px', fontSize: '21px' }}>
            {t(DentalText.dropdownText)}
          </Heading>
          <SelectInput hidePlaceholder={true} ariaLabelText={'Member'} aria-haspopup={'listbox'} aria-expanded={false} data-testid='member-dropdown' field={'member'} title={'member-dropdown'} className={'font-weight-normal pt-0 pb-0'} style={{ lineHeight: '2rem' }} disabled={infoLoading} initialValue={`${currentMember.firstName} ${currentMember.lastName}`} options={[
                ...[
                    {
                        label: `${subscriber.firstName} ${subscriber.lastName}`,
                        value: subscriber.id,
                    },
                ],
                ...(subscriber.dependents?.map(dependent => ({
                    label: `${dependent.firstName} ${dependent.lastName}`,
                    value: dependent.id,
                })) || []),
            ]} onChange={(event) => {
                navigate(`/employee/dental/members/${encode(event.target.value)}`);
                if (event.target.selectedIndex === 0) {
                    tealiumFunction({ eventAction: TEALIUM_EVENT.EVENT_ACTION.TAB, memberEvent: 'subscriber' });
                }
                else {
                    tealiumFunction({
                        eventAction: TEALIUM_EVENT.EVENT_ACTION.TAB,
                        memberEvent: 'member' + (event.target.selectedIndex - 1),
                    });
                }
            }} required={true}/>
        </Box>)}
    </>);
};
export default MemberDropdown;
