import React, { Fragment } from 'react';
import { NewModal as Modal, Button, Row, Col } from '@digitools/honest-react';
import { useDisclosure } from '@mantine/hooks';
const desc = `You can create your own report by following the three easy steps below.
  Once you run your report you can view it and/or save it to your desktop`;
const steps = [
    ' Filter Data: Select criteria for filtering your report data.',
    ' Select Fields: Select data fields that you want displayed in your report.',
    ' Run Report: Review selected criteria. Choose run your report.',
];
const informations = [
    `Please use the onscreen Next and Back buttons to navigate.`,
    `For added security, this site has a 15-minute time-out feature.`,
    `The filter criteria and data fields that are available to you on these screens are those your company's administrator has assigned to you.
    If information appears to be missing, such as subsidiaries and/or locations that you need access to, please contact your administrator.`,
    `Report data is updated overnight. Updates are run Monday through Friday.`,
];
const GetHelpModal = ({ headerText }) => {
    const [isOpen, { open, close }] = useDisclosure(false);
    const RenderOpenButton = ({ showModal }) => {
        const handleOnClick = () => {
            showModal();
        };
        return (<Button aria-label='get help modal button' type='button' buttonType='text' data-testid='openModal' onClick={handleOnClick} style={{ float: 'right' }}>
        Get Help
      </Button>);
    };
    return (<>
      <Modal title={headerText} centered={true} opened={isOpen} onClose={() => {
            close();
        }} cancelButtonText={'Cancel'} submitButton={false} cancelButtonProps={{
            'data-testid': 'hideModal',
            buttonType: 'text',
            'aria-label': 'cancel get help modal'
        }}>
        <Row style={{ marginTop: '0.2rem' }}>
          <Col>{desc}</Col>
        </Row>
        <br />
        {steps.map((step, index) => (<Fragment key={index}>
            <Row>
              <Col>
                <b>{`Step ${index + 1}`} -</b>
                {step}
              </Col>
            </Row>
            <br />
          </Fragment>))}
        <Row>
          <Col>
            <i>{'Before starting, review the following information:'}</i>
          </Col>
        </Row>
        <ul>
          {informations.map((info, index) => (<li key={index}>{info}</li>))}
        </ul>
        <br />
      </Modal>
      <RenderOpenButton showModal={open}/>
    </>);
};
export default GetHelpModal;
