import { Button, Col, Form, Heading, Row, TextInput, NewDateInput } from '@digitools/honest-react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { findMyInformation } from '../../../api/CommPrefApi';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import useAuthentication from '../../../hooks/useAuthentication';
import useExperience from '../../../hooks/useExperience';
import usePortalMessenger from '../../../hooks/usePortalMessenger';
import useTealium from '../../../hooks/useTealium';
import { Language } from '../../../types/Language';
import { isNotEmpty } from '../../../utils/validationUtils';
import { EEfindMyInformationTextEn, EEfindMyInformationTextEs, } from './EEFindMyInformationText';
import { parse } from 'date-fns';
import { validateDob } from 'src/utils/fieldValidators';
import useTranslate from 'src/hooks/useTranslate';
const StyledHRElement = styled('hr') `
  margin: 0.75rem 0 0;
`;
const StyledMainHeading = styled(Heading) `
  margin-top: 0.5rem !important;
`;
const EEFindMyInformation = ({ onComplete, noRedirect }) => {
    const [disableButton, setDisableButton] = useState(true);
    const { t } = useTranslate();
    const { language } = useExperience();
    const { user, authHeader } = useAuthentication();
    const { trackEvent } = useTealium();
    const { addMessage } = usePortalMessenger();
    const checkForFormChange = (values) => {
        if (user) {
            if (values.clmLveNum) {
                setDisableButton(false);
            }
            else {
                setDisableButton(true);
            }
        }
    };
    const handleSubmit = async (values) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_status: TEALIUM_EVENT.EVENT_STATUS.SUBMITTED,
            event_type: TEALIUM_EVENT.EVENT_TYPE.SUBMIT_INFO,
            event_name: 'Submit-FindMyInfo',
            event_version: 'verify my identity'
        });
        try {
            const data = {
                ...values,
            };
            const response = await findMyInformation(data, authHeader());
            if (response.status === 200) {
                if (!noRedirect) {
                    window.location.assign('/customer/profile/alertsNotifications');
                }
                onComplete(true);
            }
            if (response.status !== 200) {
                addMessage({
                    type: 'error',
                    message: [EEfindMyInformationTextEn.warningMessage, EEfindMyInformationTextEs.warningMessage],
                    closeable: true,
                });
                onComplete(false);
            }
        }
        catch (err) {
            addMessage({
                type: 'error',
                message: [EEfindMyInformationTextEn.warningMessage, EEfindMyInformationTextEs.warningMessage],
                closeable: true,
            });
            onComplete(false);
        }
    };
    const displayText = language === Language.SPANISH ? EEfindMyInformationTextEs : EEfindMyInformationTextEn;
    return (<Form onSubmit={handleSubmit} onValueChange={checkForFormChange} data-public={true}>
      <StyledMainHeading elemType={'h2'} color={'primary'} id='commPrefHeading'>
        {displayText.heading}
      </StyledMainHeading>
      <StyledHRElement />
      <p>{displayText.body}</p>
      <p>{displayText.toolTip}</p>
      {/* <ToolTip iconColor={'accent1'} iconSize={'xs'} toolText={displayText.toolTip} /> */}
      <Row>
        <Col md={3} xs={12} className={'mt-3'}>
          <TextInput ariaLabelText={'Claim / Leave Number'} field={'clmLveNum'} label={displayText.number} type={'text'} validate={isNotEmpty} validateOnChange={true} validateOnBlur={true}/>
        </Col>
        <Col md={3} xs={12} className={'mt-3'}>
          <NewDateInput data-testid={'dob'} ariaLabelText={'Date of Birth'} label={displayText.dateOfBirth} field={'dob'} minDate={parse('01/01/1900', 'P', new Date())} maxDate={new Date()} validate={(value) => validateDob(value, t)} required={true}/>
        </Col>
        <Col md={3} xs={12} className={'mt-3'}>
          <TextInput ariaLabelText={'Last Name'} field={'lastName'} label={displayText.lastName} type={'text'} initialValue={user ? user.lastName : ''} readOnly={true} data-private={true}/>
        </Col>
        <Col md={3} xs={12} className={'mt-3'}>
          <Button aria-label={'Submit-FindMyInfo'} buttonType={'primary'} type='submit' disabled={disableButton}>
            {displayText.button}
          </Button>
        </Col>
      </Row>
    </Form>);
};
export default EEFindMyInformation;
