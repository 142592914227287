import { themes } from '@digitools/honest-react';
import React from 'react';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import useCustomerConfig from '../../../hooks/useCustomerConfig';
import useTealium from '../../../hooks/useTealium';
import useTranslate from '../../../hooks/useTranslate';
import { CustomEmployeeText } from '../../../utils/CustomExperienceText';
import { resize } from '../../header/HeaderUtils';
import SlideoutMenu from '../../header/SlideoutMenu';
import { MobileMenuLink } from './MobileNavLink';
import useAuthentication from '../../../hooks/useAuthentication';
import { getEnvVariable } from 'src/utils/envUtils';
import { CNVG, L4B, LAMS } from '../../../constants/ssoVariables';
const MobileNav = ({ openCloseMenu, isMobileMenuOpen }) => {
    const { t } = useTranslate();
    const { navigationMenu } = useCustomerConfig();
    const { trackEvent } = useTealium();
    const { user } = useAuthentication();
    const trackPageEvent = (eventName, externalLink, url) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: eventName,
            event_type: TEALIUM_EVENT.EVENT_TYPE.HEADER,
        });
    };
    const getNavigationUrl = (navLink) => {
        let url = '';
        if (!navLink.cnvgSso && !navLink.lamsSSO) {
            url = navLink.url;
            return url;
        }
        else {
            return url;
        }
    };
    const newOnClickFn = (navLink) => {
        let ssoGroup = '';
        if (navLink.cnvgSso) {
            ssoGroup = CNVG;
        }
        else if (navLink.lamsSSO) {
            ssoGroup = LAMS;
        }
        else if (navLink.l4bSso) {
            ssoGroup = L4B;
        }
        if (navLink.cnvgSso || navLink.lamsSSO) {
            window.open(getEnvVariable('mlpHomeUrl') + '/customer/ssologin/' + ssoGroup);
        }
    };
    // DENTAL only applies to new ee nav
    const DENTAL_NAV_IDS = ['DEN-PROD', 'DEN-NP', 'DENTAL-FORMS'];
    return (<>
      {navigationMenu &&
            navigationMenu.map(navMenu => (<>
            {/* Single Level 1 Nav Link */}
            {navMenu.navLink && (<MobileMenuLink style={{ color: themes.lincoln.palette.secondary, backgroundColor: themes.lincoln.palette.white }} id={(navMenu.navLink.id ? navMenu.navLink.id : navMenu.id) + '-link'} data-testid={'mobileNavL1Link'} data-public={true} aria-label={'navigation link'} to={navMenu.navLink.url} angular={navMenu.navLink.angular} externalLink={navMenu.navLink.externalLink} proveRequired={navMenu.navLink.proveRequired && !user?.isIdentityVerified} onClick={() => {
                        openCloseMenu();
                        trackPageEvent(navMenu.navLink.id, navMenu.navLink.externalLink, navMenu.navLink?.url);
                    }}>
                {CustomEmployeeText(t(navMenu.navLabel))}
              </MobileMenuLink>)}
            {/* SlideMenu for group(s) of Level 2 Links */}
            {!navMenu.navLink && navMenu.navGroups &&
                    <SlideoutMenu id={navMenu.id + '-menu'} data-public={true} nav={true} label={CustomEmployeeText(t(navMenu.navLabel))} parentResize={resize} isMobileMenuOpen={isMobileMenuOpen}>
                            {!navMenu.navLink && navMenu.navGroups && navMenu.navGroups.map((navGroup, i) => <>
                                    {/* {console.log(navGroup.groupLabel)} */}
                                    {!navGroup.groupLabel && navGroup.navLinks.map((navLink, j) => ((!navLink.url.includes('lincoln4benefits') && user?.isLfgUser) || (!user?.isLfgUser)) &&
                                !((navLink.id === 'VIS' || navLink.id === 'DHMO') && (navLink.externalLink)) &&
                                <MobileMenuLink style={{ color: themes.lincoln.palette.secondary, backgroundColor: themes.lincoln.palette.white }} key={j} data-public={true} id={navLink.id} data-testid={'mobileNavL2Link'} aria-label={'navigation link'} to={navLink.url} angular={navLink.angular} externalLink={navLink.externalLink} proveRequired={navLink.proveRequired && !user?.isIdentityVerified} onClick={() => { openCloseMenu(); trackPageEvent(navLink.id, navLink.externalLink, navLink.url); }}>
                                            {CustomEmployeeText(t(navLink.label))}
                                        </MobileMenuLink>)}
                                    {navGroup.groupLabel &&
                                <SlideoutMenu id={navGroup.id + '-group'} data-public={true} data-testid={'mobileNavGroup'} nav={true} label={navGroup.groupLabel ? t(navGroup.groupLabel) : 'noLabel'} parentResize={resize} isMobileMenuOpen={isMobileMenuOpen}>
                                            {navGroup.navLinks.map((navLink, j) => (((!navLink.url.includes('lincoln4benefits') || (DENTAL_NAV_IDS.includes(navLink.id) && getEnvVariable('enableConsumerDental'))) && user?.isLfgUser)
                                        || (!user?.isLfgUser)) &&
                                        (!navLink.cnvgSso && !navLink.lamsSSO) &&
                                        !((navLink.id === 'VIS' || navLink.id === 'DHMO') && (navLink.externalLink)) &&
                                        <MobileMenuLink style={{ color: themes.lincoln.palette.secondary, backgroundColor: themes.lincoln.palette.white }} key={j} data-public={true} id={navLink.id} data-testid={'mobileNavL2Link'} aria-label={'navigation link'} to={navLink.url} angular={navLink.angular} externalLink={navLink.externalLink} proveRequired={navLink.proveRequired && !user?.isIdentityVerified} onClick={() => { openCloseMenu(); trackPageEvent(navLink.id, navLink.externalLink, navLink.url); }}>
                                                    {CustomEmployeeText(t(navLink.label))} 
                                                                                                  
                                                </MobileMenuLink>)}
                                             {navGroup.navLinks.map((navLink, j) => (user?.isLfgUser) && (navLink.cnvgSso || navLink.lamsSSO) &&
                                        <MobileMenuLink style={{ color: themes.lincoln.palette.secondary, backgroundColor: themes.lincoln.palette.white }} id={navLink.id} data-public={true} data-testid={'mobileNavL2Link'} aria-label={'navigation link'} to={getNavigationUrl(navLink)} angular={navLink.angular} externalLink={navLink.externalLink} proveRequired={navLink.proveRequired && !user.isIdentityVerified} onClick={() => { (navLink.cnvgSso || navLink.lamsSSO) ? newOnClickFn(navLink) : openCloseMenu(); trackPageEvent(navLink.id, navLink.externalLink, navLink.url); }}>
                                                    {CustomEmployeeText(t(navLink.label))} 
                                                </MobileMenuLink>)}                                           
                                        
                                        
                                        </SlideoutMenu>}
                                </>)}
                        </SlideoutMenu>}
          </>))}
    </>);
};
export default MobileNav;
