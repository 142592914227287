import { Col, Heading, NewDateInput, Row, SelectInput, TextInput, themes } from '@digitools/honest-react';
import { Scope } from 'informed';
import React from 'react';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import useTranslate from 'src/hooks/useTranslate';
import { AdditionalInfoText } from 'src/packages/cli/constants/AdditionalInfoText';
import { CLI_EMPLOYEE_PHYSICAL_DEMANDS, CLI_EMPLOYEE_TYPE, CLI_STATES, INCOME_TYPE_OPTIONS } from 'src/packages/cli/constants/CliConstants';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import { rangedNumberValidation, validateDateIsOnOrBeforeToday, validateJobRequirementTextArea } from 'src/packages/cli/utils/validationUtils';
import { formatCurrency, lettersAndNumbersOnly, lettersNumbersAndSpacesOnly, numbersOnly } from 'src/utils/validationUtils';
import WorkSchedule from './WorkSchedule';
const EmploymentDetails = () => {
    const { t } = useTranslate();
    const { additionalDetails } = useCliDataContext();
    const equalOneHundredValidation = (value, values) => {
        // @ts-ignore
        const employeePremiumContribution = parseInt(values?.additionalDetails?.EEPremCon);
        // @ts-ignore
        const employerPremiumContribution = parseInt(values?.additionalDetails?.ERPremCon);
        return value && (employeePremiumContribution + employerPremiumContribution !== 100) ? 'Both fields must equal 100.' : undefined;
    };
    const validatePremiums = (value, values) => equalOneHundredValidation(value, values) || rangedNumberValidation(value, 0, 100);
    const validateEmployeePremium = (value, values) => validatePremiums(value, values);
    const validateEmployerPremium = (value, values) => validatePremiums(value, values);
    return (<div data-testid='employment-details'>
            {/* @ts-ignore */}
            <Scope scope="additionalDetails">
                <BorderLessGrayBox>
                    <Heading elemType='h3' elemStyle='h4'>{t(AdditionalInfoText.pageTitle)}</Heading>
                </BorderLessGrayBox>
                <Row>
                    <Col md={4} xs={12}>
                        <NewDateInput 
    // TODO: Need to find a way to make a DateInput field optional with between
    data-testid='dateOfHire' theme={themes.lincoln} field={'dateOfHire'} label={t(AdditionalInfoText.dateOfHireLabel)} placeholder={'mm/dd/yyyy'} ariaLabelText={t(AdditionalInfoText.dateOfHireLabel)} minDate={new Date('01/01/1900')} maxDate={new Date()} validate={(input) => validateDateIsOnOrBeforeToday(input, t)} initialValue={additionalDetails && additionalDetails.dateOfHire}/>
                    </Col>
                    <Col md={4} xs={12}>
                        <TextInput data-testid='employeeID' field={'employeeID'} label={CustomEmployeeText(t(AdditionalInfoText.employeeIDLabel))} placeholder={CustomEmployeeText(t(AdditionalInfoText.employeeIDLabel))} ariaLabelText={CustomEmployeeText(t(AdditionalInfoText.employeeIDLabel))} mask={lettersAndNumbersOnly} initialValue={additionalDetails && additionalDetails.employeeID}/>
                    </Col>
                    <Col md={4} xs={12}>
                        <SelectInput field={'workState'} data-testid='workState' ariaLabelText={t(AdditionalInfoText.workStateLabel)} label={t(AdditionalInfoText.workStateLabel)} placeholderText={t(AdditionalInfoText.workStateLabel)} options={CLI_STATES} initialValue={additionalDetails && additionalDetails.workState}/>
                    </Col>
                    <Col md={4} xs={12}>
                        <TextInput data-testid='subsidiary' field={'subsidiary'} initialValue={additionalDetails && additionalDetails.subsidiary} label={t(AdditionalInfoText.subsidaryLabel)} placeholder={t(AdditionalInfoText.subsidaryLabel)} ariaLabelText={t(AdditionalInfoText.subsidaryLabel)} mask={lettersNumbersAndSpacesOnly}/>
                    </Col>
                    <Col md={4} xs={12}>
                        <TextInput data-testid='locationBranch' field={'locationBranch'} initialValue={additionalDetails && additionalDetails.locationBranch} label={t(AdditionalInfoText.locationBranchLabel)} placeholder={t(AdditionalInfoText.locationBranchLabel)} ariaLabelText={t(AdditionalInfoText.locationBranchLabel)} mask={numbersOnly} maxLength={30}/>
                    </Col>
                    <Col md={4} xs={12}>
                        <SelectInput field={'employeeType'} initialValue={additionalDetails && additionalDetails.employeeType} data-testid='employeeType' ariaLabelText={CustomEmployeeText(t(AdditionalInfoText.employeeTypeLabel))} label={CustomEmployeeText(t(AdditionalInfoText.employeeTypeLabel))} placeholderText={CustomEmployeeText(t(AdditionalInfoText.employeeTypeLabel))} options={CLI_EMPLOYEE_TYPE}/>
                    </Col>
                    <Col md={8} xs={12}>
                        <TextInput data-testid='jobReqDesc' label={t(AdditionalInfoText.jobReqsLabel)} ariaLabelText={t(AdditionalInfoText.jobReqsLabel)} field={'jobReqDesc'} initialValue={additionalDetails && additionalDetails.jobReqDesc} resize={'vertical'} style={{ height: '5rem', minHeight: '5rem' }} textarea={true} validate={(props) => validateJobRequirementTextArea(props, t)} validateOnBlur={true} validateOnChange={true}/>
                    </Col>
                    <Col md={4} xs={12}>
                        <SelectInput data-testid='physicalDemands' ariaLabelText={t(AdditionalInfoText.physicalDemandsLabel)} field={'physicalDemands'} label={t(AdditionalInfoText.physicalDemandsLabel)} placeholderText={t(AdditionalInfoText.physicalDemandsLabel)} options={CLI_EMPLOYEE_PHYSICAL_DEMANDS} initialValue={additionalDetails && additionalDetails.physicalDemands}/>
                    </Col>
                    <Col md={4} xs={12}>
                        <SelectInput data-testid='payType' field={'payType'} initialValue={additionalDetails && additionalDetails.payType} options={INCOME_TYPE_OPTIONS} ariaLabelText={t(AdditionalInfoText.payTypeLabel)} label={t(AdditionalInfoText.payTypeLabel)} placeholderText={t(AdditionalInfoText.payTypeLabel)}/>
                    </Col>
                    <Col md={4} xs={12}>
                        <TextInput data-testid='earnings' field={'earnings'} initialValue={additionalDetails && additionalDetails.earnings} label={t(AdditionalInfoText.earningsLabel)} placeholder={t(AdditionalInfoText.earningsLabel)} ariaLabelText={t(AdditionalInfoText.earningsLabel)} mask={formatCurrency}/>
                    </Col>
                    <Col md={3} xs={12}>
                        <TextInput data-testid='incomeClass' field={'incomeClass'} initialValue={additionalDetails && additionalDetails.incomeClass} label={t(AdditionalInfoText.incomeClassLabel)} placeholder={t(AdditionalInfoText.incomeClassLabel)} ariaLabelText={t(AdditionalInfoText.incomeClassLabel)} mask={lettersAndNumbersOnly} maxLength={2}/>
                    </Col>
                    <Col md={4} xs={12}>
                        <TextInput data-testid='benefitPercent' field={'benefitPercent'} initialValue={additionalDetails && additionalDetails.benefitPercent} label={t(AdditionalInfoText.benefitPercentLabel)} placeholder={t(AdditionalInfoText.benefitPercentLabel)} ariaLabelText={t(AdditionalInfoText.benefitPercentLabel)} validateOnBlur={true} validateOnChange={true} validate={(value) => rangedNumberValidation(value, 0, 100)}/>
                    </Col>
                    <Col md={4} xs={12}>
                        <TextInput data-testid='EEPremCon' field={'EEPremCon'} initialValue={additionalDetails && additionalDetails.EEPremCon} notify={['ERPremCon']} validate={validateEmployeePremium} label={CustomEmployeeText(t(AdditionalInfoText.EEPremConLabel))} placeholder={CustomEmployeeText(t(AdditionalInfoText.EEPremConLabel))} ariaLabelText={CustomEmployeeText(t(AdditionalInfoText.EEPremConLabel))} validateOnBlur={true} validateOnChange/>
                    </Col>
                    <Col md={4} xs={12}>
                        <TextInput data-testid='ERPremCon' field={'ERPremCon'} initialValue={additionalDetails && additionalDetails.ERPremCon} notify={['EEPremCon']} validate={validateEmployerPremium} label={t(AdditionalInfoText.ERPremConLabel)} placeholder={t(AdditionalInfoText.ERPremConLabel)} ariaLabelText={t(AdditionalInfoText.ERPremConLabel)} validateOnBlur={true} validateOnChange/>
                    </Col>
                </Row>
                <WorkSchedule />
            </Scope>
        </div>);
};
export default EmploymentDetails;
