import React from 'react';
import useTranslate from '../../../../../hooks/useTranslate';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import Col from '@digitools/honest-react/dist/lib/components/Col';
import TextInput from '@digitools/honest-react/dist/lib/components/TextInput';
import { PersonalInformationText } from '../../../i18n/ApplicantInformationText';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { NewDateInput } from '@digitools/honest-react';
import { middleInitialMask, numberOnlyMask } from '../../../utils/InputMasks';
import SelectInput from '@digitools/honest-react/dist/lib/components/SelectInput';
import Radio from '@digitools/honest-react/dist/lib/components/Radio';
import RadioGroup from '@digitools/honest-react/dist/lib/components/Radio/RadioGroup';
import useExperience from '../../../../../hooks/useExperience';
import { Language } from '../../../../../types/Language';
import styled from 'styled-components';
import Label from '@digitools/honest-react/dist/lib/components/Label';
import { fieldRequired, selectInputErrorMessage, ssnValidation, validateName, weightValidation, } from '../../../utils/FormFieldValidations';
import { format, parse, startOfTomorrow } from 'date-fns';
import useEoiContext from '../../../hooks/useEoiContext';
import { EoiGeneralText } from '../../../i18n/EoiText';
import { isSitusStateMA } from '../../../utils/HelperFunctions';
import { useFormState } from 'informed';
import LanguageToggle from '../../../../../utils/LanguageToggle';
import useAuthentication from 'src/hooks/useAuthentication';
import { validateDateStartOfTomorrow } from 'src/utils/fieldValidators';
const RadioContainer = styled('div') `
  display: flex;
  flex-direction: row;
  @media (max-width: 500px) {
    flex-direction: column;
  }
  > label {
    margin-right: 1rem;
  }
`;
const getEmployeeSSNFromForm = (formApplicants) => {
    if (formApplicants.employee && formApplicants.employee.socialSecurityNumber) {
        return formApplicants.employee.socialSecurityNumber;
    }
    else {
        return '';
    }
};
const getSpouseSSNFromForm = (formApplicants) => {
    if (formApplicants.spouse && formApplicants.spouse.socialSecurityNumber) {
        return formApplicants.spouse.socialSecurityNumber;
    }
    else {
        return '';
    }
};
const getDependentSSNArrayFromForm = (formApplicants) => {
    let dependentSSNs = [];
    if (formApplicants.dependents) {
        formApplicants.dependents.forEach(dependent => {
            if (dependent.socialSecurityNumber) {
                dependentSSNs.push(dependent.socialSecurityNumber);
            }
        });
    }
    return dependentSSNs;
};
const DuplicateSsnError = () => {
    return <LanguageToggle en={EoiGeneralText.duplicateSsnError.english} es={EoiGeneralText.duplicateSsnError.spanish}/>;
};
const PersonalInformation = (props) => {
    const { user } = useAuthentication();
    const { t } = useTranslate();
    const { language } = useExperience();
    const { interviewAnswers, initialInformation } = useEoiContext();
    const formState = useFormState();
    const displayApplicantFields = props.type === 'employee' ? interviewAnswers.applicantTypes.employee : true;
    // Display these options for states NOT in the 2 arrays below.
    const displayCivilUnion = initialInformation &&
        ['KY', 'NH', 'TX', 'NY', 'VA'].indexOf(initialInformation.employerConfiguration.situsStateCode.toUpperCase()) < 0;
    const displayDomesticPartner = initialInformation && ['KY'].indexOf(initialInformation.employerConfiguration.situsStateCode.toUpperCase()) < 0;
    const ssnPrefill = () => {
        return props.type === 'employee' && interviewAnswers && interviewAnswers.verificationInformation
            ? interviewAnswers.verificationInformation.verificationSsn
            : undefined;
    };
    const sexValues = () => {
        return [
            {
                label: t(PersonalInformationText.male),
                value: 'M',
            },
            {
                label: t(PersonalInformationText.female),
                value: 'F',
            },
        ];
    };
    const numberSelectValues = (start, end) => {
        const arr = [];
        for (let i = start; i <= end; i++) {
            arr.push({
                label: i.toString(),
                value: i,
            });
        }
        return arr;
    };
    const ssnIsUnique = (ssn) => {
        const formApplicants = formState.values;
        switch (props.type) {
            case 'employee':
                if (ssn === getSpouseSSNFromForm(formApplicants) ||
                    getDependentSSNArrayFromForm(formApplicants).indexOf(ssn) > -1) {
                    return <DuplicateSsnError />;
                }
                return undefined;
            case 'spouse':
                if (ssn === getEmployeeSSNFromForm(formApplicants) ||
                    getDependentSSNArrayFromForm(formApplicants).indexOf(ssn) > -1) {
                    return <DuplicateSsnError />;
                }
                return undefined;
            case 'dependent':
                if (formApplicants.dependents) {
                    const duplicateSSNArray = formApplicants.dependents.filter((dependent) => dependent.socialSecurityNumber === ssn);
                    if (duplicateSSNArray.length > 1 ||
                        ssn == getSpouseSSNFromForm(formApplicants) ||
                        ssn === getEmployeeSSNFromForm(formApplicants)) {
                        return <DuplicateSsnError />;
                    }
                }
                return undefined;
        }
    };
    // get prepop values from interviewAnswersApplicant
    let applicantInformation = {
        disableFirstName: false,
        disableLastName: false,
        disableMiddleInitial: false,
        disableSocialSecurityNumber: false,
        disableDateOfBirth: false,
        disableSex: false,
        disableBirthState: false,
        ssnFound: false,
    };
    if (props.interviewAnswersApplicant) {
        applicantInformation = props.interviewAnswersApplicant;
    }
    return (<div style={{ marginBottom: '1rem' }} data-public={true}>
      <Row data-private={true}>
        <Col md={6} lg={language === Language.ENGLISH ? 3 : 6}>
          <TextInput theme={themes.lincoln} label={t(PersonalInformationText.firstName)} field={'firstName'} data-testid={'firstName'} validateOnBlur={true} validateOnChange={true} validate={validateName} initialValue={applicantInformation.firstName} disabled={user && (user.ssoUser || (user.isLfgUser && !user.isIdentityVerified)) ? false : applicantInformation.disableFirstName}/>
        </Col>
        <Col md={6} lg={language === Language.ENGLISH ? 3 : 6}>
          <TextInput theme={themes.lincoln} label={t(PersonalInformationText.middleInitial)} field={'middleInitial'} mask={middleInitialMask} initialValue={applicantInformation.middleInitial} disabled={user && user.ssoUser ? false : applicantInformation.disableMiddleInitial}/>
        </Col>
        <Col md={6} lg={6}>
          <TextInput theme={themes.lincoln} label={t(PersonalInformationText.lastName)} field={'lastName'} validateOnBlur={true} validateOnChange={true} validate={validateName} initialValue={applicantInformation.lastName} disabled={user && user.ssoUser ? false : applicantInformation.disableLastName}/>
        </Col>
      </Row>

      <Row>
        <Col md={6} lg={6} xl={language === Language.ENGLISH ? 3 : 6} data-private={true}>
          <TextInput theme={themes.lincoln} label={t(PersonalInformationText.socialSecurityNumber)} field={'socialSecurityNumber'} initialValue={applicantInformation.socialSecurityNumber ? applicantInformation.socialSecurityNumber : ssnPrefill()} disabled={applicantInformation.disableSocialSecurityNumber} validateOnBlur={true} validateOnChange={true} type={'textmask'} validate={(ssn) => {
            const formError = ssnValidation(ssn, applicantInformation.ssnFound);
            if (formError) {
                return formError;
            }
            else {
                return ssnIsUnique(ssn);
            }
        }}/>
        </Col>

        {displayApplicantFields && (<Col md={6} lg={6} xl={language === Language.ENGLISH ? 3 : 6}>
            <NewDateInput data-testid={'dateOfBirth'} minDate={parse('01/01/1900', 'P', new Date())} maxDate={startOfTomorrow()} validate={(value) => validateDateStartOfTomorrow(value, t, undefined, true)} theme={themes.lincoln} field={'dateOfBirth'} lang={language} label={t(PersonalInformationText.dateOfBirth)} initialValue={applicantInformation.dateOfBirth
                ? format(new Date(applicantInformation.dateOfBirth), 'MM/dd/yyyy')
                : undefined} disabled={applicantInformation.disableDateOfBirth}/>
          </Col>)}
        {displayApplicantFields && (<Col md={6} lg={3} data-private={true}>
            <SelectInput field={'sex'} label={isSitusStateMA(initialInformation)
                ? t(PersonalInformationText.gender)
                : t(PersonalInformationText.sexAtBirth)} options={sexValues()} theme={themes.lincoln} placeholderText={isSitusStateMA(initialInformation)
                ? t(PersonalInformationText.gender)
                : t(PersonalInformationText.sexAtBirth)} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} errorMessage={selectInputErrorMessage} initialValue={applicantInformation.sex} disabled={false}/>
          </Col>)}
        {displayApplicantFields && (<Col md={6} lg={3}>
            <SelectInput field={'birthState'} label={t(PersonalInformationText.birthState)} options={[...props.states, { label: t(EoiGeneralText.other), value: 'OT' }]} theme={themes.lincoln} placeholderText={t(PersonalInformationText.birthState)} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} errorMessage={selectInputErrorMessage} initialValue={applicantInformation.birthState} disabled={applicantInformation.disableBirthState} data-testid={'birth-state'}/>
          </Col>)}
      </Row>
      {props.type === 'employee' && (<div style={{ marginBottom: '1rem' }}>
          <RadioGroup label={t(PersonalInformationText.maritalStatus)} field={'maritalStatus'} theme={themes.lincoln} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={applicantInformation.maritalStatus}>
            <RadioContainer>
              <Radio data-testid={'single'} name='maritalStatus' theme={themes.lincoln} label={t(PersonalInformationText.single)} value={'single'}/>
              <Radio data-testid={'married'} name='maritalStatus' theme={themes.lincoln} label={t(PersonalInformationText.married)} value={'married'}/>
              {displayDomesticPartner && (<Radio data-testid={'domesticPartner'} name='maritalStatus' theme={themes.lincoln} label={t(PersonalInformationText.domesticPartner)} value={'domesticPartner'}/>)}
              {displayCivilUnion && (<Radio data-testid={'civilUnion'} name='maritalStatus' theme={themes.lincoln} label={t(PersonalInformationText.civilUnion)} value={'civilUnion'}/>)}
            </RadioContainer>
          </RadioGroup>
        </div>)}

      {displayApplicantFields && (<Row>
          <Col sm={6} md={6}>
            <Row>
              <Col>
                <Label theme={themes.lincoln}>{t(PersonalInformationText.height)}</Label>
              </Col>
            </Row>

            <Row style={{ marginTop: '0.5rem' }}>
              <Col sm={6} md={6}>
                <SelectInput field={'feet'} label={t(PersonalInformationText.feet)} options={numberSelectValues(1, 8)} theme={themes.lincoln} placeholderText={t(PersonalInformationText.feet)} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={applicantInformation.feet} errorMessage={selectInputErrorMessage}/>
              </Col>
              <Col sm={6} md={6}>
                <SelectInput field={'inches'} label={t(PersonalInformationText.inches)} options={numberSelectValues(0, 11)} theme={themes.lincoln} placeholderText={t(PersonalInformationText.inches)} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={applicantInformation.inches} errorMessage={selectInputErrorMessage}/>
              </Col>
            </Row>
          </Col>

          <Col sm={6} md={6}>
            <Row>
              <Col>
                <Label theme={themes.lincoln}>{t(PersonalInformationText.weight)}</Label>
              </Col>
            </Row>

            <Row style={{ marginTop: '0.5rem' }}>
              <Col sm={6} md={6}>
                <TextInput theme={themes.lincoln} label={t(PersonalInformationText.weightPlaceholder)} field={'weight'} validateOnBlur={true} validateOnChange={true} validate={weightValidation} 
        //@ts-ignore //FIXME: type error
        initialValue={applicantInformation.weight} mask={numberOnlyMask}/>
              </Col>
            </Row>
          </Col>
        </Row>)}
    </div>);
};
export default PersonalInformation;
