import { Col, NewDateInput, Row, SelectInput, TextInput } from '@digitools/honest-react';
import React, { useState } from 'react';
import { isNotEmpty, optionalFieldCharacters } from '../../../../../utils/validationUtils';
import { LCI_EMPLOYEE_REASON_FOR_CHANGE } from '../../../constants/LCIConstant';
import { validateDateIsOnOrBeforeToday } from 'src/packages/cli/utils/validationUtils';
import { dateMask } from 'src/packages/eoi/utils/InputMasks';
import useTranslate from 'src/hooks/useTranslate';
import { parse } from 'date-fns';
/*
 ****** Important Dates ****
 */
const ImportantDateInformation = ({ claimant, event }) => {
    const [employeeReason, setReason] = useState('');
    const { t } = useTranslate();
    const validateReason = (input) => {
        setReason(input);
    };
    let showDateOfDeath = false;
    let showDependentEffectiveDate = false;
    let showDateOfDisability = false;
    let showReasonForLeaving = false;
    switch (event) {
        case 'death':
            showDateOfDeath = true;
            if (claimant === 'dependent') {
                showDependentEffectiveDate = true;
            }
            break;
        case 'accelerated death':
            if (claimant === 'dependent') {
                showDependentEffectiveDate = true;
            }
            break;
        case `dismemberment / loss of sight`:
            if (claimant === 'dependent') {
                showDependentEffectiveDate = true;
            }
            break;
        case 'total disability':
            showDateOfDisability = true;
            showReasonForLeaving = true;
            break;
    }
    return (<>
      <Row className={'mt-0'}>
        <Col xl={4} md={6} sm={12} className={'mt-3'}>
          <NewDateInput data-testid='policyInfo_dateEmployed' field={'policyInfo_dateEmployed'} label={'Date Employed'} ariaLabelText={'Date Employed'} required={true} validate={(props) => validateDateIsOnOrBeforeToday(props, t, true)} minDate={parse('01/01/1900', 'P', new Date())} maxDate={new Date()}/>
        </Col>
        <Col xl={4} md={6} sm={12} className={'mt-3'}>
          <NewDateInput data-testid='policyInfo_lastWorked' field={'policyInfo_lastWorked'} label={'Last Worked'} ariaLabelText={'Last Worked'} required={true} validate={(props) => validateDateIsOnOrBeforeToday(props, t, true)} minDate={parse('01/01/1900', 'P', new Date())} maxDate={new Date()}/>
        </Col>
        <Col xl={4} md={6} sm={12} className={'mt-3'}>
          <NewDateInput data-testid='policyInfo_lastPaid' field={'policyInfo_lastPaid'} label={'Date Premium Last Paid (optional)'} ariaLabelText={'Date Premium Last Paid'} validate={(props) => validateDateIsOnOrBeforeToday(props, t, false)} minDate={parse('01/01/1900', 'P', new Date())} maxDate={new Date()}/>
        </Col>

        {showDateOfDisability && (<Col xl={4} md={6} sm={12}>
            <NewDateInput data-testid='policyInfo_dateOfDisability' field={'policyInfo_dateOfDisability'} label={'Date of Disability'} ariaLabelText={'Date of Disability'} required={true} validate={(props) => validateDateIsOnOrBeforeToday(props, t, true)}/>
          </Col>)}

        {showReasonForLeaving && (<Col xl={4} md={6} sm={12} className={(event === 'total disability' ? 'mt-0' : 'mt-3')}>
            <TextInput field={'policyInfo_reasonForLeaving'} ariaLabelText={'Reason for Leaving Work'} label={'Reason for Leaving Work (optional)'} mask={optionalFieldCharacters} type={'text'} validateOnBlur={true}/>
          </Col>)}

        {showDateOfDeath && claimant !== 'dependent' && (<Col xl={4} md={6} sm={12}>
            <NewDateInput data-testid='policyInfo_dateOfDeath' field={'policyInfo_dateOfDeath'} label={'Date of Death (optional)'} ariaLabelText={'Date of Death'} validate={(props) => validateDateIsOnOrBeforeToday(props, t, false)} mask={dateMask}/>
          </Col>)}

        {showDependentEffectiveDate && (<Col xl={4} md={6} sm={12}>
            <NewDateInput data-testid='policyInfo_dependentEffectiveDate' field={'policyInfo_dependentEffectiveDate'} label={'Dependent\'s Coverage Effective Date (optional)'} ariaLabelText={`Dependent's Coverage Effective Date`} validate={(props) => validateDateIsOnOrBeforeToday(props, t, false)} mask={dateMask}/>
          </Col>)}

        <Col xl={4} md={6} sm={12}>
          <NewDateInput data-testid='policyInfo_dateBenefitChange' field={'policyInfo_dateBenefitChange'} label={'Last Benefits Change Date (optional)'} ariaLabelText={'Last Increase/Decrease in Benefits'} validate={(props) => validateDateIsOnOrBeforeToday(props, t, false)} mask={dateMask}/>
        </Col>

        <Col xl={4} md={6} sm={12}>
          <SelectInput ariaLabelText={'Reason'} field={'policyInfo_reason'} label={'Reason (optional)'} options={LCI_EMPLOYEE_REASON_FOR_CHANGE} validate={validateReason} validateOnChange={true} placeholderText={'Reason (optional)'}/>
        </Col>
        {employeeReason == 'Other' && (<Col xl={4} md={6} sm={12}>
            <TextInput field={'policyInfo_otherReason'} ariaLabelText={'Please Explain:'} label={'Please Explain:'} mask={optionalFieldCharacters} validate={isNotEmpty} validateOnBlur={true} type={'text'}/>
          </Col>)}
      </Row>
    </>);
};
export default ImportantDateInformation;
