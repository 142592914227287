export function trimLeadingZeros(officeNum) {
    if (officeNum) {
        return officeNum.toUpperCase().trim().replace(/^0+/, '');
    }
    return officeNum;
}
export const LTD_PRODUCT_TYPES = ['LTD', 'LTDC'];
export const NON_LTD_PRODUCT_TYPES = ['STD', 'STDCP', 'SALCN', 'STAT', 'AS']; // 'LEAVE' is separate
export const REGIONAL_CLAIM_PRODUCT_TYPES = ['STD', 'STDCP', 'SALCN', 'STAT'];
export function getOffice(cliOfficeInfo, prodType, caseManager) {
    const officePhoneNumber = (prodType === "LTD" ? getOfficeCustomizedInfo(cliOfficeInfo, 'customLTDVanityLine', 'selectedLTDPhone') : prodType === 'LEAVE' ? getOfficeCustomizedInfo(cliOfficeInfo, 'customLeaveVanityLine', 'selectedLeavePhone') : getOfficeCustomizedInfo(cliOfficeInfo, 'customSTDVanityLine', 'selectedSTDPhone'));
    const officeFaxNumber = (prodType === "LTD" ? getOfficeCustomizedInfo(cliOfficeInfo, 'customLTDVanityFax', 'selectedLTDFax') : prodType === 'LEAVE' ? getOfficeCustomizedInfo(cliOfficeInfo, 'customLeaveVanityFax', 'selectedLeaveFax') : getOfficeCustomizedInfo(cliOfficeInfo, 'customSTDVanityFax', 'selectedSTDFax'));
    let emailAddress;
    if (cliOfficeInfo?.statusContactDetails?.regionalEmployer && REGIONAL_CLAIM_PRODUCT_TYPES?.includes(prodType)) {
        emailAddress = cliOfficeInfo.statusContactDetails.regionalEmailAddress;
    }
    else if (cliOfficeInfo?.statusContactDetails?.majorIntegratedEmployer && (NON_LTD_PRODUCT_TYPES.includes(prodType) || prodType.includes('PL'))) { //|| prodType.includes('PMFL')
        emailAddress = cliOfficeInfo.statusContactDetails.customLeaveVanityEmail;
    }
    else if (prodType === 'LEAVE') {
        emailAddress = getOfficeCustomizedInfo(cliOfficeInfo, 'customLeaveVanityEmail', 'selectedLeaveEmail');
    }
    else {
        emailAddress = caseManager ? caseManager.emailAddr : '';
    }
    return {
        officePhoneNumber,
        officeFaxNumber,
        emailAddress,
        regionalEmployer: !!cliOfficeInfo?.statusContactDetails?.regionalEmployer,
        majorIntegratedEmployer: !!cliOfficeInfo?.statusContactDetails?.majorIntegratedEmployer,
    };
}
function getOfficeCustomizedInfo(contactInfo, vanityContactField, selectedContactField) {
    if (contactInfo != null && contactInfo.statusContactDetails != null) {
        if (contactInfo.statusContactDetails[vanityContactField] !== "") {
            return contactInfo.statusContactDetails[vanityContactField];
        }
        return contactInfo.statusContactDetails[selectedContactField];
    }
    return "";
}
