import { Alert, Box, Button, Heading, Link, Paginator, Search, SearchResult } from '@digitools/honest-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Notifications, { FunctionalArea } from '../../../../components/Notifications/Notifications';
import PageLoader from '../../../../components/PageLoader/PageLoader';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../../../constants/tealiumConstants';
import useAuthentication from '../../../../hooks/useAuthentication';
import useTealium from '../../../../hooks/useTealium';
import { ExperienceContext } from '../../../../providers/ExperienceProvider';
import { EventAction, GoogleAnalyticsContext } from '../../../../providers/GoogleAnalyticsProvider';
import { getTraceId } from '../../../../utils/axiosUtils';
import { gaLabelFactory } from '../../../../utils/gaUtils';
import getDisplayText from '../../../../utils/languageDisplay';
import { searchCustomers as searchCustomersApi } from './../../../../api/ConfigApi';
import { fetchCustomers } from './../../../../api/CustomerApi';
import { MultiCustomerDisplayTextEn } from './i18n/MultiCustomerDisplayTextEn';
import { MultiCustomerDisplayTextEs } from './i18n/MultiCustomerDisplayTextEs';
import UnassociatedMessage from './UnassociatedMessage';
const GA_FEATURE_PREFIX = 'MULTI_CUSTOMER_LANDING';
const generateGALabel = gaLabelFactory(GA_FEATURE_PREFIX);
const StyledCustomerContainer = styled('div') `
  margin-top: 2rem;
`;
const StyledHeading = styled(Heading) `
  margin-top: 20px;
  margin-bottom: 0.25rem;
`;
const StyledSubHeading = styled(Heading) `
  font-weight: 900;
  margin-bottom: 1rem;
`;
const NoResults = styled('div') `
  color: rgb(126, 134, 153);
`;
const StyledPaginatorContainer = styled('div') `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledBox = styled(Box) `
  margin-top: 0;
  border-radius: 0;
  border: none;
  align-self: stretch;
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.palette.grayLightest};
  }
`;
const ResultButton = styled(Button) `
  margin-left: 0 !important;
  text-align: left;
  width: 100%;
`;
const getCustomerName = (customer, displayCustDivSerial) => {
    let divSerial = '';
    if (displayCustDivSerial && customer.custDiv && customer.custSeral) {
        divSerial = customer.custDiv + '-' + customer.custSeral + ' ';
    }
    if (!!customer.customerDisplayName) {
        return divSerial + customer.customerDisplayName;
    }
    else if (!!customer.customerInternalName) {
        return divSerial + customer.customerInternalName;
    }
    else {
        console.info('No Customer Name available for customer: ', customer.customerId);
        return '';
    }
};
const Result = ({ result, updateCustomerInContext, displayCustDivSerial, }) => {
    return (<ResultButton onClick={() => updateCustomerInContext(result.customerId)} type={'button'} buttonType={'text'} data-testid={`customer-${result.customerId}-link`}>
      {getCustomerName(result, displayCustDivSerial)}
    </ResultButton>);
};
const EmptyResults = () => (<SearchResult>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>No Results!</span>
    </div>
  </SearchResult>);
const CustomerSearchLandingPage = () => {
    const { user, authHeader, setCustomerInContext } = useAuthentication();
    const { language } = useContext(ExperienceContext);
    const { trackError } = useContext(GoogleAnalyticsContext);
    const [filteredCustomerList, setFilteredCustomerList] = useState([]);
    const [displayDivSerial, setDisplayDivSerial] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [traceId, setTraceId] = useState('');
    const submitButtonRef = useRef();
    const hideResultsRef = useRef();
    const { trackView, trackEvent } = useTealium();
    useEffect(() => {
        if (!loading) {
            // add passed source_system to deal with customer search landing page having no customerInContext initially
            trackView({
                page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.CUSTOMER_SEARCH,
                page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.CUSTOMER_SEARCH.CUSTOMER_SEARCH_LANDING_PAGE,
                source_system: '',
            });
        }
    }, [loading]);
    useEffect(() => {
        clearCustomerInContext().then(() => {
            if (user && user.accessAllCustomers) {
                if (user.userInternal) {
                    setDisplayDivSerial(true);
                }
                getAllCustomers();
            }
            else {
                getAuthorizedCustomers();
            }
        });
    }, []);
    const getLocalDisplayText = (key) => getDisplayText.apply({}, [key, MultiCustomerDisplayTextEn, MultiCustomerDisplayTextEs, language]);
    const getAllCustomers = async () => {
        try {
            const customers = await fetchCustomers(authHeader());
            setCustomerList(customers);
            setFilteredCustomerList(customers);
            setLoading(false);
        }
        catch (error) {
            setTraceId(getTraceId(error));
            trackError(EventAction.PAGE_LOAD, generateGALabel('load-customer-accounts'));
            setError(true);
            setLoading(false);
        }
    };
    const getAuthorizedCustomers = () => {
        if (user && user.customers) {
            setCustomerList(user.customers);
            setFilteredCustomerList(user.customers);
            setLoading(false);
        }
        else if (user && !user.customers) {
            setCustomerList([]);
            setFilteredCustomerList([]);
            setLoading(false);
        }
    };
    const handleQueryChange = async (query) => {
        try {
            setError(false);
            if (!query || query.length === undefined || query.length < 3) {
                setFilteredCustomerList(customerList);
            }
            else {
                const result = await searchCustomersApi(query, authHeader());
                setFilteredCustomerList(result);
                return result;
            }
        }
        catch (e) {
            setError(true);
            setFilteredCustomerList([]);
        }
        return [];
    };
    const updateCustomerInContext = async (customerId) => {
        if (setCustomerInContext) {
            try {
                setLoading(true);
                if (user && user.roles && user.roles.length === 1 && user.roles.includes('employee')) {
                    await setCustomerInContext(customerId, '/employee/home');
                }
                else {
                    await setCustomerInContext(customerId, '/employer/home');
                }
            }
            catch (e) {
                console.error('issue setting customer');
            }
        }
        trackEvent({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.CUSTOMER_SEARCH,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.CUSTOMER_SEARCH.CUSTOMER_SEARCH_LANDING_PAGE,
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELECT,
            event_name: 'select a customer',
            event_type: TEALIUM_EVENT.EVENT_TYPE.SELECT_CUSTOMER
        });
    };
    const clearCustomerInContext = async () => {
        if (setCustomerInContext) {
            try {
                await setCustomerInContext('');
            }
            catch (e) {
                console.error('Issue clearing customer in context');
            }
        }
    };
    const PagedCustomers = ({ pagedData }) => {
        return (<>
        {pagedData.map((item, idx) => {
                return (<StyledBox data-public={true} data-testid={`customer-${idx}`} key={idx} onClick={() => updateCustomerInContext(item.customerId)}>
              <ResultButton className={'p-0'} data-testid={`customer-${idx}-link`}>
                {getCustomerName(item, displayDivSerial)}
              </ResultButton>
            </StyledBox>);
            })}
      </>);
    };
    if (user && user.roles && user.roles.indexOf('client_service_reps') > -1) {
        const url = window.location.hostname.indexOf('np-') > -1
            ? 'https://admin.np-mylincolnportal.com/'
            : 'https://admin.mylincolnportal.com/';
        return (<Alert data-public={true} type={'error'}>
        <strong>Account no longer valid.</strong> Please note that as of 9/1/19, the login process has changed for LFG
        employees. Please go to <Link href={url}>{url}</Link> and login with your LFG email address and password.
      </Alert>);
    }
    return (<>
      <StyledHeading data-public={true} elemType={'h1'}>
        {getLocalDisplayText('pageHeading')}
      </StyledHeading>
      <p>{getLocalDisplayText('subHeading')}</p>
      {error ? (<div data-testid={'error'}>
          <Alert data-public={true} type={'error'}>
            {getLocalDisplayText('errorMessage')}
            {traceId && ` (Error: ${traceId})`}
          </Alert>
        </div>) : (<>
          <Notifications functionalArea={FunctionalArea.MULTI_CUSTOMER_LANDING}/>
          {loading ? (<PageLoader id='customer-search-landing-page-spinner'/>) : (<>
              {customerList.length === 0 ? (<UnassociatedMessage />) : (<>
                  <Box size={'large'}>
                    <div className='d-flex' style={{ alignItems: 'center' }}>
                      <div style={{ width: '90%' }}>
                        <Search disableResultsDropdown={true} label={'Search'} field={'search'} debounce={500} onQueryChange={handleQueryChange} resultComponent={({ result }) => (<Result result={result} updateCustomerInContext={updateCustomerInContext} displayCustDivSerial={displayDivSerial}/>)} emptyResultComponent={EmptyResults} data-test-id={'search'} placeholder={'Search by customer name, at least 3 characters'} focusAfterEnter={submitButtonRef} hideResultsRef={hideResultsRef} showResultsOnEmptyQuery={true}/>
                      </div>
                      <Button onClick={() => setFilteredCustomerList(customerList)}>Reset</Button>
                    </div>
                  </Box>
                  <StyledCustomerContainer data-public={true} id={'customer-list'} role={'customerList'} data-testid={'customer-list'}>
                    <StyledSubHeading tabIndex={0} elemType={'h2'} elemStyle={'h6'}>
                      {getLocalDisplayText('customerHeading')}
                    </StyledSubHeading>
                    {filteredCustomerList.length === 0 ? (<NoResults>{getLocalDisplayText('noResults')}</NoResults>) : (<StyledPaginatorContainer>
                        <Paginator data={filteredCustomerList} renderContent={PagedCustomers} managed={false} rowsPerPage={rowsPerPage} currentPage={currentPage} setRowsPerPage={(selected) => setRowsPerPage(selected)} setCurrentPage={setCurrentPage}/>
                      </StyledPaginatorContainer>)}
                    <p>{getLocalDisplayText('helpMessage')}</p>
                  </StyledCustomerContainer>
                </>)}
            </>)}
        </>)}
    </>);
};
export default CustomerSearchLandingPage;
