import React from 'react';
import { Row, Col, TextInput, NewDateInput, themes, Box, Icon } from '@digitools/honest-react';
import useExperience from '../../../hooks/useExperience';
import useTranslate from '../../../hooks/useTranslate';
import { Language } from '../../../types/Language';
import useAsDataContext from '../hooks/useAsDataContext';
import { validateName, validateDateIsOnOrBeforeToday } from '../utils/validationUtils';
import { middleInitialMask } from '../../eoi/utils/InputMasks';
import { Scope } from 'informed';
import useAuthentication from 'src/hooks/useAuthentication';
import { Experience } from 'src/types/Experience';
import { StyledDiv, SectionHeading, DivBlock, StyledRow } from './LayoutComponents';
import styles from '../pages/aboutYou/aboutYou.scss';
import { PersonalInformationText } from '../constants/PersonalInformationText';
import styled from 'styled-components';
import { AsText } from '../constants/Text';
const StyledIcon = styled(Icon) `
  font-size: 3rem;
  margin-left: 0.5rem; 
  margin-right: 0.5rem;
  margin-top: -0.25rem;
  @media only screen and (max-width: 500px) {
    font-size: 2.5rem;
}
`;
/**
 * Section of fields for personal information, for use within a <Form>
 */
const PersonalInformation = () => {
    const { user } = useAuthentication();
    const { t } = useTranslate();
    const { language, experience } = useExperience();
    const { claimantData } = useAsDataContext();
    return (<StyledDiv data-public={true} data-testid='personal-information'>
      <Box className={'mt-4'}>
        <StyledRow>
          <DivBlock className={'d-flex align-items-center'}>
            <StyledIcon name={'personal-info'} size='large' color={'primary'}/>
            <SectionHeading elemType={'h3'} color={'black'} theme={themes.lincoln}>
            {t(PersonalInformationText.personalInfoHeading)}
            </SectionHeading>
          </DivBlock>
        </StyledRow>
        {/* @ts-ignore */}
      <Scope scope='claimantData'>
        <Row>
          <Col md={6} xs={2} lg={language === Language.ENGLISH ? 3 : 6} className={styles.leftWidthStyling} data-private={true}>
            <TextInput data-testid='firstName' label={t(PersonalInformationText.firstNameQuestionLabel)} ariaLabelText={t(PersonalInformationText.firstNameQuestionLabel)} field={'empFirstName'} validateOnBlur={true} validateOnChange={true} validate={validateName} 
    //@ts-ignore FIXME: type error
    initialValue={claimantData?.empFirstName || experience === Experience.EMPLOYEE && user?.firstName}/>
          </Col>
          <Col md={6} xs={2} lg={language === Language.ENGLISH ? 3 : 6} className={styles.rightWidthStyling}>
            <TextInput data-testid='middleInitial' label={t(PersonalInformationText.middleInitialQuestionLabel) + t(AsText.optionalText)} ariaLabelText={t(PersonalInformationText.middleInitialQuestionLabel) + t(AsText.optionalText)} field={'empMiName'} mask={middleInitialMask} initialValue={claimantData && claimantData?.empMiName}/>
          </Col>
          <Col md={6} xs={6} lg={6} data-private={true}>
            <TextInput disabled={experience === Experience.EMPLOYEE} data-testid='lastName' label={t(PersonalInformationText.lastNameQuestionLabel)} ariaLabelText={t(PersonalInformationText.lastNameQuestionLabel)} field={'empLastName'} validateOnBlur={true} validateOnChange={true} validate={validateName} 
    //@ts-ignore FIXME: type error
    initialValue={claimantData?.empLastName || experience === Experience.EMPLOYEE && user?.lastName}/>
          </Col>
          <Col md={6} xs={6} lg={6} xl={language === Language.ENGLISH ? 3 : 6} data-private={true}>
            <NewDateInput data-testid='dateOfBirth' id='empBthDte' theme={themes.lincoln} field={'empBthDte'} label={t(PersonalInformationText.dateOfBirthQuestionLabel) + t(AsText.optionalText)} aria-label={t(PersonalInformationText.dateOfBirthQuestionLabel) + t(AsText.optionalText)} minDate={undefined} maxDate={new Date()} validate={(props) => validateDateIsOnOrBeforeToday(props, t, experience === Experience.EMPLOYEE)} initialValue={claimantData && claimantData?.empBthDte}/>
          </Col>
        </Row>
      </Scope>
      </Box>
    </StyledDiv>);
};
export default PersonalInformation;
