import { Alert, Col, Form, Heading, Loader, Row, StepperContext } from '@digitools/honest-react';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthentication from 'src/hooks/useAuthentication';
import useTranslate from 'src/hooks/useTranslate';
import { SubmitAbsenceData } from '../../api/RiaApi';
import AbsenceDuration from '../../components/AbsenceDuration';
import { BackButton, ContinueButton, StepsDiv, StickyDiv } from '../../components/LayoutComponents';
import LeaveCard from '../../components/LeaveCard';
import useRiaData from '../../hooks/useRiaData';
import { RIA_TEXT } from '../../constants/RiaConstants';
import useExperience from 'src/hooks/useExperience';
import useRiaConfig from '../../hooks/useRiaConfig';
import { format } from 'date-fns';
import PreviousAbsences, { submitPreviousAbsences, updatedAbsences } from './PreviousAbsences';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { absenceDurationFormat } from '../../utils/AbsenceDurationUtils';
/**
 *  Page component for the Report Absence part of ria
 * @returns
 */
const ReportAbsence = () => {
    const { authHeader } = useAuthentication();
    const { t } = useTranslate();
    const navigate = useNavigate();
    const [formApi, setFormApi] = useState();
    const [isSubmitLoading, setIsSubmitLoading] = useState();
    const stepper = useContext(StepperContext);
    const [allFailed, setAllFailed] = useState(false);
    const [emptySubmit, setEmptySubmit] = useState(false);
    const { leaves, currentLeave, setNewAbsenceDateTimes, setUpdatedAbsenceDateTimes, setCurrentLeave } = useRiaData();
    const { riaConfig, riaConfigLoading } = useRiaConfig();
    const { language } = useExperience();
    const { trackEvent } = useTealium();
    const handleBack = () => {
        if (leaves.length === 1) {
            navigate('/employee/home');
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_name: 'cancel',
                event_type: TEALIUM_EVENT.EVENT_TYPE.REPORT_INTERMITTENT_ABSENCE,
                event_version: 'add or edit existing absences',
            });
            navigate('/employee/home');
        }
        else {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_name: 'back',
                event_type: TEALIUM_EVENT.EVENT_TYPE.REPORT_INTERMITTENT_ABSENCE,
                event_version: 'add or edit existing absences',
            });
            stepper.back();
            // TODO: Do we want to look into clearing previous leave
            // values if the user starts to fill out one field,
            // goes back, selects another leave, then hits continue?
            if (formApi) {
                setCurrentLeave(undefined);
                formApi.reset();
            }
        }
    };
    const handleSubmitAbsence = async (values) => {
        setEmptySubmit(false);
        setAllFailed(false);
        const newAbsences = values.absenceDurations;
        setIsSubmitLoading(true);
        let failureCount = 0;
        //TODO: we should put a tech task for post-release to push a lot of these values/calculations into the BE
        const baseSubmitObj = {
            lang: language,
            dateEnteredTimeStamp: new Date().toString(),
            emailActive: riaConfig?.reportingPolicyItems[0]?.emailActive,
            emailAddress: riaConfig?.reportingPolicyItems[0]?.emailAddresses,
            locCde: currentLeave?.locCde,
            ssdCde: currentLeave?.ssdCde,
        };
        const numSuccessfulEdits = await submitPreviousAbsences(values, currentLeave, riaConfig, language, authHeader, setUpdatedAbsenceDateTimes);
        if (newAbsences && newAbsences[0].absenceDate?.value && newAbsences[0].absenceDuration?.value) {
            setEmptySubmit(false);
        }
        else {
            setEmptySubmit(true);
        }
        const tempArray = [];
        //@ts-ignore
        if (newAbsences && newAbsences.length > 0) {
            //@ts-ignore
            const promises = newAbsences.map(async (absence) => {
                if (absence?.absenceDate?.length > 0 && absence?.absenceDuration) {
                    const absenceDuration = absence.absenceDuration;
                    let date = absence.absenceDate !== undefined ? new Date(absence.absenceDate) : new Date();
                    const submitObj = {
                        ...baseSubmitObj,
                        absenceResource: {
                            absenceDate: format(date, 'yyyy-MM-dd'),
                            absenceHours: absenceDuration.split(':')[0],
                            absenceMinutes: absenceDuration.split(':')[1],
                            leaveNumber: currentLeave?.lveNum,
                        },
                    };
                    try {
                        setEmptySubmit(false);
                        // TODO: multi-submit story - make use of numSuccessfulEdits to know whether or not we're in ALL FAIL case
                        await SubmitAbsenceData(submitObj, authHeader()).then(response => {
                            if (response.status == 200) {
                                tempArray.push({
                                    absenceDate: absence.absenceDate,
                                    absenceHours: absenceDuration.split(':')[0],
                                    absenceMinutes: absenceDuration.split(':')[1],
                                    success: true,
                                });
                            }
                            else {
                                tempArray.push({
                                    absenceDate: absence.absenceDate,
                                    absenceHours: absenceDuration.split(':')[0],
                                    absenceMinutes: absenceDuration.split(':')[1],
                                    success: false,
                                });
                                failureCount++;
                            }
                        });
                    }
                    catch (error) {
                        tempArray.push({
                            absenceDate: absence.absenceDate,
                            absenceHours: absenceDuration.split(':')[0],
                            absenceMinutes: absenceDuration.split(':')[1],
                            success: false,
                        });
                        failureCount++;
                    }
                }
            });
            await Promise.all(promises);
        }
        //@ts-ignore
        const numEditsSubmitted = values?.previousAbsences ? values?.previousAbsences?.length : 0;
        const totalFailureCount = failureCount + (numEditsSubmitted - numSuccessfulEdits);
        setNewAbsenceDateTimes(tempArray);
        if (totalFailureCount > 0 && totalFailureCount === numEditsSubmitted + tempArray.length) {
            setAllFailed(true);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_name: "We're sorry, we were unable to submit your request at this time. Please wait a moment and try again try. If issues persist please contact us",
                event_type: TEALIUM_EVENT.EVENT_TYPE.REPORT_INTERMITTENT_ABSENCE,
                event_version: 'add or edit existing absences',
            });
        }
        else if (tempArray.length > 0 || numEditsSubmitted > 0 || updatedAbsences.length > 0) {
            let eventResults = '';
            let updatedEventResults = '';
            tempArray.map(a => {
                eventResults += `${format(new Date(a.absenceDate), 'P')} ${absenceDurationFormat(a.absenceHours, a.absenceMinutes)}, `;
            });
            updatedAbsences.map(a => {
                updatedEventResults += `${format(new Date(a.absenceDate), 'yyyy-MM-dd')} ${absenceDurationFormat(a.absenceHours, a.absenceMinutes)}, `;
            });
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                event_name: 'submit',
                event_type: TEALIUM_EVENT.EVENT_TYPE.REPORT_INTERMITTENT_ABSENCE,
                event_version: 'add or edit existing absences',
                event_status: failureCount > 0 ? 'complete-partial' : 'complete-full',
                transaction_id: currentLeave?.lveNum,
                form_result: `gp_ria,${eventResults}` + `${updatedEventResults}`,
            });
            navigate('/ria/confirmation');
            window.scrollTo(0, 0);
        }
        setIsSubmitLoading(false);
    };
    return (<>
      {allFailed && (<Alert data-testid={'submission-full-error-alert'} type={'error'} aria-labelledby='submission-full-error-alert'>
          {t(RIA_TEXT.confirmationErrorFull)}
        </Alert>)}
      {emptySubmit && (<Alert data-testid={'submission-empty-error-alert'} type={'warning'} aria-labelledby='submission-empty-error-alert' closeable={true}>
          {t(RIA_TEXT.riaEmptyAlert)}
        </Alert>)}
      <Heading elemType='h2' color='grayDarkest'>
        {t(RIA_TEXT.reportAbsencesHeading)}
      </Heading>
      <br />
      <Row>
        <Col lg={9} md={12}>
          {currentLeave && <LeaveCard leave={currentLeave}/>}
        </Col>
      </Row>
      {/* spinner - TODO: this should be a loader with the isSUbmitLoading as the loading state  */}
      <br />
      <Loader isShown={isSubmitLoading || riaConfigLoading}>
        <Form data-testid='formid' onSubmit={handleSubmitAbsence} getApi={setFormApi}>
          <Row style={{ display: 'flex' }}>
            <Col lg={9} sm={12}>
              <AbsenceDuration />
            </Col>
          </Row>
          <Row>
            <Col lg={9} sm={12}>
              <PreviousAbsences />
            </Col>
          </Row>

          <StickyDiv>
            <StepsDiv>
              {/* TODO: conditional back button for 1 leave user */}
              <BackButton type={'button'} buttonType='secondary' onClick={handleBack}>
                {leaves.length === 1 ? t(RIA_TEXT.cancelButton) : t(RIA_TEXT.backButton)}
              </BackButton>
              <ContinueButton id='riaSubmitButton' buttonType='primary'>
                {t(RIA_TEXT.submitButton)}
              </ContinueButton>
            </StepsDiv>
          </StickyDiv>
        </Form>
      </Loader>
    </>);
};
export default ReportAbsence;
