import { Alert, Heading, Icon, Spinner } from '@digitools/honest-react';
import queryString from 'query-string';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'src/utils/withRouter';
import EmployeeDetails from 'status/components/EmployeeDetails/EmployeeDetails';
import { useAuth0, useProductAttributeCodes, useStatusDefinitionList } from 'status/hooks';
import { decode, encode } from 'status/utilities/hash';
import titleCase from 'status/utilities/titleCase';
import { TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';
import useAuthentication from '../../../../../hooks/useAuthentication';
import useGet from '../../../../../hooks/useGet';
import useTealium from '../../../../../hooks/useTealium';
import CommunicationPreferences from '../../CommunicationPreferences/CommunicationPreferences';
import LeaveInfo from './LeaveInfo';
import LeaveTabs from '../Tabs/LeaveTabs';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';

const EmployeeError = ({ error }) => (
  <Alert type={'error'}>
    {error.response && error.response.status === 404 ? (
      'No employee found.'
    ) : (
      <span>
        <strong>Error!</strong>&nbsp;Something went wrong when trying to load the employee.
      </span>
    )}
  </Alert>
);

const ReturnTo = ({ empNum, experience, params }) => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const fromTeamView = parsed.from === 'teamView';

  const expPrefix = experience ===  Experience.EMPLOYEE ? 'employee' : 'employer';

  const { t } = useTranslation();

  let to = `/status/${expPrefix}/${encode(empNum)}/coverageDetail`;
  to = fromTeamView ? `${to}?from=teamView` : to;
  let text = t('returnCoverageDetailsText');

  if (params.from === 'search') {
    to = '/status/employer/search';
    text = t('returnToSearch');
  }

  if (params.search) {
    to = `${to}?search=${params.search}&searchCrit=${params.searchCrit}`;
  }

  return (
    <div className='d-flex align-items-center'>
      <Icon name='long-arrow-left-regular' size='small' color='secondaryDark' className='mr-2' />
      <Link to={to}>{text}</Link>
    </div>
  );
};

const LeaveDetails = () => {
  const { t } = useTranslation();
  const { userMeta } = useAuth0();
  const { experience } = useExperience();
  const { user } = useAuthentication();
  const location = useLocation();
  const { leaveId, empNum } = useParams();
  const decodedLeaveId = decode(leaveId);
  const decodedEmpNum = decode(empNum);
  const params = queryString.parse(location.search);
  const { trackView } = useTealium();
  const messagesEndRef = useRef(null);

  const [leaveStatus, setLeaveStatus] = useState(undefined);
  const [subsAndLocs, setSubsAndLocs] = useState(undefined);

  const {
    error: leaveError,
    loading: loadingLeave,
    data: leaveData,
  } = useGet({
    url: '/status-service/leaves/search/searchByLeaveId',
    options: {
      params: {
        leaveId: decodedLeaveId,
      },
    },
  });

  const {
    error: leaveDetailsError,
    loading: loadingLeaveDetails,
    data: leaveDetailsData,
  } = useGet({
    url: '/status-service/storproc/search/leaveDetails',
    options: {
      params: {
        leaveId: decodedLeaveId,
      },
    },
  });

  const {
    error: employeeError,
    loading: loadingEmployee,
    data: employeeData,
  } = useGet({
    url: '/status-service/employee/search/searchByEmpNum',
    options: {
      params: {
        empNum: decodedEmpNum,
      },
    },
  });

  const {
    error: leaveReportError,
    loading: loadingLeaveReport,
    data: leaveReportData,
  } = useGet({
    url: '/status-service/storproc/search/leaveAppliedReport',
    options: {
      params: {
        leaveId: decodedLeaveId,
      },
    },
  });

  const { data: notificationData } = useGet({
    url: `/mlp-ui-service/services/v1/notificationsEnable?custDiv=${user && user.customerDiv}&custSeral=${user && user.customerSerial
      }`,
  });

  const { data: communicationPreferencesData } = useGet({
    url: '/mlp-ui-service/services/v1/communicationPreferences',
  });

  const { error: statusDefError, loading: statusDefLoading, statusDefinitionList } = useStatusDefinitionList();

  const { error: prodAttError, loading: prodAttLoading, productAttributeCodes } = useProductAttributeCodes();

  const loading =
    loadingLeaveReport || loadingLeave || loadingLeaveDetails || loadingEmployee || prodAttLoading || statusDefLoading;

  useEffect(() => {
    window.scrollTo(0,0)
    if(!loading) {
      trackView({
        page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.STATUS,
        page_l5: TEALIUM_PAGE_VIEW.PAGE_L5.STATUS.COVERAGE_DETAILS,
        page_l6: TEALIUM_PAGE_VIEW.PAGE_L6.STATUS.LEAVE_DETAILS
      });
    }
  }, [loading]);

  // Spinner if loading content
  if (loading) {
    return <Spinner id='leave-details-spinner' center={true} />;
  }

  // Error whole page if no employee
  if (employeeError) {
    return <EmployeeError error={employeeError} />;
  }

  const updateStatusCallback = status => {
    if (!leaveStatus) {
      setLeaveStatus(status);
    }
  };

  const updateSubsAndLocsCallback = value => {
    if (!subsAndLocs) {
      setSubsAndLocs(value);
    }
  };

  const name = `${titleCase(employeeData.empFirstName)} ${titleCase(employeeData.empLastName)}`;

  const basicInfo = { ...leaveStatus, ...subsAndLocs, employeeFullName: name };

  return (
    <div data-public={true} style={{ position: 'relative', }}>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <ReturnTo empNum={decodedEmpNum} experience={experience} params={params} />
        {experience &&
          experience === Experience.EMPLOYEE &&
          notificationData &&
          notificationData.notificationsActiveIndicator &&
          notificationData.notificationsActiveIndicator === 'true' &&
          communicationPreferencesData &&
          !communicationPreferencesData.notification && (
            <CommunicationPreferences confirmation={communicationPreferencesData.confirmation} />
          )}
      </div>
      <br />
      <Heading data-testid='leave-details-header'>{t('leaveDetails')}</Heading>
      <EmployeeDetails empNum={decodedEmpNum} noBenefitClass={true} updateSubsAndLocsCallback={updateSubsAndLocsCallback} empEmailAddress={employeeData?.empEmailAddr} />
      <br />
      <div ref={messagesEndRef}></div>
      <div>
        <LeaveInfo
          leave={leaveData}
          params={params}
          name={name}
          leaveDetails={leaveDetailsData && leaveDetailsData.content[0]}
          empNum={decodedEmpNum}
          error={leaveError || prodAttError || statusDefError || leaveDetailsError || leaveReportError}
          statusDefinitionList={statusDefinitionList}
          prodAttCodes={productAttributeCodes}
          leaveId={decodedLeaveId}
          leaveReports={leaveReportData?.content}
          updateStatusCallback={updateStatusCallback}
        />
      </div>
      <div ref={messagesEndRef}></div>
      <div className='mb-4'>
        <LeaveTabs
          leaveId={decodedLeaveId}
          basicInfo={basicInfo}
          leaveData={leaveData}
          employeeData={employeeData}
          error={leaveError || leaveDetailsError}
          leaveDetailsData={leaveDetailsData}
          userMeta={userMeta}
          experience={experience}
        />
      </div>
    </div>
  );
};

export default withRouter(LeaveDetails);
