import { Alert, Heading, Icon, Spinner } from '@digitools/honest-react';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import EmployeeDetails from 'status/components/EmployeeDetails/EmployeeDetails';
import { useAuth0, useProductAttributeCodes, useStatusDefinitionList } from 'status/hooks';
import { decode, encode } from 'status/utilities/hash';
import titleCase from 'status/utilities/titleCase';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';
import useAuthentication from '../../../../../hooks/useAuthentication';
import useGet from '../../../../../hooks/useGet';
import useTealium from '../../../../../hooks/useTealium';
import CommunicationPreferences from '../../CommunicationPreferences/CommunicationPreferences';
import ClaimInfo from './ClaimInfo';
import { RtwContext } from '../../../context';
import ClaimTabs from '../Tabs/ClaimTabs';
import { withRouter } from '../../../../../utils/withRouter';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';

const EmployeeError = ({ error }) => (
  <Alert type={'error'}>
    {error.response && error.response.status === 404 ? (
      'No employee found.'
    ) : (
      <span>
        <strong>Error!</strong>&nbsp;Something went wrong when trying to load the employee.
      </span>
    )}
  </Alert>
);
const ReturnTo = ({ empNum, experience, params }) => {
  const parsed = queryString.parse(location.search);
  const fromTeamView = parsed.from === 'teamView';
  const expPrefix = experience === Experience.EMPLOYEE ? 'employee' : 'employer';
  const { t } = useTranslation();
  const { trackEvent } = useTealium();
  let to = `/status/${expPrefix}/${encode(empNum)}/coverageDetail`;
  to = fromTeamView ? `${to}?from=teamView` : to;
  let text = t('returnCoverageDetailsText');
  if (params.from === 'search') {
    to = '/status/employer/search';
    text = t('returnToSearch');
  }
  if (params.search) {
    to = `${to}?search=${params.search}&searchCrit=${params.searchCrit}`;
  }
  const fireBackEvent = () => {
    trackEvent({
      event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
      event_name: t('returnCoverageDetailsText'),
      event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
      event_version: 'claim details',
    });
  };

  return (
    <div data-public={true} className='d-flex align-items-center'>
      <Link to={to} onClick={() => fireBackEvent()}>
        <Icon name='long-arrow-left-regular' size='small' color={'secondaryDark'} className='mr-2' />
        {text}
      </Link>
    </div>
  );
};

const ClaimDetails = () => {
  const { userMeta } = useAuth0();
  const { experience } = useExperience();
  const { user } = useAuthentication();
  const { t } = useTranslation();
  const location = useLocation();
  const { claimNum, empNum } = useParams();
  const decodedClaimNum = decode(claimNum || '');
  const decodedEmpNum = decode(empNum || '');
  const params = queryString.parse(location.search);
  const [claimStatus, setClaimStatus] = useState(undefined);
  const [subsAndLocs, setSubsAndLocs] = useState(undefined);
  const { trackView } = useTealium();
  const messagesEndRef = useRef(null);

  useEffect(() => {
    trackView({
      page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.STATUS,
      page_l5: TEALIUM_PAGE_VIEW.PAGE_L5.STATUS.COVERAGE_DETAILS,
      page_l6: TEALIUM_PAGE_VIEW.PAGE_L6.STATUS.CLAIM_DETAILS,
    });
    scrollTo(0,0);
  }, []);
  const {
    error: claimError,
    loading: loadingClaim,
    data: claimData,
  } = useGet({
    url: '/status-service/claims/search/searchByClaimNum',
    options: {
      params: {
        claimNum: decodedClaimNum,
      },
    },
  });
  // TODO: Unfortunately as it stands, in order to get
  // associated leave we must get all employee leaves and filter
  // this should be changed eventually!!!
  const {
    error: leaveError,
    loading: loadingLeaves,
    data: leaveData,
  } = useGet({
    url: '/status-service/leaves/search/searchByEmpNum',
    options: {
      params: {
        empNum: decodedEmpNum,
      },
    },
    noInitial: user.customerInContext.smallMid,
  });
  const {
    error: employeeError,
    loading: loadingEmployee,
    data: employeeData,
  } = useGet({
    url: '/status-service/employee/search/searchByEmpNum',
    options: {
      params: {
        empNum: decodedEmpNum,
      },
    },
  });

  const empName =
    employeeData?.empFirstName && employeeData?.empLastName
      ? `${titleCase(employeeData.empFirstName)} ${titleCase(employeeData.empLastName)}`
      : null;

  const { data: notificationData } = useGet({
    url: `/mlp-ui-service/services/v1/notificationsEnable?custDiv=${user && user.customerDiv}&custSeral=${
      user && user.customerSerial
    }`,
  });

  const { data: communicationPreferencesData } = useGet({
    url: '/mlp-ui-service/services/v1/communicationPreferences',
  });

  const { error: statusDefError, loading: statusDefLoading, statusDefinitionList } = useStatusDefinitionList();
  const { error: prodAttError, loading: prodAttLoading, productAttributeCodes } = useProductAttributeCodes();
  const loading = loadingClaim || loadingLeaves || loadingEmployee || prodAttLoading || statusDefLoading;
  // Spinner if loading content
  if (loading) {
    return <Spinner id='claim-details-spinner' center={true} />;
  }
  // Error whole page if no employee
  if (employeeError) {
    return <EmployeeError error={employeeError} />;
  }

  const updateStatusCallback = status => {
    if (!claimStatus) {
      setClaimStatus(status);
    }
  };
  const updateSubsAndLocsCallback = value => {
    if (!subsAndLocs) {
      setSubsAndLocs(value);
    }
  };
  const basicInfo = { ...claimStatus, ...subsAndLocs };

  return (
    <div style={{ position: 'relative', overflowX: 'hidden' }}>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <ReturnTo empNum={decodedEmpNum} experience={experience} params={params} />
        {experience &&
          experience === Experience.EMPLOYEE &&
          notificationData &&
          notificationData.notificationsActiveIndicator &&
          notificationData.notificationsActiveIndicator === 'true' &&
          communicationPreferencesData &&
          !communicationPreferencesData.notification && (
            <CommunicationPreferences confirmation={communicationPreferencesData.confirmation} />
          )}
      </div>
      <br />
      <Heading data-testid='claim-details-header'>{t('claimDetails')}</Heading>
      <EmployeeDetails empNum={decodedEmpNum} updateSubsAndLocsCallback={updateSubsAndLocsCallback} empEmailAddress={employeeData?.empEmailAddr} />
      <br />
      <div ref={messagesEndRef}></div>
      <ClaimInfo
        claim={claimData}
        employeeName={empName}
        params={params}
        empNum={decodedEmpNum}
        leaveData={leaveData}
        leaveError={leaveError}
        error={claimError}
        statusDefinitionList={statusDefinitionList}
        prodAttCodes={productAttributeCodes}
        claimId={decodedClaimNum}
        updateStatusCallback={updateStatusCallback}
        isSmallMidCustomer={user.customerInContext.smallMid}
      />
      <div ref={messagesEndRef}></div>
      <div className='mb-4' data-public={true}>
        <ClaimTabs
          empNum={decodedEmpNum}
          basicInfo={basicInfo}
          claim={claimData}
          userMeta={userMeta}
          experience={experience}
          claimNum={claimNum}
          employeeData={employeeData}
        />
      </div>
    </div>
  );
};
export default withRouter(ClaimDetails);
