import { Box, Col, Heading, Row } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ImportantMessageBox from '../../components/ImportantMessageBox';
import useExperience from '../../../../hooks/useExperience';
import { Experience } from '../../../../types/Experience';
import { getEnvVariable } from '../../../../utils/envUtils';
import NextSteps from './NextSteps';
import useCliCustomerConfigContext from '../../hooks/useCliContext';
import { CliConfirmationText } from '../../i18n/CliConfirmationText';
import useTranslate from '../../../../hooks/useTranslate';
import VideoBox from '../../components/VideoBox';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../../../constants/tealiumConstants';
import useTealium from '../../../../hooks/useTealium';
import useCliDataContext from '../../hooks/useCliDataContext';
import { TaggedCustomerName } from '../../components/UtilityComponents';
import useAuthentication from '../../../../hooks/useAuthentication';
import DownloadAndPrint from './DownloadPdf';
import useCustomerConfig from 'src/hooks/useCustomerConfig';
const BorderedTextDiv = styled('div') `
    border-left: 3px solid ${({ theme }) => theme.palette.primary};
    padding-left: .5rem;
    margin-bottom: 1rem;
`;
const GrayBottomDiv = styled('div') `
    color: ${({ theme }) => theme.palette.grayDark};
    padding-bottom: .5rem;
    border-bottom: 6px solid ${({ theme }) => theme.palette.grayLightest};
`;
const PrettyBox = styled(Box) `
    border-width: 1px;
    border-color: ${({ theme }) => theme.palette.grayLighter};
    box-sizing: border-box;
    transition: none;
`;
const ConfirmationNew = () => {
    const navigate = useNavigate();
    const { experience, experienceCode, language } = useExperience();
    const { trackEvent, trackView } = useTealium();
    const { claimantData, absenceData, contactData, additionalDetails, validationResults, documents, uploadedFiles, resetCliDataContext } = useCliDataContext();
    const { mlpConfigs } = useCustomerConfig();
    const [cliContextClone, setCliContextClone] = React.useState();
    const { empIdText, leaveCategoryDays, leaveTypes, leaveReasons, claimantCondition, relationships, deliveryIndicators, isClaimCustomer, isClaimLeaveCustomer, isLeaveCustomer, hasAdditionalInfo } = useCliCustomerConfigContext();
    const { t } = useTranslate();
    const { user } = useAuthentication();
    const { overviewTitleConfirmation, overviewBodyConfirmation } = useCliCustomerConfigContext();
    // merge docsnet/uploadFiles together for confirmation pdf
    // TODO: uploadedFiles shouldn't need to be separately saved in context -  can alter docsnet doc upload to save to same documents array and avoid this merge
    const mergedDocs = uploadedFiles.length > 0
        ? uploadedFiles.map(({ documentName, docId }) => {
            return { name: documentName, uuid: docId };
        })
        : documents;
    const originalContext = {
        claimantData: claimantData ? claimantData : {},
        absenceData: absenceData ? absenceData : {},
        contactData: contactData ? contactData : {},
        documents: mergedDocs,
        language,
        experience: experienceCode,
        claimActive: !!isClaimCustomer,
        leaveActive: !!isLeaveCustomer,
        ...(experienceCode === 'ER' && { additionalDetails }),
        cliCustomerConfiguration: mlpConfigs,
        configData: {
            empIdText: empIdText,
            leaveCategoryDays,
            leaveTypes,
            leaveReasons,
            claimantCondition,
            relationships,
            deliveryIndicators,
            isLeaveCustomer: !!isLeaveCustomer,
            isClaimCustomer: !!isClaimCustomer,
            isClaimLeaveCustomer: !!isClaimLeaveCustomer,
            hasAdditionalInfo: !!hasAdditionalInfo,
        },
        validationResults,
    };
    useEffect(() => {
        if (validationResults === undefined) {
            if (experience === Experience.EMPLOYEE) {
                navigate('/cli/employee');
            }
            else if (experience === Experience.EMPLOYER) {
                navigate('/cli/employer');
            }
        }
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.CLI,
            page_l4: experience === Experience.EMPLOYEE ? TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_EE : TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_ER,
            page_l5: 'confirmation',
            transaction_id: validationResults?.claimOrLeaveNumber,
            event_results: validationResults?.submissionType,
        });
    }, []);
    /* ************ END LOADING LOGIC ************/
    // TODO: (probably should put into useEffect once this is fully hooked up to prevent more re-reads)
    const submissionType = validationResults?.submissionType;
    let sType = 'claim';
    if (user?.customerInContext?.smallMid) {
        sType = 'claimdocs';
    }
    if (submissionType === 'Claim' && !user?.customerInContext?.smallMid) {
        sType = 'claim';
    }
    if (submissionType === 'Claim' && user?.customerInContext?.smallMid) {
        sType = 'claimdocs';
    }
    if (submissionType === 'Leave') {
        sType = 'leave';
    }
    if (submissionType === 'Both') {
        sType = 'claimleave';
    }
    const submitClaimOrLeaveNumber = validationResults?.claimOrLeaveNumber;
    const submissionDate = validationResults?.dateOfSubmit;
    const submissionTime = validationResults?.timeOfSubmit;
    /* ************ END LOADING LOGIC ************/
    const viewSubmission = () => {
        let url;
        if (experience === Experience.EMPLOYEE) {
            url = getEnvVariable('cliUrlEE');
            // url = 'http://localhost:8091/cli/employee';
        }
        else {
            url = getEnvVariable('cliUrl');
            // url = 'http://localhost:8091/cli/employer';
        }
        url += '/viewSubmission';
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
            event_name: `${submissionType} view submission`,
        });
        window.open(url, "_blank"); // open page in new tab
    };
    const [hasVideo, setHasVideo] = useState(CliConfirmationText[sType].videoUrl !== undefined);
    const videoPlaceholder = sType === 'claimdocs' ? true : false;
    const errorLoadingVideo = () => {
        setHasVideo(false);
    };
    useEffect(() => {
        setCliContextClone(Object.assign({}, originalContext));
        resetCliDataContext();
    }, []);
    useEffect(() => {
        if (hasVideo && !videoPlaceholder) {
            let videoElement = document.getElementById('video-message-link');
            if (videoElement) {
                videoElement.addEventListener('click', () => {
                    trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                        event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                        event_name: `${submissionType} video link clicked`,
                    });
                });
            }
            document.getElementsByClassName('brightcove-react-player-loader')[0]
                .addEventListener('click', () => {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.VIDEO_ENGAGEMENT,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                    event_name: `${submissionType} video box clicked`,
                });
            });
        }
        if (window.performance) {
            const perfEntry = window.performance.getEntriesByType("navigation");
            //@ts-ignore
            if (perfEntry && perfEntry[0]?.type === 'reload' && !submitClaimOrLeaveNumber) {
                if (experience === Experience.EMPLOYEE) {
                    navigate('/cli/employee/intake');
                }
                else if (experience === Experience.EMPLOYER) {
                    navigate('/cli/employer/intake');
                }
            }
        }
    }, []);
    // TODO: determine mobile/responsive location for video content (below? or slotted in between other elements)
    // TODO: need spanish text for all static content/labels on this page
    // TODO:L button color for text needs to match the same blue we use for links -> will be an Honest change
    return (<>
            <TaggedCustomerName />
            <Row data-public='true' className={hasVideo ? "justify-content-lg-between justify-content-md-center" : ""}>
                {/* Main Content Column */}
                <Col lg={hasVideo ? 8 : 12} md={12} sm={12} xs={12}>
                    <Heading elemType={'h1'} elemStyle={'h3'} data-testid='confirmationHeading'>
                        {t(CliConfirmationText[sType].pageHeading)}
                    </Heading>
                    {!user?.customerInContext?.smallMid &&
            <BorderedTextDiv data-testid='confirmationSubHeading'>
                            <span data-testid='confirmationSubheadingSpan'>{t(CliConfirmationText[sType].subHeading)}</span>
                            <br />
                            {/* TODO: Translate/put in CliConfirmationText - during video story where this may change */}
                            {hasVideo && t(CliConfirmationText[sType].videoMessage)}
                        </BorderedTextDiv>}
                    <PrettyBox>
                        <Row>
                            <Col>
                                <Heading elemType={'h2'} elemStyle={'h3'} color={'primaryDarker'}>
                                    <span data-testid='eventHeading'>
                                        {t(CliConfirmationText[sType].eventNumber)} {(submitClaimOrLeaveNumber && submitClaimOrLeaveNumber !== 'pending') ? submitClaimOrLeaveNumber : t(CliConfirmationText.claimdocs.claimNumber)}
                                    </span>
                                </Heading>
                            </Col>
                            <Col>
                                {/* TODO: test and hook up error message */}
                                <DownloadAndPrint cliContextClone={{ ...cliContextClone, language: language }} setErrorMessage={() => console.log('there was an error')}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <GrayBottomDiv data-private='true'>
                                    <strong>{t({ english: 'CLAIMANT NAME', spanish: 'NOMBRE DEL RECLAMANTE' })}</strong>
                                    <br />
                                    <span data-testid='claimantName'>
                                        {cliContextClone?.claimantData?.firstName?.toUpperCase()} {cliContextClone?.claimantData?.lastName?.toUpperCase()}
                                    </span>
                                </GrayBottomDiv>
                            </Col>
                            <Col>
                                <GrayBottomDiv>
                                    <strong>{t({ english: 'TODAY\'S DATE', spanish: 'FECHA' })}</strong>
                                    <br />
                                    {submissionDate} {submissionTime}
                                </GrayBottomDiv>
                            </Col>
                        </Row>
                    </PrettyBox>
                    {user?.customerInContext?.smallMid && (<ImportantMessageBox data-testid={`claimNumMessage`} title={t(CliConfirmationText.claimdocs.claimNumberMessageTitle)}>
                            {t(CliConfirmationText.claimdocs.claimNumberMessageBody)}
                        </ImportantMessageBox>)}
                    {(overviewBodyConfirmation && overviewBodyConfirmation.english) && (<ImportantMessageBox aria-label={`confirmation message`} data-testid={`employerMessage`} title={overviewTitleConfirmation ? t(overviewTitleConfirmation) : ''}>
                                {overviewBodyConfirmation && t(overviewBodyConfirmation)}
                        </ImportantMessageBox>)}
                    <Heading elemType='h3' elemStyle='h3'>{t(CliConfirmationText[sType].ourNextStepsHeading)}</Heading>
                    <p data-testid='ourNextSteps'>{t(CliConfirmationText[sType].ourNextStepsBody)}</p>

                    <Heading elemType='h3' elemStyle='h3'>{t(CliConfirmationText[sType].nextStepsHeading)}</Heading>
                    {submissionType &&
            <NextSteps data-testid="next-steps" eventNum={submitClaimOrLeaveNumber} submissionType={sType}/>}
                    <br style={{ marginBottom: '1rem' }}/>
                    <p data-testid='confirmationQuestions'>
                        {t(CliConfirmationText[sType].questions)}
                    </p>

                </Col>

                {/* SideBar Column */}
                {hasVideo &&
            <Col lg={4} md={7} sm={12} xs={12} data-testid='videoBox'>
                        <VideoBox submissionType={sType} onFailure={errorLoadingVideo}></VideoBox>
                    </Col>}
            </Row>
        </>);
};
export default ConfirmationNew;
