import { Box, TabContainer, Table, TableData, TableHeaderCol, TableRow, TabSection, themes, } from '@digitools/honest-react';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import React from 'react';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { DEDUCTIBLES_AND_COINSURANCE, getTabs, tealiumFormat, } from 'src/packages/dental/providers/DentalDataProvider';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import { dentalBoxClass, DeductiblesAndCoinsuranceHeader, } from 'src/packages/dental/pages/DeductibleAndCoinsurance';
import { useMediaQuery } from '@mantine/hooks';
const StyledTableHeaderCol = ({ value }) => {
    return (<TableHeaderCol className={'border-0 bg-white'} style={{ textTransform: 'none', color: 'black' }} scope={'col'}>
      {value}
    </TableHeaderCol>);
};
const CoinsuranceRows = ({ coinsurances, networkType, coinsuranceClause, isTitleView, isSmallestView }) => {
    const tableClass = isTitleView ? 'p-0 mt-0 mb-1 mr-0' : 'pt-0 mt-0 mb-1';
    const { t } = useTranslate();
    return (<Table style={{ position: 'relative', left: isSmallestView ? '-10px' : '0px' }} className={tableClass}>
      <TableRow className={'border-bottom'} isHoverable={false}>
        <StyledTableHeaderCol value={''}/>
        <StyledTableHeaderCol value={t(DentalText.youPayText)}/>
        <StyledTableHeaderCol value={t(DentalText.deductibleAppliesText)}/>
      </TableRow>
      {coinsurances &&
            coinsurances.map(coinsurance => coinsurance[networkType] && (<>
                <TableRow key={coinsurance.serviceCategory} className={'border-bottom'} isHoverable={false}>
                  <StyledTableHeaderCol value={coinsurance.serviceCategory}/>
                  <TableData className={'border-0 font-weight-light'} scope={'col'}>
                    {coinsurance[networkType].networkCoinsurance}
                  </TableData>
                  <TableData className={'border-0 font-weight-light'} scope={'col'}>
                    {coinsurance[networkType].deductibleApplies}
                  </TableData>
                </TableRow>
              </>))}
      {coinsuranceClause && (<TableRow key={coinsuranceClause?.label} className={'border-bottom'} isHoverable={false}>
          <StyledTableHeaderCol value={coinsuranceClause.label}/>
          <TableData className={'border-0 font-weight-light'} scope={'col'}>
            {coinsuranceClause[networkType + 'Value']}
          </TableData>
          <TableData className={'border-0 font-weight-light'} scope={'col'}>
            {coinsuranceClause[networkType + 'Value']}
          </TableData>
        </TableRow>)}
    </Table>);
};
const Coinsurances = () => {
    const { currentMember, tealiumFunction } = useDentalDataContext();
    const { t } = useTranslate();
    const isTitleView = useMediaQuery('(max-width: 768px)');
    const isSmallestView = useMediaQuery('(max-width: 344px)');
    const [inNetworkLabel, outNetworkLabel, deductiblesAndCoinsurances, coinsurances] = getTabs('coinsurances', currentMember);
    const hasData = !!(coinsurances && coinsurances?.length);
    const hasMultiTabs = !!(inNetworkLabel.length && outNetworkLabel.length);
    const trackDropdownEvent = (eventName) => tealiumFunction({
        hasEventView: false,
        eventAction: TEALIUM_EVENT.EVENT_ACTION.TAB,
        eventName: tealiumFormat(`${eventName}-coinsurances`),
        eventType: tealiumFormat(DEDUCTIBLES_AND_COINSURANCE),
    });
    return (<Box theme={themes.lincoln} className={dentalBoxClass(isTitleView)}>
      <DeductiblesAndCoinsuranceHeader title={t(DentalText.coinsuranceTitleText)} text={currentMember?.deductiblesAndCoinsurance?.coinsuranceHelpText}/>
      {hasData && hasMultiTabs ? (<TabContainer noVertical={true}>
          <TabSection onClick={() => {
                trackDropdownEvent(inNetworkLabel);
            }} label={inNetworkLabel} name={'coinsurancesInNetworkTab'}>
            <CoinsuranceRows isSmallestView={isSmallestView} isTitleView={isTitleView} coinsurances={coinsurances} networkType={'inNetwork'} coinsuranceClause={currentMember?.deductiblesAndCoinsurance?.coinsuranceClause}/>
          </TabSection>
          <TabSection onClick={() => {
                trackDropdownEvent(outNetworkLabel);
            }} label={outNetworkLabel} name={'coinsurancesOutNetworkTab'}>
            <CoinsuranceRows isSmallestView={isSmallestView} isTitleView={isTitleView} coinsurances={coinsurances} networkType={'outNetwork'} coinsuranceClause={currentMember?.deductiblesAndCoinsurance?.coinsuranceClause}/>
          </TabSection>
        </TabContainer>) : (hasData && (<CoinsuranceRows isSmallestView={isSmallestView} isTitleView={isTitleView} coinsurances={coinsurances} networkType={inNetworkLabel ? 'inNetwork' : 'outNetwork'} coinsuranceClause={currentMember?.deductiblesAndCoinsurance?.coinsuranceClause}/>))}
    </Box>);
};
export default Coinsurances;
