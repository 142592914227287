import React, { useMemo } from 'react';
import { CompositeTable as DataTable, Icon } from '@digitools/honest-react';
import { Link } from 'react-router-dom';
import { encode } from 'status/utilities/hash';
import { useExperience } from 'status/hooks';
import dashedDateFormatToString from '../../../utilities/dashedDateFormatToString';
import useLanguage from '../../../hooks/useLanguage';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  display: block;
  text-align: center;  
`;

const LinkIcon = styled(Icon)`
  &:hover {
    text-decoration: none !important;
  }
`;

const AccommodationLinkCell = ({ data }) => {
  const { experience } = useExperience();
  const route = experience === 'ER' ? 'employer' : 'employee';
  let to = data._original.fromTeamView
    ? `/status/${route}/${encode(data._original.empNum)}/accommodations/${encode(data.asEvent)}/details?from=teamView`
    : `/status/${route}/${encode(data._original.empNum)}/accommodations/${encode(data.asEvent)}/details`;

  if (data._original.search) {
    to = `${to}?search=${data._original.search}&searchCrit=${data._original.searchCrit}`;
  }
  return <Link to={to}>{data.asEvent}</Link>;
};

const uploadDocumentsCell = ({ data }) => {
  const { experience } = useExperience();
  const route = experience === 'ER' ? 'employer' : 'employee';
  let to = data._original.fromTeamView
    ? `/status/${route}/${encode(data._original.empNum)}/accommodations/${encode(data.asEvent)}/details?from=teamView`
    : `/status/${route}/${encode(data._original.empNum)}/accommodations/${encode(data.asEvent)}/details`;
  return (
    <StyledLink
      to={to}
      onClick={() => {
        window.sessionStorage.setItem('statusNav', 'upload')
      }}
      data-testid={'docUploadLink'}
    >
      <LinkIcon color='secondaryDark' name='upload' />
    </StyledLink>
  );
};

const AccommodationTable = ({ accommodations, empNum, fromTeamView }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const formattedData = useMemo(() => {
    const data = [];
    if (accommodations) {
      accommodations.forEach(a => {
        const formattedAccommodation = {
          asEvent: a.evtNum.toString(),
          asReceivedDate: dashedDateFormatToString(a.evtRecdDte),
          asStatus: language.toUpperCase() === 'EN' ? a.eventStatusValue_EN : a.eventStatusValue_ES,
          empNum,
          fromTeamView: fromTeamView,
          uploadDocuments: '',
        };
        data.push(formattedAccommodation);
      });
    }
    return data;
  }, [accommodations]);

  const columns = useMemo(() => {
    return [
      {
        Cell: AccommodationLinkCell,
        Header: t('event'),
        accessor: 'asEvent',
        id: 'asEvent',
      },
      {
        Header: t('receivedDateText'),
        accessor: 'asReceivedDate',
        id: 'asReceivedDate',
        sortMethod: (a, b) => new Date(b) - new Date(a),
      },
      {
        Header: t('status'),
        accessor: 'asStatus',
        id: 'asStatus',
      },
      {
        Cell: uploadDocumentsCell,
        Header: t('uploadDocumentsText'),
        accessor: 'uploadDocuments',
        id: 'uploadDocuments',
      },
    ];
  }, [accommodations]);

  return (
    <>
      {accommodations.length !== 0 ? (
        <div>
          <DataTable
            data={formattedData}
            columns={columns}
            sortable={true}
            defaultSorted={[{ id: 'asReceivedDate' }]}
          />
        </div>
      ) : (
        'There are currently no items on your Accommodation'
      )}
    </>
  );
};

export default AccommodationTable;
