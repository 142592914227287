import { Heading } from '@digitools/honest-react';
import React from 'react';
import useTranslate from '../../../hooks/useTranslate';
import NavLink from './NavLink';
import { getEnvVariable } from 'src/utils/envUtils';
const NavGroup = ({ groupLabel, navLinks, closeParent, }) => {
    const { t } = useTranslate();
    const getNavigationUrl = (navLink) => {
        let url = '';
        if (!navLink.cnvgSso && !navLink.lamsSSO && !navLink.l4bSso) {
            url = navLink.url;
            return url;
        }
        else {
            return url;
        }
    };
    const newOnClickFn = (navLink) => {
        let ssoGroup = '';
        if (navLink.cnvgSso) {
            ssoGroup = 'cnvg';
        }
        else if (navLink.lamsSSO) {
            ssoGroup = 'lams';
        }
        else if (navLink.l4bSso) {
            ssoGroup = 'l4b';
        }
        if (navLink.cnvgSso || navLink.lamsSSO || navLink.l4bSso) {
            window.open(getEnvVariable('mlpHomeUrl') + '/customer/ssologin/' + ssoGroup);
        }
        closeParent(navLink.label.english);
    };
    return (<>
            <div style={{ display: 'inline-block', padding: '1.5rem', paddingTop: '.5rem', paddingBottom: '.5rem' }}>
                {groupLabel && <Heading aria-label="navigation group label" elemType='h4' elemStyle='h5' color='white'>{t(groupLabel)}</Heading>}
                {navLinks.map((navLink, i) => {
            return (<NavLink index={i} id={navLink.id} data-public={true} key={`NavLink-${i}`} angular={navLink.angular} cnvgSso={navLink.cnvgSso} proveRequired={navLink.proveRequired} lamsSSO={navLink.lamsSSO} l4bSso={navLink.l4bSso} externalLink={navLink.externalLink} url={getNavigationUrl(navLink)} onClick={() => newOnClickFn(navLink)} label={navLink.label}/>);
        })}

            </div>
            <iframe id="ssoKeepAliveIframe" style={{ display: 'none' }}/>
        </>);
};
export default NavGroup;
