import React from 'react';
import { Scope, useFormState } from 'informed';
import { LeaveType } from 'src/packages/cli/providers/CliConfigProvider';
import LeaveDates from '../components/LeaveDates';
import useExperience from 'src/hooks/useExperience';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import { Col, Label, NewDateInput, Row } from '@digitools/honest-react';
import AboutYourAbsenceText from 'src/packages/cli/constants/AboutYourAbsenceText';
import useTranslate from 'src/hooks/useTranslate';
import Relationship from '../components/Relationship';
import { Experience } from 'src/types/Experience';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { validateDateIsOnOrBeforeToday, validateDateIsWithinAYearFromToday, } from 'src/packages/cli/utils/validationUtils';
import OtherRelationship from '../components/OtherRelationship';
import { addYears } from 'date-fns';
const FamilyParentalFlow = () => {
    const { values } = useFormState();
    const { experience, language } = useExperience();
    const { absenceData } = useCliDataContext();
    const { formLabels } = AboutYourAbsenceText;
    const { t } = useTranslate();
    /* Show if leaveType === PARENTAL or FAMILY and they've answered leave reason */
    const shouldDisplayFamilyParentalFlow = (values?.absenceData?.leaveType === LeaveType.FAMILY_CARE ||
        values?.absenceData?.leaveType === LeaveType.PARENTAL) &&
        values?.absenceData?.leaveReason;
    /* Show other relationship text field if relationship is Other (OT) */
    const shouldDisplayOtherRelationship = values?.absenceData?.relationship === 'OT';
    /* Show placement date if relationship is  Foster Care (FC) */
    const shouldDisplayPlacementDate = values?.absenceData?.leaveReason === 'FC' || values?.absenceData?.leaveReason === 'AD';
    /* Show dates when relationship has been answered && otherRelationship question answered (if displayed) */
    const shouldDisplayCareRecipientDob = shouldDisplayOtherRelationship ||
        (!shouldDisplayOtherRelationship && (values?.absenceData?.relationship || values?.absenceData?.leaveReason));
    const shouldDisplayLeaveDates = shouldDisplayCareRecipientDob;
    return (<>
      {shouldDisplayFamilyParentalFlow && (<div data-testid='family-parental-flow'>
          {/* @ts-ignore */}
          <Scope scope='absenceData'>
            {/* relationship */}
            <Relationship />
            {/* IF relationship === Other -  otherRelationship */}
            {shouldDisplayOtherRelationship && (<Row className='mb-3'>
                <Col xs={12} md={5}>
                  <OtherRelationship />
                </Col>
              </Row>)}
            {/* IF reason === 'Foster  || reason === 'Adopted' - placementDate */}
            {shouldDisplayPlacementDate && (<Row className='mb-3'>
                <Col size={12}>
                  <Label className='mb-2' htmlFor='placementDate'>
                    {CustomEmployeeText(t(experience === Experience.EMPLOYEE ? formLabels.placementDateEE : formLabels.placementDateER))}
                  </Label>
                </Col>
                <Col size={12} sm={6} xl={4}>
                  <NewDateInput id={'placementDate'} field={'placementDate'} data-testid={'placementDate'} label={t(formLabels.placementDateShortLabel)} ariaLabelText={t(formLabels.placementDateShortLabel)} validate={(input) => validateDateIsOnOrBeforeToday(input, t, false)} minDate={new Date('01/01/1900')} maxDate={new Date()} initialValue={absenceData && absenceData.careRecipientDateOfBirth} placeholder={'mm/dd/yyyy'} lang={language}/>
                </Col>
              </Row>)}
            {/* careRecipientDateOfBirth */}
            {shouldDisplayCareRecipientDob && (<Row className='mb-3'>
                <Col size={12}>
                  <Label className='mb-2' htmlFor='careRecipientDateOfBirth'>
                    {CustomEmployeeText(t(experience === Experience.EMPLOYEE
                    ? formLabels.careRecipientDateOfBirthEE
                    : formLabels.careRecipientDateOfBirthER))}
                  </Label>
                </Col>
                <Col size={12} sm={6} xl={4}>
                  <NewDateInput id={'careRecipientDateOfBirth'} field={'careRecipientDateOfBirth'} data-testid='careRecipientDateOfBirth' label={t(formLabels.careRecipientDateOfBirthShortLabel)} ariaLabelText={t(formLabels.careRecipientDateOfBirthShortLabel)} minDate={undefined} maxDate={addYears(new Date(), 1)} validate={(input) => validateDateIsWithinAYearFromToday(input, t, false)} initialValue={absenceData && absenceData.careRecipientDateOfBirth} placeholder={'mm/dd/yyyy'} lang={language}/>
                </Col>
              </Row>)}
          </Scope>
          {/* leaveBeginDate & leaveEndDate */}
          {shouldDisplayLeaveDates && <LeaveDates />}
        </div>)}
    </>);
};
export default FamilyParentalFlow;
