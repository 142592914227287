import React from 'react';
import { Col, Label, NewDateInput, Row } from '@digitools/honest-react';
import useExperience from 'src/hooks/useExperience';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from '../../../hooks/useCliDataContext';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from 'src/types/Experience';
import { addYears, isAfter, isBefore, isEqual, parse } from 'date-fns';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import { Scope, useFormState } from 'informed';
import { validateDateIsWithinAYear, validateDateIsWithinOneYearBeforeAndTwoAfterToday, } from 'src/packages/cli/utils/validationUtils';
const LeaveDates = () => {
    const { absenceData } = useCliDataContext();
    const { t } = useTranslate();
    const { experience, language } = useExperience();
    const { formLabels } = AboutYourAbsenceText;
    const { values } = useFormState();
    const beginLabelText = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(formLabels.leaveBeginDateEE) : t(formLabels.leaveBeginDateER));
    const endLabelText = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(formLabels.leaveEndDateEE) : t(formLabels.leaveEndDateEE));
    const validateBeginDate = (input) => {
        if (input && values?.absenceData?.leaveEndDate) {
            if (isAfter(parse(input, 'P', new Date()), parse(values.absenceData.leaveEndDate, 'P', new Date()))) {
                return t(formLabels.leaveBeginDateError);
            }
        }
        return validateDateIsWithinAYear(input, t, true);
    };
    const isDateBeforeOrIsDateEqual = (input) => {
        if (input && values?.absenceData?.leaveBeginDate) {
            return (isBefore(parse(input, 'P', new Date()), parse(values.absenceData.leaveBeginDate, 'P', new Date())) ||
                isEqual(parse(input, 'P', new Date()), parse(values.absenceData.leaveBeginDate, 'P', new Date())));
        }
    };
    const validateEndDate = (input) => {
        if (isDateBeforeOrIsDateEqual(input)) {
            return t(formLabels.leaveEndDateError);
        }
        return validateDateIsWithinOneYearBeforeAndTwoAfterToday(input, t, false);
    };
    return (<>
      {/* @ts-ignore */}
      <Scope scope='absenceData'>
        <div data-testid='leaveDates'>
          <Row className='mb-3'>
            <Col xs={12}>
              <Label className='mb-2' htmlFor='leaveBeginDate'>
                {beginLabelText}
              </Label>
              <NewDateInput data-testid={'leaveBeginDate'} label={t(formLabels.leaveBeginDateShortLabel)} ariaLabelText={beginLabelText} field={'leaveBeginDate'} initialValue={absenceData && absenceData.leaveBeginDate} validate={validateBeginDate} minDate={addYears(new Date(), -1)} maxDate={values?.absenceData?.leaveEndDate ? new Date(values?.absenceData?.leaveEndDate) : undefined} placeholder={'mm/dd/yyyy'} lang={language}/>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs={12}>
              <Label className='mb-2' htmlFor='leaveEndDate'>
                {endLabelText}
              </Label>

              <NewDateInput id='leaveEndDate' data-testid={'leaveEndDate'} label={t(formLabels.leaveEndDateShortLabel)} ariaLabelText={endLabelText} field={'leaveEndDate'} initialValue={absenceData && absenceData.leaveEndDate} minDate={values?.absenceData?.leaveBeginDate ? new Date(values?.absenceData?.leaveBeginDate) : undefined} maxDate={addYears(new Date(), 2)} validate={validateEndDate} placeholder={'mm/dd/yyyy'} lang={language}/>
            </Col>
          </Row>
        </div>
      </Scope>
    </>);
};
export default LeaveDates;
