import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import { Alert, Box, Button, Col, Collapse, Heading, Icon, Row, Spinner, Table, TableBody, TableData, TableRow, } from '@digitools/honest-react';
import React, { useState } from 'react';
import { tealiumFormat } from 'src/packages/dental/providers/DentalDataProvider';
import StatusBadge from 'src/components/Cards/StatusBadge';
import DentalText from 'src/packages/dental/constants/DentalText';
import { GetClaimDetails, GetDetailsPdf } from 'src/packages/dental/api/DentalApiCalls';
import useExperience from 'src/hooks/useExperience';
import useAuthentication from 'src/hooks/useAuthentication';
import useTranslate from 'src/hooks/useTranslate';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { DentalSubHeading, ErrorHtml, getPdfFilename } from 'src/packages/dental/Dental';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { saveAs } from 'file-saver';
import Card from 'src/components/Cards/Card';
const UsersIcon = () => (<Icon name='profile' size='s' style={{ marginTop: '.26rem' }} color={'grayDarker'}/>);
const FileIcon = () => (<Icon name='documents' size='s' style={{ marginTop: '.26rem' }} color={'grayDarker'}/>);
const CollapsedIcon = () => (<Icon name='add' size='xs' className='d-flex align-items-center pr-2' color='primary'/>);
const ExpandedIcon = () => (<Icon name='subtract' size='xs' className='d-flex align-items-center pr-2' color='primary'/>);
const getStatusColor = (status) => {
    switch (status) {
        case 'CLOSED':
            return 'grayLight';
        case 'OPEN':
            return 'secondary';
        case 'READY_TO_ADJUDICATE':
            return 'success';
        case 'READY':
            return 'accent2Dark';
        default:
            return 'accent2Dark';
    }
};
const ClaimBoxes = () => {
    const { currentMember, tealiumFunction } = useDentalDataContext();
    const { t } = useTranslate();
    const { language } = useExperience();
    const [pdfError, setPdfError] = useState('');
    const [claimsInfoLoading, setClaimsInfoLoading] = useState({ number: -1, boolean: false });
    const [pdfDetailsLoading, setPdfDetailsLoading] = useState({ number: -1, boolean: false });
    const { authHeader } = useAuthentication();
    const isMobile = useMediaQuery('(max-width: 1439px)');
    const addClaimDetails = async (member, claim, claimIndex) => {
        setClaimsInfoLoading({ number: claimIndex, boolean: true });
        GetClaimDetails(authHeader(), language, claim.claimNumber || '', member.id)
            .then(claimDetailsResponse => {
            claim.claimDetailsError = claimDetailsResponse.graphQlError;
            claim.claimDetails = claimDetailsResponse.claimDetails;
        })
            .finally(() => setClaimsInfoLoading({ number: claimIndex, boolean: false }));
    };
    const processDetailsPdf = (claimNumber, claimIndex) => {
        setPdfDetailsLoading({ number: claimIndex, boolean: true });
        GetDetailsPdf(language, authHeader(), currentMember?.id, claimNumber)
            .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            saveAs(blob, getPdfFilename(`Claims_`, currentMember?.firstName, currentMember?.lastName, new Date()));
        })
            .catch(error => {
            setPdfError(t(DentalText.pdfError));
        })
            .finally(() => {
            setPdfDetailsLoading({ number: claimIndex, boolean: false });
        });
    };
    const handleDetailsClick = (claimNumber, claimIndex) => {
        if (!pdfDetailsLoading || !claimsInfoLoading.boolean) {
            setPdfError('');
            processDetailsPdf(claimNumber, claimIndex);
            tealiumFunction({
                eventName: tealiumFormat(t(DentalText.downloadClaimDetailsText)),
                eventAction: TEALIUM_EVENT.EVENT_ACTION.DOWNLOAD,
            });
        }
    };
    return (<>
      <>
        {isMobile ? (<></>) : (<div className={'d-flex justify-content-between h-100 w-100'}>
            <DentalSubHeading isMobile={isMobile} className={'pb-1 mb-0 align-self-end'} text={t(DentalText.claimsTitle)}/>
          </div>)}
      </>
      {currentMember?.claims &&
            currentMember.claims?.map((claim, index) => (<>
            {claim.status && (<>
                <Card data-testid={'outer-card-claims' + index} borderColor={getStatusColor(claim.status?.code)} onClick={e => {
                        if (!claim.claimDetails) {
                            addClaimDetails(currentMember, claim, index);
                        }
                    }} heading={<div>
                      <DentalSubHeading isMobile={isMobile} className={'p-0 m-0'} color={'secondaryDark'} text={claim.displayTitle}></DentalSubHeading>
                    </div>} secondaryHeading={<StatusBadge backgroundColor={getStatusColor(claim.status.code)}>{claim.status.value}</StatusBadge>}>
                  <div style={{ color: '#6b6b6b' }}>
                    <div className={'d-flex w-100 pb-3'}>
                      <UsersIcon />
                      <div style={{ paddingLeft: '.5rem' }}>{t(DentalText.claimsForText) + claim.patientName}</div>
                    </div>

                    <div className={'d-flex w-100 pb-3'}>
                      <FileIcon />
                      <div style={{ paddingLeft: '.5rem' }}>{claim.claimNumberStr}</div>
                    </div>
                  </div>
                  {claim.claimNumber && (<Collapse headerElemType={isMobile ? 'h4' : 'h2'} className={'pb-3 p-0'} title={t(DentalText.claimDetails)} id={'claim' + index + 'Details'} expandedIcon={ExpandedIcon} collapsedIcon={CollapsedIcon} type={'alternate'} color={'primary'}>
                      {claimsInfoLoading.boolean && claimsInfoLoading.number == index && (<Spinner data-testid='dental-spinner'/>)}
                      <>
                        {pdfError && (<>
                            <Row className={'pb-2'}>
                              <Col>
                                <Alert type={'error'}>{t(DentalText.pdfError)}</Alert>
                              </Col>
                            </Row>
                          </>)}

                        {claim?.claimDetails?.claimTreatmentGroups &&
                            claim?.claimDetails?.claimTreatmentGroups.map((group, index) => (<>
                              {claim.claimNumberStr && (group.treatmentGroupId || group.treatmentGroupId === '') && (<Collapse data-testid={claim.claimNumber + 'button'} style={{
                                        borderBottom: 0,
                                        paddingBottom: index + 1 == claim.claimDetails?.claimTreatmentGroups?.length ? '0' : '24px',
                                    }} title={claim.claimNumberStr + group.treatmentGroupId}>
                                  <div className={'pl-0'}>
                                    {group.paymentStatements &&
                                        group.paymentStatements.map(statement => (<p id={'summaryMessageFor' + index}>
                                          <span>
                                            <Icon name='money' size='s' className={'pr-1'}/>
                                          </span>
                                          <span>{statement}</span>
                                        </p>))}
                                    <p className={'pl-0 pb-1'}>{group.cobSecondaryStatement}</p>
                                    {isMobile ? (<>
                                        {group.claimTreatments &&
                                            group.claimTreatments.map(service => (<div style={{ paddingBottom: '1.5rem' }} className={'pl-2 pr-2'}>
                                              <p className={'pb-3'} style={{ fontSize: '18px' }}>
                                                {service.procedureName} ({service.procedureCode})
                                              </p>
                                              {claim?.claimDetails?.tableHeaders &&
                                                    claim?.claimDetails?.tableHeaders.map((headers, ind) => (<>
                                                    {claim?.claimDetails?.tableHeaders && headers.id && ind != 0 && (<Row className={'border-bottom pb-2 pl-2'}>
                                                        <Col>
                                                          <Heading elemType={'h4'}>
                                                            {claim?.claimDetails?.tableHeaders[ind]?.value}
                                                          </Heading>
                                                        </Col>
                                                        <Col>
                                                          <p className={'text-right font-weight-light w-100'}>
                                                            {service[headers.id]}
                                                          </p>
                                                        </Col>
                                                      </Row>)}
                                                  </>))}
                                            </div>))}
                                      </>) : (<Table color={'white'} className={'pt-0 mt-0 mb-1'}>
                                        <TableBody>
                                          <TableRow className={'border-bottom pl-0 pr-0 w-100'} isHoverable={false}>
                                            {claim?.claimDetails?.tableHeaders &&
                                            claim?.claimDetails?.tableHeaders.map(header => (<TableData className={'border-0 pl-0 pr-0'}>
                                                  <Heading elemType={'h4'}>{header.value}</Heading>
                                                </TableData>))}
                                          </TableRow>

                                          {group.claimTreatments &&
                                            group.claimTreatments.map(service => (<TableRow className={'border-bottom pl-0 pr-0 w-100'} isHoverable={false}>
                                                <>
                                                  <TableData className={'border-0 pl-0 pr-0'} style={{ width: '25%' }}>
                                                    <Heading elemType={'h4'}>
                                                      {service.procedureName} ({service.procedureCode})
                                                    </Heading>
                                                  </TableData>
                                                  {claim?.claimDetails?.tableHeaders &&
                                                    claim?.claimDetails?.tableHeaders.map((headers, ind) => (<>
                                                        {claim?.claimDetails?.tableHeaders &&
                                                            headers.id &&
                                                            ind != 0 && (<TableData className={'border-0 font-weight-light pl-0 pr-0'}>
                                                              {service[headers.id]}
                                                            </TableData>)}
                                                      </>))}
                                                </>
                                              </TableRow>))}
                                          <TableRow className={'border-bottom pl-0 pr-0 w-100'} isHoverable={false} style={{ backgroundColor: '#F2F4F6' }}>
                                            <TableData className={'border-0 font-weight-light pl-0 pr-0'}></TableData>
                                            <TableData className={'border-0 font-weight-light pl-0 pr-0'}>
                                              <>{group.totalDentistCharge}</>
                                            </TableData>
                                            <TableData className={'border-0 font-weight-light pl-0 pr-0'}>
                                              <>{group.totalDeductible}</>
                                            </TableData>
                                            <TableData className={'border-0 font-weight-light pl-0 pr-0'}>
                                              <>{group.totalCoinsurance}</>
                                            </TableData>
                                            <TableData className={'border-0 font-weight-light pl-0 pr-0'}>
                                              <>{group.totalCoveredAmount}</>
                                            </TableData>
                                            <TableData className={'border-0 font-weight-light pl-0 pr-0'}></TableData>
                                          </TableRow>
                                        </TableBody>
                                      </Table>)}
                                  </div>
                                  {isMobile && (<>
                                      {claim?.claimDetails?.tableHeaders &&
                                            claim?.claimDetails?.tableHeaders.length >= 5 && (<Box color={'grayLightest'} style={{ borderColor: 'lightgray', width: '101%' }}>
                                            <Row className={'border-bottom pb-2'}>
                                              <Col>
                                                <Heading elemType={'h4'}>
                                                  {claim?.claimDetails?.tableHeaders[1]?.value}
                                                </Heading>
                                              </Col>
                                              <Col>
                                                <p className={'text-right font-weight-light w-100'}>
                                                  {group.totalDentistCharge}
                                                </p>
                                              </Col>
                                            </Row>
                                            <Row className={'border-bottom pb-2'}>
                                              <Col>
                                                <Heading elemType={'h4'}>
                                                  {claim?.claimDetails?.tableHeaders[2].value}
                                                </Heading>
                                              </Col>
                                              <Col>
                                                <p className={'text-right font-weight-light w-100'}>
                                                  {group.totalDeductible}
                                                </p>
                                              </Col>
                                            </Row>
                                            <Row className={'border-bottom pb-2'}>
                                              <Col>
                                                <Heading elemType={'h4'}>
                                                  {claim?.claimDetails?.tableHeaders[3].value}
                                                </Heading>
                                              </Col>
                                              <Col>
                                                <p className={'text-right font-weight-light w-100'}>
                                                  {group.totalCoinsurance}
                                                </p>
                                              </Col>
                                            </Row>
                                            <Row className={'border-bottom pb-2'}>
                                              <Col>
                                                <Heading elemType={'h4'}>
                                                  {claim?.claimDetails?.tableHeaders[4].value}
                                                </Heading>
                                              </Col>
                                              <Col>
                                                <p className={'text-right font-weight-light w-100'}>
                                                  {group.totalCoveredAmount}
                                                </p>
                                              </Col>
                                            </Row>
                                          </Box>)}
                                    </>)}
                                </Collapse>)}
                            </>))}
                      </>
                      {claim?.claimDetailsError && (<ErrorHtml errorType={claim.claimDetailsError} generalErrorMessage={`${t(DentalText.claimDetailsGeneralError)}`} notFoundErrorMessage={t(DentalText.claimDetailsNotFoundError)}/>)}
                      <hr className={'mt-4 mb-2'}/>
                      {pdfDetailsLoading.boolean && pdfDetailsLoading.number == index ? (<Spinner data-testid='dental-spinner'/>) : (<Button className={'pr-0 pt-2 pl-0'} style={{ paddingBottom: '2rem' }} data-testid={'claimDetailsPdfButton' + claim.claimNumber} onClick={() => {
                                handleDetailsClick(claim.claimNumber, index);
                            }}>
                          {<Icon name={'download'} color={'secondaryLight'}></Icon>}
                          {' ' + t(DentalText.downloadClaimDetailsText)}
                        </Button>)}
                    </Collapse>)}
                </Card>
              </>)}
          </>))}
    </>);
};
export default ClaimBoxes;
