import { Row, Col, Label, themes, NewDateInput } from '@digitools/honest-react';
import { addYears, isAfter, parse } from 'date-fns';
import React from 'react';
import useExperience from 'src/hooks/useExperience';
import useTranslate from 'src/hooks/useTranslate';
import { validateDateIsWithinAYear } from 'src/packages/cli/utils/validationUtils';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import useCliDataContext from '../../../hooks/useCliDataContext';
import { useFormState } from 'informed';
const LastDayWorked = ({ lastDayRequired }) => {
    const { absenceData } = useCliDataContext();
    const { language } = useExperience();
    const { t } = useTranslate();
    const { formLabels } = AboutYourAbsenceText;
    const { values } = useFormState();
    const validateLastDayWorked = (input) => {
        if (input && values?.absenceData?.claimBeginDate) {
            if (isAfter(parse(input, 'P', new Date()), parse(values.absenceData.claimBeginDate, 'P', new Date()))) {
                return t({
                    english: 'Last day worked cannot be after the date of disability',
                    spanish: 'El último día trabajado no puede ser después de la fecha de discapacidad'
                });
            }
            else
                return validateDateIsWithinAYear(input, t, true);
        }
        else
            return validateDateIsWithinAYear(input, t, true);
    };
    return (<>
      {/* Last Day Worked */}
      <Row className='mb-3'>
        <Col size={12}>
          <Label className='mb-2'>{t(formLabels.lastDayWorked)}</Label>
        </Col>
        <Col size={12} sm={6} xl={4}>
          <NewDateInput data-testid='lastDayWorked' theme={themes.lincoln} field={'lastDayWorked'} lang={language} label={t(formLabels.lastDayWorkedShort)} placeholder={'MM/DD/YYYY'} ariaLabelText={t(formLabels.lastDayWorked)} minDate={addYears(new Date(), -1)} maxDate={values?.absenceData?.claimBeginDate ? new Date(values?.absenceData?.claimBeginDate) : addYears(new Date(), 1)} validate={validateLastDayWorked} initialValue={absenceData && absenceData.lastDayWorked}/>
        </Col>
      </Row>
    </>);
};
export default LastDayWorked;
