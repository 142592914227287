import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import useTranslate from 'src/hooks/useTranslate';
import { Alert, Link, Spinner } from '@digitools/honest-react';
import DentalText from 'src/packages/dental/constants/DentalText';
import React, { useEffect } from 'react';
import { ErrorHtml } from 'src/packages/dental/Dental';
import { CLAIMS } from 'src/packages/dental/providers/DentalDataProvider';
import { GetClaims, HIPAA_VIOLATION } from 'src/packages/dental/api/DentalApiCalls';
import useAuthentication from 'src/hooks/useAuthentication';
import useExperience from 'src/hooks/useExperience';
import ClaimBoxes from 'src/packages/dental/components/ClaimBoxes';
export const ClaimsInfo = () => {
    const { infoLoading, currentMember, activeTab, setCurrentMember, setClaimsLoading, claimsLoading, } = useDentalDataContext();
    const { authHeader } = useAuthentication();
    const { language } = useExperience();
    useEffect(() => {
        if (currentMember && !currentMember.claims && !currentMember.claimSummaryError && !currentMember.hipaaViolation && activeTab == CLAIMS) {
            setClaimsLoading(true);
            GetClaims(authHeader(), language, currentMember.memberType, currentMember.id)
                .then((claimsResponse) => {
                currentMember.claims = claimsResponse?.claims;
                if (claimsResponse.graphQlError === HIPAA_VIOLATION) {
                    currentMember.hipaaViolation = true;
                }
                else {
                    currentMember.claimSummaryError = claimsResponse?.graphQlError;
                }
                setCurrentMember(currentMember);
            })
                .finally(() => setClaimsLoading(false));
        }
    }, [activeTab, currentMember?.id]);
    const { t } = useTranslate();
    const hasError = currentMember?.claimSummaryError || currentMember?.hipaaViolation;
    const HipaaViolation = () => {
        const hipaaText = t(DentalText.hipaaViolationText).split('%%link%%');
        return (<p>
        {hipaaText[0]}
        <span>
          <Link target={'_blank'} href={'https://www.lincoln4benefits.com/adminWebContent/Production/Ardis_Forms/grup/BJ-03566.pdf'}>
            {t(DentalText.dentalInfoReleaseFormLinkText)}
          </Link>
        </span>
        {hipaaText[1]}
      </p>);
    };
    if (claimsLoading || infoLoading) {
        return <Spinner data-testid='claims-spinner'/>;
    }
    return (<>
      {currentMember?.hipaaViolation && (<Alert type={'info'}>
          <HipaaViolation />
        </Alert>)}
      {currentMember?.claimSummaryError && (<ErrorHtml errorType={currentMember.claimSummaryError} generalErrorMessage={`${t(DentalText.claimSummaryGeneralError)}`} notFoundErrorMessage={t(DentalText.claimSummaryNotFoundError)}/>)}

      {!hasError && currentMember?.claims && currentMember.claims.length == 0 && <Alert type={'info'}>{t(DentalText.claimsNotFoundInfo)}</Alert>}
      {!hasError && currentMember?.claims && currentMember?.claims?.length > 0 && <ClaimBoxes />}
    </>);
};
const Claims = () => {
    const { currentMember, subscriber } = useDentalDataContext();
    const { t } = useTranslate();
    return (<>
      {!!currentMember && !!subscriber && (<>
          <p>{t(DentalText.disclaimerText)}</p>
          {currentMember?.planOverview?.inNetworkOnlyClause && (<Alert style={{ padding: '.5rem' }} type={'info'}>
              {currentMember.planOverview.inNetworkOnlyClause}
            </Alert>)}
          <ClaimsInfo />
        </>)}
    </>);
};
export default Claims;
