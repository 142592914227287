import React from 'react';
import { Button, themes } from '@digitools/honest-react';
import useTranslate from '../../../../hooks/useTranslate';
import useCliDataContext from '../../hooks/useCliDataContext';
import useAuthentication from 'src/hooks/useAuthentication';
import styled from 'styled-components';
import { CliText } from '../../constants/CliText';
import { useNavigate } from 'react-router-dom';
import useExperience from 'src/hooks/useExperience';
import { SubmitInterviewData } from '../../api/CliApiCalls';
import useCliCustomerConfigContext from '../../hooks/useCliContext';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { stringifyUploads } from 'src/utils/TealiumUtils';
const StyledReviewSubmitButton = styled(Button) `
  @media (max-width: 1200px) {
    padding: 0 1rem;
    width: 100%;
    margin-bottom: 0.5rem;
  }
  @media (max-width: 765px) {
      margin-left: 0 !important;
  }
`;
const ReviewSubmit = () => {
    const { t } = useTranslate();
    let navigate = useNavigate();
    const { setValidationResults, contactData, claimantData, absenceData, additionalDetails, documents, paymentMethodData, uploadedFiles, setSubmitErrors, setIsLoading, isLoading, setDocuments, setUploadedFiles, } = useCliDataContext();
    const { empIdText, leaveCategoryDays, leaveTypes, leaveReasons, claimantCondition, relationships, deliveryIndicators, isClaimCustomer, isClaimLeaveCustomer, isLeaveCustomer, hasAdditionalInfo, saveForLaterEntries, } = useCliCustomerConfigContext();
    const { experienceCode, experience, language } = useExperience();
    const { authHeader } = useAuthentication();
    const { trackEvent } = useTealium();
    const handleOnClick = async () => {
        setIsLoading(true);
        const submitObj = {
            claimantData: claimantData ? claimantData : {},
            absenceData: absenceData ? absenceData : {},
            contactData: contactData ? contactData : {},
            paymentMethodData: paymentMethodData ? paymentMethodData : {},
            documents,
            language,
            uploadedFiles,
            saveForLaterId: saveForLaterEntries?.rowID,
            experience: experienceCode,
            claimActive: !!isClaimCustomer,
            leaveActive: !!isLeaveCustomer,
            ...(experienceCode === 'ER' && { additionalDetails }),
            configData: {
                empIdText: empIdText,
                leaveCategoryDays,
                leaveTypes,
                leaveReasons,
                claimantCondition,
                relationships,
                deliveryIndicators,
                isLeaveCustomer: !!isLeaveCustomer,
                isClaimCustomer: !!isClaimCustomer,
                isClaimLeaveCustomer: !!isClaimLeaveCustomer,
                hasAdditionalInfo: !!hasAdditionalInfo,
            },
        };
        try {
            const { data, status } = await SubmitInterviewData(submitObj, authHeader());
            if (status === 200) {
                setIsLoading(false);
                setSubmitErrors(false);
                setValidationResults(data);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                    event_name: 'submit',
                    event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                    event_version: 'review',
                    event_status: TEALIUM_EVENT.EVENT_STATUS.COMPLETE,
                    event_results: uploadedFiles.length.toString(),
                    transaction_id: data?.claimOrLeaveNumber,
                    form_result: stringifyUploads(uploadedFiles)
                });
                window.scrollTo(0, 0);
                navigate(`/cli/${experience.toLowerCase()}/confirmationNew`);
            }
            else {
                console.log('Error submitting application', { status });
                setSubmitErrors(true);
                setIsLoading(false);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
                    event_name: 'Error submitting application',
                    event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                    event_version: 'review',
                });
            }
        }
        catch (error) {
            setIsLoading(false);
            setSubmitErrors(error?.response?.data[0]?.message);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
                event_name: 'Error submitting application',
                event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                event_version: 'review',
            });
        }
    };
    return (<StyledReviewSubmitButton type='submit' buttonType={'primary'} theme={themes.lincoln} data-testid='review-submit' disabled={isLoading} onClick={handleOnClick}>
      {t(CliText.submit)}
    </StyledReviewSubmitButton>);
};
export default ReviewSubmit;
