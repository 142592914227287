import React, { useContext } from 'react';
import { Row, Col, TextInput, NewDateInput } from '@digitools/honest-react';
import { BdmAddBeneficiaryModalDisplayTextEn as LanguageEn } from '../language/BdmAddBeneficiaryModalDisplayTextEn';
import { BdmAddBeneficiaryModalDisplayTextEs as LanguageEs } from '../language/BdmAddBeneficiaryModalDisplayTextEs';
import { formatPhone, validateTrustName } from '../../../utils/validationUtils';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { Language } from '../../../types/Language';
import BdmBeneficiaryUtils from '../utils/BdmBeneficiaryUtils';
import { useIsLci } from '../../../providers/IsLciProvider';
import { validateDateIsOnOrBeforeToday } from 'src/packages/cli/utils/validationUtils';
import useTranslate from 'src/hooks/useTranslate';
import { parse } from 'date-fns';
const BdmModalContentTrust = ({ beneficiaryCategory, beneficiaryIndex, beneficiary }) => {
    const isLci = useIsLci();
    const experienceContext = useContext(ExperienceContext);
    const displayText = experienceContext.language === Language.SPANISH ? LanguageEs : LanguageEn;
    const idPrefix = BdmBeneficiaryUtils.idBuilder(beneficiaryCategory, beneficiaryIndex);
    const { t } = useTranslate();
    return (<>
      <Row>
        <Col>
          <TextInput field={`${idPrefix}trustName`} label={displayText.trustName} type={'text'} validateOnBlur={true} validate={validateTrustName} initialValue={beneficiary ? beneficiary.trustName : undefined} data-private={true}/>
        </Col>
        <Col>
          <NewDateInput data-testid='dateOfTrust' label={displayText.dateOfTrust} field={`${idPrefix}dateOfTrust`} ariaLabelText={displayText.dateOfTrust} minDate={parse('01/01/1900', 'P', new Date())} maxDate={new Date()} validate={(props) => validateDateIsOnOrBeforeToday(props, t, true)} initialValue={beneficiary ? beneficiary.dateOfTrust : undefined}/>
        </Col>
        <Col />
      </Row>
      {isLci && (<Row>
          <Col>
            <TextInput field={`${idPrefix}phoneNumber`} label={displayText.phoneNumber} type={'text'} mask={formatPhone}/>
          </Col>
          <Col>
            <TextInput field={`${idPrefix}emailAddress`} label={displayText.emailAddress} type={'text'}/>
          </Col>
        </Row>)}
    </>);
};
export default BdmModalContentTrust;
