import React, { memo } from 'react';
import styled from 'styled-components';
import { Footer as LFGFooter, Link } from '@digitools/honest-react';
import useTranslate from '../../hooks/useTranslate';
import { Link as RRLink } from 'react-router-dom';
import { FooterText } from './FooterText';
import useTealium from '../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../constants/tealiumConstants';
import FooterNavigation from './FooterNavigation';
// @ts-ignore
import styles from './../../app/App.scss';
import useAuthentication from '../../hooks/useAuthentication';
import CookieConsent from './CookieConsent';
// dunno how this little nugget of desctructuring works but its cool
const { FooterLink } = LFGFooter;
const DefaultCopyright = () => (<div className='copyright'>
    {`© ${new Date().getFullYear()} Lincoln National Corporation. All rights
        reserved`}
  </div>);
// TODO: Add to honest
const StyledFooterLinkContainer = styled.div `
  @media (max-width: 767px) {
    &&& a {
      line-height: 38px;
    }

    &&& li {
      display: block;
      border-right: none;
    }
  }
`;
const FooterLinks = memo(() => {
    const { t } = useTranslate();
    const { isAuthenticated } = useAuthentication();
    const { trackEvent } = useTealium();
    const trackPageEvent = (eventName) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: eventName,
            event_type: TEALIUM_EVENT.EVENT_TYPE.FOOTER,
        });
    };
    return (<StyledFooterLinkContainer>
      <FooterLink>
        <RRLink to='/public/accessibility' onClick={() => trackPageEvent('Accessibility')}>
          {t(FooterText.accessibility)}
        </RRLink>
      </FooterLink>
      {isAuthenticated && (<FooterLink>
          <RRLink to='/learning/glossary' onClick={() => trackPageEvent('Glossary')}>
            {t(FooterText.glossary)}
          </RRLink>
        </FooterLink>)}
      <FooterLink>
        <Link target='_blank' href={'https://www.lfg.com/public/aboutus/companyoverview'} onClick={() => trackPageEvent('Company overview')}>
          {t(FooterText.companyOverview)}
        </Link>
      </FooterLink>
      <FooterLink>
        <Link target='_blank' href={'https://www.lfg.com/privacy'} onClick={() => trackPageEvent('Privacy')}>
          {t(FooterText.privacy)}
        </Link>
      </FooterLink>
      <FooterLink>
        <Link target='_blank' href={'https://www.lfg.com/legal'} onClick={() => trackPageEvent('Legal')}>
          {t(FooterText.legal)}
        </Link>
      </FooterLink>
      <FooterLink>
        <Link target='_blank' href='https://policies.google.com/technologies/partner-sites' onClick={() => trackPageEvent('Google analytics')}>
          {t(FooterText.googleAnalytics)}
        </Link>
      </FooterLink>
      <FooterLink>
        <Link target='_blank' href='https://www.lincolnfinancial.com/public/general/support/reportfraud/form' onClick={() => trackPageEvent('report fraud')}>
          {t(FooterText.reportFraud)}
        </Link>
      </FooterLink>
    </StyledFooterLinkContainer>);
});
// TODO: the footer nav link (story pending)
const Footer = () => {
    const { t } = useTranslate();
    return (<div data-testid={'mlp-footer'} data-public={true} className={styles.footer}>
      <FooterNavigation />
      <LFGFooter links={FooterLinks} 
    // @ts-ignore
    content={<>
            <div className='mb-1'>
              {t(FooterText.content)}
            </div>
            <div>
              <CookieConsent />
            </div>
          </>} copyright={DefaultCopyright} showSocialIcons={true}/>
    </div>);
};
export default Footer;
