import React, { useMemo, useState } from 'react';
import { Icon, Button, CompositeTable as DataTable, Spinner } from '@digitools/honest-react';
import { Link } from 'react-router-dom';
import titleCase from 'status/utilities/titleCase';
import { encode } from 'status/utilities/hash';
import { usePost } from 'status/hooks';
import { statusCodes } from '../../../../createMyReport/components/run-my-report/report-preview/RunReportParamMapping';
import formatDateMMDDYYY from '../../../utilities/formatDateMMDDYYY';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';

const getStatusValue = statusCode => {
  let value;
  statusCodes.forEach(item => {
    if (item.code === statusCode) {
      value = item.value;
    }
  });
  return value;
};

const ACCOMMODATION_DECODE = 'Accommodation';
const ACCOMMODATION_CODE = 'AS';

const WatchlistTable = ({ watchlistData, from }) => {
  if (!watchlistData || watchlistData.length === 0) {
    return null;
  }

  const [tableData, setTableData] = useState(watchlistData);

  const EventIdCell = ({ data }) => {
    const { trackEvent } = useTealium();

    const handleClick = data => {
      trackEvent({
        event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
        event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
        event_name: data.eventId,
        event_results: data.eventType.toLowerCase(),
        event_version: data.eventType.toLowerCase() === 'leave' ? 'leave details' : 'claim details',
      });
    };
    // Another check for event type could be needed in the future if value
    // for event type can be anything other than 'Claim' and 'Leave'
    if (data.eventType === 'Claim') {
      return (
        <Link
          to={`/status/employer/${encode(data._original.employeeNumber)}/claims/${encode(
            data.eventId,
          )}/details?from=${from}`}
          onClick={() => handleClick(data)}>
          {data.eventId}
        </Link>
      );
    } else if (data.eventType === 'Leave') {
      return (
        <Link
          to={`/status/employer/${encode(data._original.employeeNumber)}/leaves/${encode(
            data.eventId,
          )}/details?from=${from}`}
          onClick={() => handleClick(data)}>
          {data.eventId}
        </Link>
      );
    } else if (data.eventType === ACCOMMODATION_DECODE) {
      return (
        <Link
          to={`/status/employer/${encode(data._original.employeeNumber)}/accommodations/${encode(
            data.eventId,
          )}/details?from=${from}`}
          onClick={() => handleClick(data)}>
          {data.eventId}
        </Link>
      );
    } else {
      return <Link>{data.eventId}</Link>;
    }
  };

  const NameCell = ({ data }) => {
    // Making This a custom cell so we can display the table in logrocket and keep this NPPI field hidden,
    // table doesn't support just tagging columnns with data-private or data-public yet
    return <span data-private={true}>{data.name}</span>;
  };

  const EventDeleteCell = ({ data }) => {
    const { trackEvent } = useTealium();

    const success = () => {
      const newList = tableData.filter(item => item.eventId !== data.eventId);
      setTableData(newList);
    };

    const {
      post: postRemovePost,
      error: postRemoveError,
      loading: postRemoveLoading,
    } = usePost({
      url: '/status-service/watch-list/remove',
      payload: {
        eventId: data._original.eventId,
        eventType: data.eventType === ACCOMMODATION_DECODE ? ACCOMMODATION_CODE : data.eventType.toUpperCase(),
      },
      success,
    });

    const handleClick = () => {
      trackEvent({
        event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
        event_type: TEALIUM_EVENT.EVENT_TYPE.REMOVE_FROM_WATCHLIST,
        event_name: 'Remove From Watchlist',
      });
      postRemovePost();
    };

    if (postRemoveError) {
      return <Icon name='error' />;
    }

    if (postRemoveLoading) {
      return <Spinner id='watchlist-table-spinner' size='small' style={{ margin: 0 }} />;
    }

    return (
      <Button
        id={`delete-btn-${data._original.eventId}`}
        aria-label='delete button'
        buttonType={'text'}
        onClick={handleClick}
        style={{ height: '1rem', width: '1rem', margin: '0 auto', padding: 0, verticalAlign: 'top' }}>
        <Icon name={'close'} size={'xs'} color={'secondary'} />
      </Button>
    );
  };

  const formattedData = useMemo(() => {
    return tableData.map(item => {
      const newItem = {
        ...item,
        name: titleCase(item.lastName) + ', ' + titleCase(item.firstName),
        eventType: item.eventType === ACCOMMODATION_CODE ? ACCOMMODATION_DECODE : titleCase(item.eventType),
        eventId: item.eventId,
        receivedDate: !isNaN(Date.parse(item.receivedDate)) ? formatDateMMDDYYY(new Date(item.receivedDate)) : '',
        status: item.eventType === ACCOMMODATION_CODE ? item.statusCode : getStatusValue(item.statusCode),
      };
      return newItem;
    });
  }, [tableData]);

  const columns = useMemo(() => {
    return [
      {
        Cell: NameCell,
        Header: 'Name',
        accessor: 'name',
        id: 'name',
      },
      {
        Header: 'Employee ID',
        accessor: 'empId',
        id: 'empId',
      },
      {
        Header: 'Event type',
        accessor: 'eventType',
        id: 'eventType',
      },
      {
        Cell: EventIdCell,
        Header: 'Event ID',
        accessor: 'eventId',
        id: 'eventId',
        sortMethod: (a, b) => parseInt(b) - parseInt(a),
      },
      {
        Header: 'Status',
        accessor: 'status',
        id: 'status',
      },
      {
        Header: 'Received date',
        accessor: 'receivedDate',
        id: 'receivedDate',
        sortMethod: (a, b) => new Date(b) - new Date(a),
      },
      {
        Cell: EventDeleteCell,
        Header: 'Delete',
        accessor: 'deleteIcon',
        id: 'deleteIcon',
      },
    ];
  }, [tableData]);

  return (
    <>
      {' '}
      {tableData.length !== 0 ? (
        <DataTable
          fixHeaders={true}
          maxHeight={'16.75rem'}
          data={formattedData}
          columns={columns}
          sortable={true}
          defaultSorted={[{ id: 'receivedDate' }]}
        />
      ) : (
        'There are currently no items on your Watchlist. To add an event, select Watchlist on the details page.'
      )}
    </>
  );
};

export default WatchlistTable;
