import React from 'react';
import { Col, Label, NewDateInput, Row, themes } from '@digitools/honest-react';
import useExperience from 'src/hooks/useExperience';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from '../../../hooks/useCliDataContext';
import { Experience } from 'src/types/Experience';
import { addYears, isAfter, parse } from 'date-fns';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import { useFormState } from 'informed';
import { validateDateIsWithinAYear } from 'src/packages/cli/utils/validationUtils';
// TODO: optional for ER? double check in old interview
const IncidentDate = () => {
    const { absenceData } = useCliDataContext();
    const { t } = useTranslate();
    const { experience, language } = useExperience();
    const { formLabels } = AboutYourAbsenceText;
    const { values } = useFormState();
    const labelText = experience === Experience.EMPLOYEE ? t(formLabels.incidentDateEE) : t(formLabels.incidentDateER);
    const validateIncidentDate = (input) => {
        //Should this first check be removed? Not S1 requirement.
        if (input && values?.absenceData?.claimBeginDate) {
            if (isAfter(parse(input, 'P', new Date()), parse(values.absenceData.claimBeginDate, 'P', new Date()))) {
                return t({
                    english: 'Incident date cannot be after the date of disability',
                    spanish: 'La fecha del incidente no puede ser posterior a la fecha de la discapacidad',
                });
            }
        }
        return validateDateIsWithinAYear(input, t, true);
    };
    return (<>
      <Row className='mb-3'>
        <Col size={12}>
          <Label htmlFor='incidentDate' className='mb-2'>
            {labelText}
          </Label>
        </Col>
        <Col size={12} sm={6} xl={4}>
          <NewDateInput id='incidentDate' data-testid='incidentDate' theme={themes.lincoln} field={'incidentDate'} lang={language} label={t(formLabels.incidentDateShortLabel)} placeholder={'mm/dd/yyyy'} ariaLabelText={labelText} validate={validateIncidentDate} initialValue={absenceData && absenceData.incidentDate} minDate={addYears(new Date(), -1)} maxDate={values.absenceData.claimBeginDate ? new Date(values.absenceData.claimBeginDate) : undefined}/>
        </Col>
      </Row>
    </>);
};
export default IncidentDate;
