import React from 'react';
import { Button, NewModal as Modal, themes } from '@digitools/honest-react';
import { workStatesModel, workProvincesModel } from '../../../filter-my-report/work-location-model/initialStateAndProvinces';
import WorkLocations from '../../../filter-my-report/work-location-model/workLocations';
import { useDisclosure } from '@mantine/hooks';
const WorkLocationModalView = ({ prevSelectedStates, prevSelectedProvinces }) => {
    const RenderOpenModalButton = ({ showModal }) => {
        const handleOnClick = () => {
            showModal();
        };
        return (<Button buttonType={'text'} aria-label='View work locations modal' type={'button'} onClick={handleOnClick}>
        View work locations
      </Button>);
    };
    workStatesModel.map((workStateModel) => {
        workStateModel.checked = prevSelectedStates.filter(prevSelectedStates => prevSelectedStates.stateCode === workStateModel.stateCode).length > 0;
    });
    workProvincesModel.map((workProvinceModel) => {
        workProvinceModel.checked = prevSelectedProvinces.filter(prevSelectedLocations => prevSelectedLocations.provinceCode === workProvinceModel.provinceCode).length > 0;
    });
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <Modal withCloseButton={false} title='Employee work location' theme={themes.lincoln} closeOnClickOutside={true} centered={true} opened={isOpen} onClose={() => {
            close();
        }} cancelButtonText={'Close'} cancelButtonProps={{
            'data-testid': 'closeWorkLocations',
            buttonType: 'primary',
            'aria-label': 'close employee work location modal'
        }} submitButton={false}>
        <WorkLocations onStateSelect={() => { }} onProvinceSelect={() => { }} onClearAllState={() => { }} onClearAllProvince={() => { }} workStates={workStatesModel} workProvinces={workProvincesModel} disableForm={true}/>
      </Modal>
      <RenderOpenModalButton showModal={open}/>
    </>);
};
export default WorkLocationModalView;
